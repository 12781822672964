import React, { useEffect, useState } from 'react'
import ProfileMenu from '../../components/profile/ProfileMenu'
import ViewProfile from '../../components/profile/ViewProfile'
import axios from 'axios'
import { url } from '../../components/api/Url'
import { Link } from 'react-router-dom'
import Spinner from '../../components/Spinner/Spinner'
import ViewOrder from './ViewOrder'

const Order = () => {


    return (
        <>
            {/* <ProfileMenu /> */}
            <ViewOrder />
        </>
    )
}
export default Order