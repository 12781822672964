import React, { useState, useEffect } from 'react'
import Spinner from '../../components/Spinner/Spinner';
import SProfileMenu from './SProfileMenu';
import axios from 'axios';
import SellerFavoritesPage from '../../pages/template1/profile/SellerFavoritesPage'
 import SellerUserViewProfile from './profile/SellerUserViewProfile';
import ViewOrder from './profile/ViewOrder';
import SellerUserAddress from './profile/SellerUserAddress';
import { url } from '../../components/api/Url';

const SellerUserProfile = () => {
    const [profiledata, setProfiledata] = useState([]);
    const [username, setUsername] = useState('');
    const [image, setImage] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [city, setCity] = useState('');
    const [area, setArea] = useState('');
    const [dob, setDob] = useState('');
    const [isloading, setIsloading] = useState(true);
    const [showFavorites, setShowFavorites] = useState(false);
    const [showProfile, setShowProfile] = useState(true);
    const [showOrders, setShowOrders] = useState(false);
    const [showLoyalty, setShowLoyalty] = useState(false);
    const [showHelpCenter, setShowHelpCenter] = useState(false);
    const [showAddress, setShowAddress] = useState(false);
    const [showWallet, setShowWallet] = useState(false);

    const getProfile = async () => {
        setIsloading(true);
        const json = JSON.stringify({ user_id: localStorage.getItem('userid') });
        try {
            const res = await axios.post(url + "/app/v1/api/user_profile_byid", json, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setUsername(res.data.data[0].username);
            setEmail(res.data.data[0].email);
            setMobile(res.data.data[0].mobile);
            setCity(res.data.data[0].city);
            setImage(res.data.data[0].image);
            setArea(res.data.data[0].area);
            setDob(res.data.data[0].dob);
            setIsloading(false);
        } catch (error) {
            console.error('Error fetching profile:', error);
            setIsloading(false);
        }
    };

    const handleFormSubmit = (updatedUser) => {
        console.log('Updated User Data:', updatedUser);
        // Handle form submission, e.g., send it to an API
    };

    useEffect(() => {
        getProfile();
    }, []);
    const handleShowFavorites = () => {
        setShowFavorites(true);
        setShowProfile(false);
        setShowOrders(false);
        setShowLoyalty(false);
        setShowAddress(false);
        setShowWallet(false);
    };

    const handleShowOrders = () => {
        setShowFavorites(false);
        setShowProfile(false);
        setShowOrders(true);
        setShowAddress(false);
        setShowLoyalty(false);
        setShowWallet(false);
    };

    const handleShowProfile = () => {
        setShowFavorites(false);
        setShowProfile(true);
        setShowOrders(false);
        setShowLoyalty(false);
        setShowAddress(false);
        setShowWallet(false);
    };

    const handleShowLoyalty = () => {
        setShowFavorites(false);
        setShowProfile(false);
        setShowWallet(false);
        setShowOrders(false);
        setShowLoyalty(true);
        setShowAddress(false);
    };

    const handleShowHelpCenter = () => {
        setShowFavorites(false);
        setShowProfile(false);
        setShowOrders(false);
        setShowLoyalty(false);
        setShowHelpCenter(true);
        setShowAddress(false);
        setShowWallet(false);
    };

    const handleShowAddress = () => {
        setShowFavorites(false);
        setShowProfile(false);
        setShowOrders(false);
        setShowLoyalty(false);
        setShowHelpCenter(false);
        setShowAddress(true);
        setShowWallet(false);
    };

    const handleShowWallet = () => {
        setShowFavorites(false);
        setShowProfile(false);
        setShowOrders(false);
        setShowLoyalty(false);
        setShowHelpCenter(false);
        setShowAddress(false);
        setShowWallet(true);
    };


    return (
        <>
          <div className="mx-[100px] mt-10">
            {isloading ? (
                <Spinner />
            ) : (
                <>
                    <SProfileMenu
                        onShowFavorites={handleShowFavorites}
                        onShowProfile={handleShowProfile}
                        onShowOrders={handleShowOrders}
                        onShowLoyalty={handleShowLoyalty}
                        onShowHelpCenter={handleShowHelpCenter}
                        onShowAddress={handleShowAddress}
                        onShowWallet={handleShowWallet}
                    />
           {showFavorites ? (
              <SellerFavoritesPage />
            ) : showProfile ? (
               <SellerUserViewProfile 
                username={username}
                email={email} 
                userimage={image} 
                mobile={mobile}
                cityname={city}
                dateofbirth={dob}
                areaname={area}
                onSubmit={handleFormSubmit} 
              /> 
             
            ) : showOrders ? (
              <ViewOrder />
            ) : showLoyalty ? (
             ''
            ) : showHelpCenter ? ( 
             ''
            ) : showAddress ? ( 
             <SellerUserAddress />
            ) : showWallet ? ( 
           ''
          ) : null
            } 
                </>
            )}
            </div>
        </>
    );
};
export default SellerUserProfile

