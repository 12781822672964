import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";
import { url } from "../api/Url"; 

const tripo3dApiKey = "tsk_tZd2LyKRAsbjFSrH8PKHLz3rZsue8wJAzFyKqvJzoqt";
const tripo3dBaseUrl = "https://api.tripo3d.ai/v2/openapi/task/";

const ModelViewer = ({ modelPath }) => {
  const { scene, error } = useGLTF(modelPath);

  if (error) {
    console.error("GLTF Model Error:", error.message);
    return <div>Error loading model: {error.message}</div>;
  }

  if (!scene) {
    return <div>Loading model...</div>;
  }

  return <primitive object={scene} scale={0.5} />;
};

const AllModel3D = ({ data, queryParams }) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [models, setModels] = useState({}); 

  const getVideos = async () => {
    try {
      const response = await axios.post(`${url}/get_all_videos`, queryParams);
      const videoData = response.data.message;

      const seenNames = new Set();
      const unique3DModelVideos = videoData.filter(video => {
        if (!seenNames.has(video.video_name) && video.video_type === "3dModel") {
          seenNames.add(video.video_name);
          return true;
        }
        return false;
      });

      console.log("Unique 3D Model Videos fetched:", unique3DModelVideos);
      setVideos(unique3DModelVideos);
      setLoading(false);

      unique3DModelVideos.forEach(video => {
        if (video.weblink) {
          fetchModelData(video.weblink); 
        }
      });
    } catch (err) {
      console.error("Error fetching videos:", err.response?.data?.message || err.message);
      setError(err.response?.data?.message || "Failed to load videos");
      setLoading(false);
    }
  };

  const fetchModelData = async (taskId) => {
    const modelUrl = `${tripo3dBaseUrl}${taskId}`;
    const options = {
      headers: {
        'Authorization': `Bearer ${tripo3dApiKey}`
      }
    };

    try {
      const response = await axios.get(modelUrl, options);
      console.log("Model data:", response.data);
      setModels(prevModels => ({
        ...prevModels,
        [taskId]: response.data.result.model.url
      }));
    } catch (err) {
      console.error("Error fetching model:", err.response?.data?.message || err.message);
      setError(err.response?.data?.message || "Failed to load model");
    }
  };

  useEffect(() => {
    getVideos();
  }, [queryParams]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">Error: {error}</div>;
  }

  return (
    <div className="mt-10">
      <div className="flex justify-between items-center mb-6">
        <h1
          className="text-left font-bold text-[22px]"
          style={{ color: `${data?.component?.image_gallery?.color}` }}
        >
          3D Models
        </h1>
        <Link to="" className="text-blue-700">
          View All
        </Link>
      </div>

      {/* Display videos in a responsive grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {videos.length > 0 ? (
          videos.map((video) => (
            <div key={video.id} className="bg-gray-100 p-3 rounded shadow-md">
              {/* Display 3D model using the weblink */}
              {video.video_type === "3dModel" && models[video.weblink] ? (
                <Canvas className="w-full h-40 mb-2">
                  <OrbitControls />
                  <ambientLight />
                  <ModelViewer modelPath={models[video.weblink]} />
                </Canvas>
              ) : (
                <div className="w-full h-40 bg-gray-300 flex items-center justify-center rounded-md mb-2">
                  <span>No Model Available</span>
                </div>
              )}

              {/* Video name */}
              <h2 className="font-semibold text-sm">
                {video.video_name || `Video`}
              </h2>

              {/* Video description */}
              {video.video_description && (
                <p className="text-sm text-gray-600">
                  {video.video_description}
                </p>
              )}
            </div>
          ))
        ) : (
          <p>No 3D model videos available</p>
        )}
      </div>
    </div>
  );
};

export default AllModel3D;
