import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SellerCartContext = createContext();

export const SellerCartProvider = ({ children }) => {
  const [sellerCartItems, setSellerCartItems] = useState(() => {
    try {
      const savedCart = localStorage.getItem('sellerCartItems');
      return savedCart ? JSON.parse(savedCart) : [];
    } catch (error) {
      console.error('Error loading cart from localStorage:', error);
      return [];
    }
  });

  const [currentSellerId, setCurrentSellerId] = useState(() => {
    try {
      const savedSellerId = localStorage.getItem('seller_id');
      return savedSellerId ? savedSellerId : null;
    } catch (error) {
      console.error('Error loading seller ID from localStorage:', error);
      return null;
    }
  });

  // Save seller cart items in local storage
  useEffect(() => {
    try {
      localStorage.setItem('sellerCartItems', JSON.stringify(sellerCartItems));
      console.log('SellerLocalStorage:', sellerCartItems);
    } catch (error) {
      console.error('Error saving cart to localStorage:', error);
    }
  }, [sellerCartItems]);

  // Filter cart items based on seller_id
  const filteredCartItems = useMemo(() => {
    return currentSellerId
      ? sellerCartItems.filter(item => item.seller_id === currentSellerId)
      : [];
  }, [sellerCartItems, currentSellerId]);

  const addToCart = useCallback((product, size) => {
    setSellerCartItems(prevItems => {
      const existingItem = prevItems.find(item => item.id === product.id && item.size === size && item.seller_id === product.seller_id);
      if (existingItem) {
        toast.info('Increased quantity in cart', { autoClose: 1000 });
        return prevItems.map(item =>
          item.id === product.id && item.size === size && item.seller_id === product.seller_id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        toast.success('Added to cart', { autoClose: 1000 });
        return [...prevItems, { ...product, size, quantity: 1 }];
      }
    });
  }, []);

  const removeFromCart = useCallback((id, size, seller_id) => {
    console.log('remove cart deg...' + id);
    setSellerCartItems(prevItems => {
      const updatedItems = prevItems.map(item => {
        if (item.id === id && item.size === size) {
        //if (item.id === id && item.size === size && item.seller_id === seller_id) {
          const newQuantity = Math.max(0, item.quantity - 1);
          if (newQuantity < item.quantity) {
            toast.warn('Decreased quantity in cart', { autoClose: 1000 });
          }
          return { ...item, quantity: newQuantity };
        }
        return item;
      }).filter(item => item.quantity > 0);

      return updatedItems;
    });
  }, []);

  const deleteFromCart = useCallback((id, size, seller_id) => {
    console.log(' delete...' + id)
    setSellerCartItems(prevItems => {
     // const updatedItems = prevItems.filter(item => !(item.id === id && item.size === size && item.seller_id === seller_id));
      const updatedItems = prevItems.filter(item => !(item.id === id && item.size === size));
      if (updatedItems.length < prevItems.length) {
        toast.error('Item removed from cart', { autoClose: 1000 });
      }
      return updatedItems;
    });
  }, []);

  const contextValue = useMemo(() => ({
    sellerCartItems: filteredCartItems,
    addToCart,
    removeFromCart,
    deleteFromCart,
  }), [filteredCartItems, addToCart, removeFromCart, deleteFromCart]);

  return (
    <SellerCartContext.Provider value={contextValue}>
      {children}
    </SellerCartContext.Provider>
  );
};

export const useSellerCart = () => {
  const context = useContext(SellerCartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
