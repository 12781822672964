import React, { useState, useEffect } from "react";
import axios from "axios";
import { CoinsIcon } from "lucide-react";

const Wallet = () => {
  const [walletBalance, setWalletBalance] = useState(0);
  const [transactions, setTransactions] = useState([]); // assuming transactions data is fetched as well

  const getBalance = async () => {
    const token = localStorage.getItem("token");
    const userid = localStorage.getItem("userid");
  
    if (!token || !userid) {
      throw new Error("Token or User ID not found in localStorage");
    }
  
    const json = JSON.stringify({ user_id: userid });
  
    try {
      let response = await axios.post("/app/v1/api/get_user_balance", json, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      let data = await response.data.data;
      const balance = parseFloat(data[0].balance); // Ensure balance is a number
      setWalletBalance(balance);
    } catch (error) {
      setWalletBalance(0); // Fallback in case of error
    }
  };

  useEffect(() => {
    getBalance();
  }, []);

  return (
    <div className="container mx-auto p-4">
      {/* Wallet Balance */}
      <div className="bg-white shadow-md rounded-md p-6 flex items-center justify-between mb-6">
        <div className="flex items-center">
          <div className="bg-purple-100 p-4 rounded-full">
                <CoinsIcon/>
          </div>
          <div className="ml-4">
            <h3 className="text-xl font-semibold">Balance</h3>
            <p className="text-xl font-bold">
            ₹ {walletBalance ? walletBalance.toFixed(2) : "0.00"}
            </p>
          </div>
        </div>
        <div>
          {/* <button className="bg-blue-500 text-white py-2 px-4 rounded-lg mr-4">
            Add Money
          </button>
          <button className="bg-red-500 text-white py-2 px-4 rounded-lg">
            Withdraw Money
          </button> */}
        </div>
      </div>

      {/* Transactions Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-md">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-3 px-4 text-left">ID</th>
              <th className="py-3 px-4 text-left">Username</th>
              <th className="py-3 px-4 text-left">Type</th>
              <th className="py-3 px-4 text-left">Amount</th>
              <th className="py-3 px-4 text-left">Status</th>
              <th className="py-3 px-4 text-left">Message</th>
              <th className="py-3 px-4 text-left">Date</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={index} className="border-t">
                <td className="py-3 px-4">{transaction.id}</td>
                <td className="py-3 px-4">{transaction.username}</td>
                <td className="py-3 px-4">{transaction.type}</td>
                <td className="py-3 px-4">₹ {transaction.amount}</td>
                <td className="py-3 px-4">{transaction.status}</td>
                <td className="py-3 px-4">{transaction.message}</td>
                <td className="py-3 px-4">{transaction.date}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-between items-center mt-4">
        <p className="text-sm">
          Showing 1 to {Math.min(transactions.length, 10)} of {transactions.length} rows
        </p>
        <div className="flex space-x-2">
          <button className="bg-gray-200 text-gray-700 py-1 px-3 rounded-lg">1</button>
          <button className="bg-gray-200 text-gray-700 py-1 px-3 rounded-lg">2</button>
          <button className="bg-gray-200 text-gray-700 py-1 px-3 rounded-lg">3</button>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
