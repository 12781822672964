import React from "react";
import { url } from "../api/Url";
import { Link } from "react-router-dom";

export const Blogcard = ({ name, width, height, title, autoid, Blogimage }) => {
  const sellerid = localStorage.getItem('seller_id');
  const sellerslug = localStorage.getItem('seller_slug');
  return (
    <Link to={`/o/${sellerslug}/${autoid}/blog`} className="md:min-w-[200px] min-w-[300px]"> 
    <div className={`border-2 relative rounded-xl overflow-hidden bg-slate-200 min-w-[200] mt-4 md:h-[250px]`}>
      {Blogimage && (
       
        <img 
          src={`${url}/${Blogimage}`} 
          alt={title} 
          className="object-cover h-48 w-96" 
        />
      )}
      <div className="absolute inset-0 flex items-center justify-center p-4 bg-gradient-to-t from-black to-transparent">
        <p className="text-white text-lg md:text-xl mt-32 font-semibold text-center bg-opacity-50 p-2 rounded">
          {title}
        </p>
      </div>
    </div>
    </Link>
  );
};
