import React, { useState, useEffect } from 'react';
import axios from 'axios';

const LoyaltyPage = () => {
  const [transactionData, setTransactionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem('userid');
        const token = localStorage.getItem('token');

        if (!userId || !token) {
          throw new Error('User ID or token not found in local storage');
        }

        const formData = new FormData();
        formData.append('user_id', userId); //userId

        const response = await axios.post(
          'https://zulushop.in/app/v1/api/get_loyalty_transaction_data',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`
            }
          }
        );

        setTransactionData(response.data);
      } catch (err) {
        setError('Failed to fetch transaction data');
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!transactionData) return <div>No data available</div>;

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = transactionData.data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(transactionData.data.length / itemsPerPage);

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Loyalty Transactions</h1>

      <div className="mb-4">
        <p>Receiver Points: {transactionData.receiver_point[0].amount}</p>
        <p>Sender Points: {transactionData.sender_point[0].amount}</p>
      </div>

      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="bg-gray-100 text-center text-gray-600 text-sm  uppercase">
              <th className="py-2 px-4 border ">Seller Name</th>
              <th className="py-2 px-4 border">Receiver Name</th>
              <th className="py-2 px-4 border">Description</th>
              <th className="py-2 px-4 border">Amount</th>
              <th className="py-2 px-4 border">Status</th>
              <th className="py-2 px-4 border">Date</th>
            </tr>
          </thead>
          <tbody  className="text-gray-600">
            {currentItems.map((transaction, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}>
                <td className="py-3 px-4 border">{transaction.seller_name}</td>
                <td className="py-3 px-4 border">{transaction.receiver_name}</td>
                <td className="py-3 px-4 border">{transaction.description}</td>
                <td className="py-3 px-4 border">{transaction.amount}</td>
                <td className="py-3 px-4 border">{transaction.status}</td>
                <td className="py-3 px-4 border">{transaction.timestamp}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mt-4 flex items-center justify-between">
        <div>
          Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, transactionData.data.length)} of {transactionData.data.length} rows
        </div>
        <div className="flex items-center">
          <select 
            className="mr-2 border rounded p-1"
            value={itemsPerPage}
            onChange={(e) => setItemsPerPage(Number(e.target.value))}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
          <span>rows per page</span>
        </div>
        <div className="flex">
          <button
            onClick={() => setCurrentPage(page => Math.max(page - 1, 1))}
            disabled={currentPage === 1}
            className="px-3 py-1 bg-gray-200 text-gray-600 rounded-l"
          >
            &lt;
          </button>
          {[...Array(totalPages).keys()].map(number => (
            <button
              key={number}
              onClick={() => setCurrentPage(number + 1)}
              className={`px-3 py-1 ${currentPage === number + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-600'}`}
            >
              {number + 1}
            </button>
          ))}
          <button
            onClick={() => setCurrentPage(page => Math.min(page + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="px-3 py-1 bg-gray-200 text-gray-600 rounded-r"
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoyaltyPage;
