import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { X, MapPin, ChevronRight } from 'lucide-react';
import { url } from '../api/Url';
import Spinner from '../Spinner/Spinner';

const PromoCodes = ({ show, onClose, onSelectPromo }) => {
    const [poromolist, setPromolist] = useState([]);
    const [selectedPromo, setSelectedPromo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (show) {
            getPromolist();
        }
    }, [show]);

    const getPromolist = async () => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem("token");
            const userid = localStorage.getItem("userid");
            if (!token || !userid) {
                throw new Error("Token or User ID not found in localStorage");
            }
            const formData = new FormData();
            formData.append("limit", '20');
            const response = await axios.post(
                url + "/app/v1/api/get_promo_codes",
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = response.data.promo_codes;
            console.log(' poromo ' + JSON.stringify(data))
            /*    if (!Array.isArray(addressData) || addressData.length === 0) {
                 throw new Error("No promo found");
               } */
            setPromolist(data);
            //  setSelectedAddress(addressData.find(address => address.is_default === "1")?.id || null);
        } catch (error) {
            console.error("Error fetching address:", error);
            // toast.error("Error fetching addresses: " + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSelectPromocodes = (item) => {
        // setSelectedAddress(address.id);
      //  alert(id);
        onSelectPromo(item);
        onClose();
    };

    if (!show) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50  flex items-center justify-center">
            <div className="relative bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
                <div className="flex justify-between items-center p-4 border-b">
                    <h2 className="text-lg font-semibold">Promo Codes</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <X size={20} />
                    </button>
                </div>
                {isLoading ? (
                    <div className="p-4"><Spinner /></div>
                ) : (
                    <div className="p-4">
                        {poromolist.length === 0 ? (
                            <p>No Promo found.</p>
                        ) : (
                            poromolist?.map((item) => (
                                <div
                                    key={item.id}
                                    className="flex items-center p-2 cursor-pointer hover:bg-gray-100"
                                    onClick={() => handleSelectPromocodes(item)}
                                >
                                    <div className="flex-grow text-left">
                                        <p className=" text-leftfont-semibold">{item.promo_code} - {item.message}</p>
                                        <p className=" text-left text-sm text-gray-600">{item.discount}</p>
                                        <p className="text-left text-sm text-gray-600">
                                            <img
                                                src={`${item.image}`}
                                                className="w-full h-[200px]"
                                                alt=""
                                            />
                                        </p>
                                    </div>
                                    <ChevronRight size={20} className="text-gray-400" />
                                </div>
                            ))
                        )}
                    </div>
                )}
                <div className="p-4 border-t">
                    {/* <button
            className="w-full py-2 px-4 bg-blue-600 text-white rounded-lg flex items-center justify-center"
          
          >
            <MapPin size={20} className="mr-2" />
            Create a New Address
          </button> */}
                </div>
            </div>
        </div>
    );
};

export default PromoCodes;