import React, { useState, useMemo, useEffect } from "react";
import { Edit2, HandCoins, HouseIcon, PlusCircle, Wallet } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaHome, FaTruck } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import AddAddressModal from "./AddAddressModal";
import EditAddressModal from "./EditAddressModal";
import { useCart } from "../categaries_menu/CartContext";
import axios from "axios";
import { url } from "../api/Url";
import Spinner from "../Spinner/Spinner";
import PromoCodes from "./PromoCodes";

const CheckoutPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { cartItems, removeFromCart } = useCart();
  const [promoCode, setPromoCode] = useState(""); 
  const [promoDiscount, setPromoDiscount] = useState(""); 
  const [selectedAddress, setSelectedAddress] = useState(0);
  const [addresses, setAddresses] = useState([]);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [isPromocode, setIsPromocode] = useState(false);
  const [pormocode, setPromocode] = useState('');
  const [isAddingNewAddress, setIsAddingNewAddress] = useState(false);
  const [currentAddress, setCurrentAddress] = useState(
    addresses[selectedAddress]
  );
  const [useWallet, setUseWallet] = useState(false);
  const [walletBalance, setWalletBalance] = useState('N/A');
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const cartProducts = location.state?.cartProducts || cartItems;

  const subtotal = useMemo(() => {
    return cartProducts.reduce(
      (total, item) => total + item.min_max_price.special_price * item.quantity,
      0
    );
  }, [cartProducts]);

  const deliveryCharge = 70.0;
  const taxPercentage = 10; // Assuming 10% tax
  const taxAmount = (subtotal * taxPercentage) / 100;
  const total = subtotal + deliveryCharge + taxAmount;

  const getAddress = async () => {
    try {
      const token = localStorage.getItem("token");
      const userid = localStorage.getItem("userid");

      console.log("Token:", token);
      console.log("User ID:", userid);

      if (!token || !userid) {
        throw new Error("Token or User ID not found in localStorage");
      }

      const formData = new FormData();
      formData.append("user_id", userid);

      console.log("Form Data:", formData);

      const response = await axios.post(
        url + "/app/v1/api/get_user_address",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Response:", response);

      // Assuming the API response contains an array in data
      const addressData = response.data;

      // Log addressData to check its type and structure
      console.log("Address Data:", addressData);

      // Ensure addressData is an array before trying to find the default address
      if (!Array.isArray(addressData) || addressData.length === 0) {
        throw new Error("No addresses found");
      }


      // Find the default address
      const defaultAddress = addressData.find(address => address.is_default === "1");

      console.log("Default Address:", defaultAddress);

      if (!defaultAddress) {
        throw new Error("No default address found");
      }

      // Set state with the fetched data
      setAddresses(addressData); // Set all addresses to state
      setSelectedAddress(defaultAddress.id); // Set the default address as selected
      setCurrentAddress(defaultAddress); // Set the default address as the current one
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  const getBalance = async () => {
    const token = localStorage.getItem("token");
    const userid = localStorage.getItem("userid");

    if (!token || !userid) {
      throw new Error("Token or User ID not found in localStorage");
    }
    const json = JSON.stringify({ user_id: userid });
    try {
      let response = await axios.post(
        url + "/app/v1/api/get_user_balance",
        json,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      let data = await response.data.data;
      //  console.log("Response Balance :", data[0].balance);
      setWalletBalance(data[0].balance);

    } catch (error) {
      // setCategoriesdata([]);
      setWalletBalance(0);
    }

  };


  useEffect(() => {
    getAddress();
    getBalance();
  }, []);


  const handleSaveNewAddress = (newAddressData) => {
    const updatedAddresses = [...addresses, newAddressData];
    setAddresses(updatedAddresses);
    setSelectedAddress(updatedAddresses.length - 1);
    setCurrentAddress(newAddressData);
    setIsAddingNewAddress(false);
    toast.success("New address added successfully!");
  };

  const handleSaveEditAddress = (editedAddress) => {
    const updatedAddresses = addresses.map((addr) =>
      addr.id === editedAddress.id ? editedAddress : addr
    );
    setAddresses(updatedAddresses);
    setCurrentAddress(editedAddress);
    setIsEditingAddress(false);
    toast.success("Address updated successfully!");
  };

  const handleWalletChange = () => {
    setUseWallet(!useWallet);
    if (!useWallet) {
      setPaymentMethod("wallet");
    } else {
      setPaymentMethod("");
    }
  };

  const handleCODChange = () => {
    if (paymentMethod === "cod") {
      setPaymentMethod(""); // Deselect COD
    } else {
      setUseWallet(false); // Disable wallet when COD is selected
      setPaymentMethod("cod");
    }
  };
  const redeemSubmit = async () => {
    if (!promoCode) {
      toast.error("Please Insert the Promo code");
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("user_id", localStorage.getItem("userid")); // This should be dynamically set based on the logged-in user
    formData.append("mobile", localStorage.getItem("mobileno"));
    const token = localStorage.getItem("token");
    console.log("....token...." + token);
    console.log("formdata..." + formData);

    try {
      const response = await fetch(url + "/seller/app/v1/api/place_order", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        setIsLoading(false);
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("response" + data.data.message);
      if (data.data.message === "Order Placed Successfully") {
        setIsLoading(false);
        toast.success(`Order Submited successfully: ${data.data.order_id}`);
        localStorage.setItem("customeraddress", JSON.stringify(addresses));
        navigate(`/ordersuccess/${data.data.order_id}`);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to place order");
    }
    setIsLoading(false);
  }
  const placeOrder = async () => {

    if (!paymentMethod) {
      toast.error("Please select a payment method");
      return;
    }

    setIsLoading(true);

    const selectedAddressDetails = addresses.find(addr => addr.id === selectedAddress);

    if (!selectedAddressDetails) {
      toast.error("Selected address details not found");
      setIsLoading(false);
      return;
    }
const fullAddress = `${selectedAddressDetails.address}, ${selectedAddressDetails.landmark}, ${selectedAddressDetails.area}, ${selectedAddressDetails.city}, ${selectedAddressDetails.state}, ${selectedAddressDetails.country} - ${selectedAddressDetails.pincode}`.trim();

const formData = new FormData();
    formData.append("user_id", localStorage.getItem("userid")); // This should be dynamically set based on the logged-in user
    formData.append("mobile", localStorage.getItem("mobileno"));
    formData.append(
      "quantity",
      cartProducts.map((item) => item.quantity).join(",")
    );
    formData.append(
      "product_variant_id",
      cartProducts.map((item) => item.id).join(",")
    );
    formData.append("seller_id", "0"); // This should be dynamically set based on the seller
    formData.append("total", subtotal);
    formData.append("delivery_charge", deliveryCharge);
    formData.append("tax_amount", taxAmount);
    formData.append("tax_percentage", taxPercentage);
    formData.append("final_total", total);
    formData.append("total_payable", total);
    formData.append("latitude", "4000"); // This should be dynamically set based on the address
    formData.append("longitude", "4000"); // This should be dynamically set based on the address
    formData.append("payment_method", paymentMethod);
    formData.append("address_id", selectedAddress ); //currentAddress.id); 
    formData.append("address", fullAddress);
    formData.append("order_type", 'Try at home');

    const token = localStorage.getItem("token");
    console.log("....token...." + token);
    console.log("formdata..." + formData);
    console.log("FormData contents:");
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const response = await fetch(url + "/seller/app/v1/api/place_order", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        setIsLoading(false);
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("response" + data.data.message);
  // CheckoutPage component - after order is placed successfully
if (data.data.message === "Order Placed Successfully") {
  setIsLoading(false);
  toast.success(`Order Submitted successfully: ${data.data.order_id}`);
  
  // Pass the selected address as state when navigating
  navigate(`/ordersuccess/${data.data.order_id}`, { state: { usedAddress: currentAddress } });
}

    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to place order");
    }
    setIsLoading(false);
  };




  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Checkout</h1>
      <div className="flex flex-col lg:flex-row gap-8">
        <div className="lg:w-2/3">
          {/* Address section */}
          <div className="bg-white p-6 rounded-lg shadow-md mb-6">
            <div className="flex justify-between text-left items-center mb-4">
              <h2 className="text-xl font-semibold items-center flex">
                <FaHome className="mr-4" />
                Shipping Address
              </h2>
              <div>
                <button
                  onClick={() => setIsEditingAddress(true)}
                  className="text-blue-500 hover:text-blue-700 mr-2"
                >
                  <Edit2 size={18} />
                </button>
                <button
                  onClick={() => setIsAddingNewAddress(true)}
                  className="text-green-500 hover:text-green-700"
                >
                  <PlusCircle size={18} />
                </button>
              </div>
            </div>
            {/* Shipping Address section */}
            <div className="mb-4 text-left">
              <div className="flex justify-between items-center mb-2">

              </div>
              {currentAddress && currentAddress.id ? (
                <div>
                  <p>{currentAddress.name} - {currentAddress.type}</p>
                  <p>{currentAddress.address}</p>
                  <p>{currentAddress.city}, {currentAddress.state}, {currentAddress.country}</p>
                  <p>{currentAddress.mobile}</p>
                </div>
              ) : (
                <p>No address found. Please add one.</p>
              )}
            </div>

            {/* Deliverability warning */}
            {/* <p className="text-red-500 text-sm">Some of the item(s) are not deliverable on selected address. Try changing address or modify your cart items.</p> */}
          </div>


          {/* Use wallet balance section */}
          <div className="bg-white p-6 rounded-lg text-left shadow-md mb-6">
            <h2 className="text-xl font-semibold mb-4">Use wallet balance</h2>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="use-wallet"
                checked={useWallet}
                onChange={handleWalletChange}
                className="mr-2"
              />
              <Wallet className="w-4 h-4 mr-2" />
              <label htmlFor="use-wallet" className="cursor-pointer">
                Available balance: {walletBalance}
              </label>
            </div>
          </div>

          {/* Payment Method section */}
          <div className="bg-white p-6 rounded-lg text-left shadow-md">
            <h2 className="text-xl font-semibold mb-4">Payment Method</h2>
            <div className="flex items-center">
              <input
                type="radio"
                id="cod"
                checked={paymentMethod === "cod"}
                onChange={handleCODChange}
                className="mr-2"
              />
              <label htmlFor="cod" className="flex items-center cursor-pointer">
                {/* <img src="/path-to-cod-icon.png" alt="COD" className="w-8 h-8 mr-2" /> */}
                <HandCoins className="w-5 h-5 mr-2" />
                Cash On Delivery
              </label>
            </div>
          </div>
        </div>


        {/* Order summary section */}
        <div className="lg:w-1/3 text-left">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Order Summary</h2>
            {/* <p className="text-sm text-gray-600 mb-4">1 Item(s) in Cart</p> */}
            {cartProducts.map((item) => (
              <div key={`${item.id}-${item.size}`} className="flex mb-4">
                <img src={item.image} alt={item.name} className="w-16 h-16 object-cover rounded mr-4" />
                <div>
                  <h3 className="font-semibold">{item.name}</h3>
                  {/* <p className="text-red-500">Not deliverable</p> */}
                  <p>Qty: {item.quantity}</p>
                  <p>Net Amount: ₹{item.min_max_price.special_price * item.quantity}</p>
                  <p className="text-sm text-gray-600">GST 5% : ₹{(item.min_max_price.special_price * item.quantity * 0.05).toFixed(2)}</p>
                </div>
              </div>
            ))}
            <div className="border-t pt-4">
              <div className="flex justify-between mb-2">
                <span>Subtotal</span>
                <span>₹{subtotal.toFixed(2)}</span>
              </div>
              <div className="mb-4">
                <h3 className="font-semibold mb-2">Delivery Charge</h3>
                <div className="flex justify-between mb-1">
                  <span>Delivery Charge with COD</span>
                  <span>₹{deliveryCharge.toFixed(2)}</span>
                </div>
                <div className="flex justify-between">
                  <span>Delivery Charge without COD</span>
                  <span>₹{deliveryCharge.toFixed(2)}</span>
                </div>
              </div>
              <div className="flex justify-between mb-2">
                <span>Wallet</span>
                <span>{walletBalance}</span>
              </div>
              <div className="flex justify-between mb-2">
                <span>Promo Discount</span>
                <span>{promoDiscount}</span>
              </div>
              
              <div className="flex justify-between font-semibold">
                <span>Total</span>
                <span>₹{total.toFixed(2)}</span>
              </div>
            </div>
            <div className="mt-4">
              <Link  onClick={() => setIsPromocode(true)} className="text-blue-500 hover:underline">See All Offers</Link>
            </div>
            <div className="mt-4 flex">
              <input
                type="text"
                placeholder="Promo code"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                className="flex-grow border p-2 rounded-l"
              />
              <button className="bg-blue-400 text-white px-4 py-2 rounded-r"
               onClick={redeemSubmit}  disabled={isLoading}>
                {isLoading ? <Spinner /> : 'Redeem'}
               </button>
            </div>
            <button
              className="bg-blue-500 text-white w-full py-2 rounded-3xl mt-4"
              onClick={placeOrder}
              disabled={isLoading}
            >
              {isLoading ? <Spinner /> : 'Place Order'}
            </button>
          </div>
        </div>
        {/* </div> */}

        <AddAddressModal
          show={isAddingNewAddress}
          onClose={() => setIsAddingNewAddress(false)}
          onSave={handleSaveNewAddress}
        />

        <EditAddressModal
          show={isEditingAddress}
          onClose={() => setIsEditingAddress(false)}
          onSelectAddress={(address) => {
            setCurrentAddress(address);
            setSelectedAddress(address.id); // Update selected address in the parent
          }}
        />
        <PromoCodes 
        show={isPromocode}
        onSelectPromo={(item) => {
         // setPromoCode(address);
         console.log('ddddd....' + item.discount)
          setPromoCode(item.promo_code); // Update selected address in the parent
          setPromoDiscount(item.discount); // Update selected address in the parent
        }}
        onClose={() => setIsPromocode(false)}/>

      </div>
    </div>
  );
};

export default CheckoutPage;