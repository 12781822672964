import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { url } from "../api/Url";
import axios from "axios";

const Card6 = ({ data }) => {
  const navigate = useNavigate();

  const handleClick = async (slug) => {
    localStorage.clear();
    console.log('Link was clicked!' + slug);
    axios
      .post(url + "/slug_id/" + slug)
      .then((res) => {
        console.log('seller info....' + res);
        // setSellerid(res.data.info.user_id);
        localStorage.setItem('seller_id', res.data.info.user_id);
        localStorage.setItem('seller_slug', res.data.info.slug);
        localStorage.setItem('seller_info', res.data.info);
        navigate(`${slug}`); // Redirects to the /about route

      })
      .catch(() => {
        // setCategoriesdata([]);
      });
  };

  return (
    <div className="p-2 w-[300px]">
      <div className="relative bg-white rounded-xl shadow-lg border border-gray-200 overflow-hidden">
        {/* Image Section */}
        <Link
          onClick={() => handleClick(`${data.slug}`)} /* to={`${data.slug}`}  */
          className="text-black-500 hover:underline"
        >
          <img
            src={data.seller_profile}
            alt={data.seller_name}
            className="w-full h-48 object-contain"
          />
        </Link>
        {/* Favorite Icon */}
        <div className="absolute top-2 right-2  p-1 rounded-full shadow-md">
          {/* <svg
            className="w-6 h-6 text-red-500"
            fill="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M12 4.318C10.402-1.547 1.812 2.47 6.153 8.612l.677.76.708-.705C10.62 6.118 11.78 5.2 12 5.032c.222.17 1.38 1.085 4.462 4.64l.707.705.677-.76C22.189 2.47 13.598-1.547 12 4.318z"
              clipRule="evenodd"
            />
          </svg> */}
        </div>

        {/* Text Section */}
        <div className="p-4 text-left rounded-t-xl">
          <h3 className="text-lg font-semibold">{data.seller_name} {data.slug}</h3>
          <p className="text-sm text-gray-500">Men, Women, Kids</p>
          <p className="text-sm text-gray-500">{data.total_products}+ products</p>
        </div>
      </div>
    </div>
  );
};

export default Card6;
