import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaRegHeart, FaTrash, FaHome } from 'react-icons/fa';
import { Edit2, PlusCircle, Trash } from 'lucide-react';
//import AddAddressModal from '../checkout/AddAddressModal';
import SellerAddressModal from '../Modal/SellerAddressModal';
//import EditAddressModal from '../checkout/EditAddressModal';
import SellerEditAddressModal from '../Modal/SellerEditAddressModal';
import { url } from '../../../components/api/Url';
// import {
//   Dialog,
//   DialogHeader,
//   DialogBody,
//   DialogFooter,Button
// } from "@material-tailwind/react";
import ModalYesNo from './ModalYesNo';

function SellerUserAddress() {
  const [favorites, setFavorites] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(0);
  const [currentAddress, setCurrentAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [isAddingNewAddress, setIsAddingNewAddress] = useState(false);
  const [showyesnomessage, setShowyesnomessage] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [addressautoid, setAddressautoid] = useState('');

  const navigate = useNavigate();

  /* const handleOpen = () => setShowyesnomessage(!showyesnomessage); */

  const getAddress = async () => {
    try {
      const token = localStorage.getItem("token");
      const userid = localStorage.getItem("userid");

      if (!token || !userid) {
        throw new Error("Token or User ID not found in localStorage");
      }

      const formData = new FormData();
      formData.append("user_id", userid);

      const response = await axios.post(
        url + "/app/v1/api/get_user_address",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const addressData = response.data;

      if (!Array.isArray(addressData) || addressData.length === 0) {
        throw new Error("No addresses found");
      }

      const defaultAddress = addressData.find(address => address.is_default === "1");

      setAddresses(addressData);
      setSelectedAddress(defaultAddress ? defaultAddress.id : addressData[0].id);
      setCurrentAddress(defaultAddress || addressData[0]);
    } catch (error) {
      console.error("Error fetching address:", error);
      setError(error.message || 'An error occurred while fetching addresses.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFavorites = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('token');
      const userid = localStorage.getItem('userid');

      if (!token || !userid) {
        throw new Error('Token or user ID not found. Please log in again.');
      }

      const formData = new FormData();
      formData.append('user_id', userid);
      formData.append('limit', '10');
      formData.append('offset', '0');

      const response = await axios.post(
        'https://zulushop.in/app/v1/api/get_favorites',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data && Array.isArray(response.data.data)) {
        setFavorites(response.data.data);
      } else {
        throw new Error(response.data.message || 'Invalid response format from the server.');
      }
    } catch (error) {
      setError(error.message || 'An error occurred while fetching favorites.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleFavorite = async (product_id, isFavorite) => {
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('userid');

    if (!token || !user_id) {
      toast.error('Token or user ID not found. Please log in again.');
      return;
    }

    const url = isFavorite
      ? 'https://zulushop.in/app/v1/api/add_to_favorites'
      : 'https://zulushop.in/app/v1/api/remove_from_favorites';

    try {
      const formData = new FormData();
      formData.append('user_id', user_id);
      formData.append('product_id', product_id);

      await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });

      toast.success(isFavorite ? 'Product added to favorites!' : 'Product removed from favorites!');

      if (isFavorite) {
        setFavorites(prevFavorites => [...prevFavorites, { id: product_id }]);
      } else {
        setFavorites(prevFavorites => prevFavorites.filter(item => item.id !== product_id));
      }

      const favorites = JSON.parse(localStorage.getItem('favorites')) || {};
      if (isFavorite) {
        favorites[product_id] = true;
      } else {
        delete favorites[product_id];
      }
      localStorage.setItem('favorites', JSON.stringify(favorites));
    } catch (error) {
      toast.error(isFavorite ? 'Failed to add product to favorites.' : 'Failed to remove product from favorites.');
    }
  };

  const handleViewDetails = (id) => {
    navigate(`/productdetail/${id}`);
  };

  const handleSaveNewAddress = (newAddressData) => {
    const updatedAddresses = [...addresses, newAddressData];
    setAddresses(updatedAddresses);
    setSelectedAddress(newAddressData.id);
    setCurrentAddress(newAddressData);
    setIsAddingNewAddress(false);
    getAddress();
  };

  const handleSaveEditAddress = (editedAddress) => {
    const updatedAddresses = addresses.map((addr) =>
      addr.id === editedAddress.id ? editedAddress : addr
    );
    setAddresses(updatedAddresses);
    setCurrentAddress(editedAddress);
    setIsEditingAddress(false);
    toast.success("Address updated successfully!");
  };

  useEffect(() => {
    fetchFavorites();
    getAddress();
  }, [fetchFavorites]);

  if (isLoading) {
    return <div className="text-center mt-8">Loading...</div>;
  }

  /*  if (error) {
     return <div className="text-center mt-8 text-red-500">Error: {error}</div>;
   } */
  const getAddressID = (data) => {
    //alert(JSON.stringify(data))
    //alert(data.id)
    setIsEditingAddress(true)
  }
 const handleConfirm = async() => {
    console.log("User chose Yes");
  //  alert(JSON.stringify(addressautoid));
  //  alert(addressautoid)
    if(addressautoid > 0)
    {
      try {
        const token = localStorage.getItem("token");
        console.log('Deleting address with ID:', addressautoid);
        const formData = new FormData();
        formData.append("id", addressautoid);
        const response = await axios.post(
          url + "/app/v1/api/delete_address",
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log('Delete response:', response.data);
        if (response.data.message) {
          setModalOpen(false);
        
          toast.success(response.data.message);
          //  getAddress(); // Refresh the addresses after deletion
        } 
        getAddress();
      } catch (error) {
        setModalOpen(false);
        console.error("Error deleting address:", error);
        toast.error("Error deleting address: " + error.message);
      }
    }
    else
    {
      toast.success('ID Not Found');
    }

    // Add your Yes action here
  };

  const handleClose = () => {
    console.log("User chose No");
    setModalOpen(false);
  };

  const handleDeleteAddress = async (data) => {
   // alert(data.id)
   if(data.id > 0)
   {
    setModalOpen(true)
    setAddressautoid(data.id)
   }
   else
   {
    alert('id not found');
   }   
  };


  return (
    <div className="mt-4 container mx-auto px-4">

      <div className="mb-8">
      {isModalOpen && (
        <ModalYesNo
          message="Do you want to proceed?"
          onClose={handleClose}
          dataid = {addressautoid}
          onConfirm={handleConfirm}
        />
      )}

        <h2 className="text-xl font-medium mb-4 text-gray-800">Your Address</h2>
        <div className="bg-white p-4 rounded-md shadow-md mb-6">
          <div className="flex justify-between text-left items-center mb-3">
            <h2 className="text-lg font-semibold text-gray-700 flex items-center">
              <FaHome className="mr-2 text-gray-500" />
              Shipping Address
            </h2>
            <div className="flex items-center">
              {/*     */}
              <button
                onClick={() => setIsAddingNewAddress(true)}
                className="text-green-500 hover:text-green-600"
              >
                <PlusCircle size={20} />
              </button>
            </div>
          </div>
          <div className='grid'>
            {addresses.length > 0 && addresses.map((address) => (
              <div
                key={address.id}
                className={`p-3 border rounded-md mb-3 text-sm ${selectedAddress === address.id ? 'bg-blue-50 border-blue-200 ' : 'bg-gray-50'}`}
              >
                <h3 className="font-medium text-gray-800 text-left">
                  {address.name} - {address.type}
                </h3>
                <p className="text-gray-600 text-left">{address.address}</p>
                <p className="text-gray-600 text-left">{address.landmark}</p>
                <p className="text-gray-600 text-left">
                  {address.city}, {address.state}, {address.country} - {address.pincode}
                </p>
                <p className="text-gray-600 text-left">{address.mobile}</p>
                <div className='text-left mt-2'>

                  <button
                    // onClick={() => setIsEditingAddress(true)}
                    onClick={() => getAddressID(address)}
                    className="text-blue-500 hover:text-blue-600 mr-2 "
                  >
                    <Edit2 size={16} />
                  </button>

                  <button
                    onClick={() => handleDeleteAddress(address)}
                  //  onClick={() => setModalOpen(true)}
                    className="text-green-500 hover:text-green-600"
                  >
                    <Trash size={20} />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <SellerAddressModal
        show={isAddingNewAddress}
        onClose={() => setIsAddingNewAddress(false)}
        onSave={handleSaveNewAddress}
      />

      <SellerEditAddressModal
        show={isEditingAddress}
        onClose={() => setIsEditingAddress(false)}
        onSelectAddress={(address) => {
          setCurrentAddress(address);
          setSelectedAddress(address.id);
        }}
      />

    </div>
  );
}

export default SellerUserAddress;
