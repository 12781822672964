import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { url } from "../../components/api/Url";
import axios from "axios";
import SellerTestimonialCard from "./SellerTestimonialCard";

const SellerTestimonials = ({ data, sellerid, title }) => {
  const [test, setTest] = useState([]);

  const getTest = async () => {
    const json = JSON.stringify({ seller_id: sellerid });
    axios.post(url + '/app/v1/api/listoftestimonal', json)
      .then((res) => {
        setTest(res.data.slice(0, 4));
      })
      .catch(() => {
        setTest([]);
      });
  };

  useEffect(() => {
    getTest();
  }, []);

  return (
    <div className="mt-16">
      <div className="text-center mx-auto">
        <div className="text-2xl font-semibold mx-auto mb-14 flex flex-col items-center justify-center">
          {title ? title : "Testimonials"}
          <div className="w-[100px] h-[3px] mb-[10px] mt-3 bg-black rounded-full"></div>
        </div>
        <div className="flex flex-nowrap md:grid md:grid-cols-4 mx-2 gap-8 mt-5 w-full overflow-x-auto md:overflow-hidden">
  {test && test.map(user => (
    <SellerTestimonialCard
      key={user.id}
      id={user.id}
      comments={user.comments}
      username={user.username}
      image={user.user_image}
    />
  ))}
</div>

      </div>
    </div>
  );
};

export default SellerTestimonials;
