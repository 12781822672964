import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";
import { url } from "../api/Url";
import { toast } from "react-toastify";
import ProductCard from "../cards/ProductCard";
import { useProduct } from "../categaries_menu/ProductContext";
import ProductCardSkeleton from "../categaries_menu/ProductCardSkeleton";

const HighlightDetail = () => {
  const { featureId } = useParams();
  const location = useLocation();
  const productIds = location.state?.productIds || [];

  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 16;
  const { selectProduct } = useProduct();

  useEffect(() => {
    console.log("Feature ID:", featureId);
    console.log("Product IDs:", productIds);
    getProducts();
  }, [featureId, productIds]);

  const getProducts = async () => {
    try {
      if (productIds.length === 0) {
        throw new Error("No product IDs available for this feature.");
      }

      const formData = new FormData();
      formData.append("limit", 1000);
      formData.append("offset", 0);

      const response = await axios.post(
        `${url}/app/v1/api/get_products`, 
        formData, 
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      console.log("API Response:", response.data);

      if (response.data.error) {
        throw new Error(response.data.message);
      }

      // Filter products based on the productIds
      const filteredProducts = response.data.data.filter(product => 
        productIds.includes(product.id.toString())
      );

      console.log("Filtered Products:", filteredProducts);

      setAllProducts(filteredProducts);
      setLoading(false);
    } catch (error) {
      console.error("Error in getProducts:", error);
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    setFilteredProducts(allProducts.slice(indexOfFirstProduct, indexOfLastProduct));
  }, [allProducts, currentPage]);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(allProducts.length / productsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-500 text-center mt-4">Error: {error}</div>;

  return (
    <div className="mt-10">
      <div className="mt-5">
        <div className="text-center mx-auto">
          <h2 className="text-2xl font-semibold">Products under Feature {featureId}</h2>
          <div className="w-28 h-1 bg-white mx-auto mt-2 rounded"></div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <div
                key={product.id}
                onClick={() => selectProduct(product)}
                className="md:ml-5 md:mx-2 lg:mx-2 mx-auto"
              >
                <ProductCard product={product} />
              </div>
            ))
          ) : (
            <div className="col-span-full text-center">No products found for this feature.</div>
          )}
        </div>

        {filteredProducts.length > 0 && (
          <div className="flex justify-center mt-5">
            <button
              className="mr-2 px-3 py-1 bg-gray-500 text-white"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Prev
            </button>
            <button
              className="ml-2 px-3 py-1 bg-gray-500 text-white"
              onClick={handleNextPage}
              disabled={currentPage === Math.ceil(allProducts.length / productsPerPage)}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default HighlightDetail;