import React, { useEffect, useState } from "react";
import { Blogcard } from "../cards/Blogcard";
import { Link, useNavigate } from "react-router-dom";
import { url } from "../api/Url";
import axios from "axios";

const Blogs2 = ({ data }) => {
  const [branddata, setBranddata] = useState([]);
  const navigate = useNavigate();

  const getBrd = async () => {
    const json = JSON.stringify({ seller_id: "" });
    
    try {
      const res = await axios.post(url + '/app/v1/api/listofblog', json);
      console.log("API Response:", res.data); // Log the API response to check data structure
      setBranddata(res.data.message.slice(0, 4));
      console.log("Brand Data after setting:", res.data.message.slice(0, 4)); // Log the data being set
    } catch (error) {
      console.error("Error fetching blog data:", error); // Log error if API call fails
      setBranddata([]);
    }
  };

  useEffect(() => {
    console.log("Fetching blog data...");
    getBrd();
  }, []);

  const handleBlogClick = (id) => {
    console.log("Blog clicked, ID:", id); // Log the blog ID on click
    navigate(`/Blog/${id}`);
  };

  return (
    <div className="mt-10">
      <div className="flex justify-between items-center">
      <div className="text-center mx-auto">
  <div className="text-2xl font-semibold">Trends near you</div>
  <Link to="/AllBlogs" className="text-black">
          View All
        </Link>
  {/* <div className="w-28 h-1 bg-black mx-auto mt-2 rounded"></div> */}
      </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5">
        {branddata.map(user => (
          <div key={user.id} onClick={() => handleBlogClick(user.id)}>
            <Blogcard 
              title={user.Title}
              Blogimage={`uploads/seller/${user.Blogimage}`}
              name={"blog"}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blogs2;
