import React, { useState } from 'react';
import {
  FaShoppingBag,
  FaMapMarkerAlt,
  FaHeart,
  FaCoins,
  FaQuestionCircle,
  FaSignOutAlt,
  FaUser,
  FaWallet,
} from 'react-icons/fa';
import OrderCard from './card/OrderCard';

const logOut = async () => {
  localStorage.clear();
  window.location.href = '/sellerhome'; // Redirect after logout
};

const ProfileMenu = ({
  onShowFavorites,
  onShowProfile,
  onShowOrders,
  onShowLoyalty,
  onShowHelpCenter,
  onShowAddress,
  onShowWallet,
}) => {
  const [activeMenu, setActiveMenu] = useState(''); // State to track active menu

  // Function to handle menu item clicks
  const handleMenuClick = (menuName, action) => {
    setActiveMenu(menuName); // Set the active menu
    action(); // Trigger the corresponding action passed in props
  };

  return (
    <div>
      <div className="flex gap-10 cursor-pointer mx-auto mb-6 w-full text-sm justify-center">
        {/* Profile */}
        <OrderCard
          name={
            <div
              className={`flex text-sm items-center ${
                activeMenu === 'profile' ? 'text-blue-500' : ''
              }`}
            >
              <FaUser className="mr-2" /> Profile
            </div>
          }
          onClick={() => handleMenuClick('profile', onShowProfile)}
        />

        {/* Orders */}
        <OrderCard
          name={
            <div
              className={`flex text-sm items-center ${
                activeMenu === 'orders' ? 'text-blue-500' : ''
              }`}
            >
              <FaShoppingBag className="mr-2" /> Orders
            </div>
          }
          onClick={() => handleMenuClick('orders', onShowOrders)}
        />

        {/* Address */}
        <OrderCard
          name={
            <div
              className={`flex text-sm items-center ${
                activeMenu === 'address' ? 'text-blue-500' : ''
              }`}
            >
              <FaMapMarkerAlt className="mr-2" /> Address
            </div>
          }
          onClick={() => handleMenuClick('address', onShowAddress)}
        />

        {/* Favourites */}
        <OrderCard
          name={
            <div
              className={`flex text-sm items-center ${
                activeMenu === 'favorites' ? 'text-blue-500' : ''
              }`}
            >
              <FaHeart className="mr-2" /> Favourites
            </div>
          }
          onClick={() => handleMenuClick('favorites', onShowFavorites)}
        />

        {/* Wallet */}
        <OrderCard
          name={
            <div
              className={`flex text-sm items-center ${
                activeMenu === 'wallet' ? 'text-blue-500' : ''
              }`}
            >
              <FaWallet className="mr-2" /> Wallet
            </div>
          }
          onClick={() => handleMenuClick('wallet', onShowWallet)}
        />

        {/* Loyalty */}
        <OrderCard
          name={
            <div
              className={`flex text-sm items-center ${
                activeMenu === 'loyalty' ? 'text-blue-500' : ''
              }`}
            >
              <FaCoins className="mr-2" /> Loyalty
            </div>
          }
          onClick={() => handleMenuClick('loyalty', onShowLoyalty)}
        />

        {/* Help Center */}
        <OrderCard
          name={
            <div
              className={`flex text-sm items-center ${
                activeMenu === 'helpCenter' ? 'text-blue-500' : ''
              }`}
            >
              <FaQuestionCircle className="mr-2" /> Help Center
            </div>
          }
          onClick={() => handleMenuClick('helpCenter', onShowHelpCenter)}
        />

        {/* Logout */}
        <OrderCard
          name={
            <div className="flex text-sm items-center">
              <FaSignOutAlt className="mr-2" /> Logout
            </div>
          }
          onClick={logOut}
        />
      </div>
    </div>
  );
};

export default ProfileMenu;
