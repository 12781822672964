import React, { useState, useEffect, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const HelpCenterPage = () => {
  const [formData, setFormData] = useState({
    senderid: '',
    sendername: '',
    Sendertype: '',
    store_id: '',
    store_name: '',
    issuetype: '',
    phoneno: '',
    question: '',
    status: '',
    answer: '',
    // disputed_party_name: '',
  });

  const [stores, setStores] = useState([]);
  const [activeSection, setActiveSection] = useState('createTicket');
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [faqs, setFaqs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [ticketTypes, setTicketTypes] = useState([]);
  const [faqTypes, setFaqTypes] = useState([]);
  const [selectedTicketType, setSelectedTicketType] = useState('all');
  const [selectedFaqType, setSelectedFaqType] = useState('all');

  
  const handleTicketTypeChange = (value) => {
    setSelectedTicketType(value);
    setActiveSection('viewTickets');
  };

  const handleFaqTypeChange = (value) => {
    setSelectedFaqType(value);
    setActiveSection('faqs');
  };

  useEffect(() => {
    if (activeSection === 'faqs') {
      fetchFaqs();
      fetchFaqTypes();
    }
  }, [activeSection]);

  const fetchTicketTypes = async () => {
    setLoading(true);
    setError(null);
  
    try {
      const userId = localStorage.getItem('userid');
      if (!userId) throw new Error('User ID not found in local storage');
  
      const response = await fetch(`https://zulushop.in/app/v1/api/listofticketing?userid=${userId}`);
      const data = await response.json();
  
      if (response.ok) {
        // Filter tickets for the current user
        const filteredTickets = data.filter(ticket => ticket.senderid === userId);
        
        // Extract unique ticket types from the filtered tickets
        const types = new Set(
          filteredTickets
            .map(ticket => ticket.issuetype)
            .filter(type => type !== null && type !== '')
        );
  
        // Set ticket types, including 'all' as an option
        setTicketTypes(['All', ...Array.from(types)]);
      } else {
        setError('Failed to load ticket types.');
      }
    } catch (error) {
      setError('An error occurred while fetching the ticket types.');
    } finally {
      setLoading(false);
    }
  };
  
  
  useEffect(() => {
    fetchTicketTypes();
  }, []);

  const fetchFaqTypes = async () => {
    try {
      const response = await fetch('https://zulushop.in/app/v1/api/get_faq_types', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();
      if (response.ok && !data.error) {
        setFaqTypes(['all', ...data.types]);
      } else {
        console.error('Failed to fetch FAQ types:', data.message);
      }
    } catch (error) {
      console.error('Error fetching FAQ types:', error);
    }
  };

  const fetchFaqs = async () => {
    try {
      const response = await fetch('https://zulushop.in/app/v1/api/get_faqs', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();
      
      if (response.ok && !data.error) {
        setFaqs(data.data);
      } else {
        console.error('Failed to fetch FAQs:', data.message);
      }
    } catch (error) {
      console.error('Error fetching FAQs:', error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  
  // Filter FAQs based on search query
  const filteredFaqs = faqs.filter((faq) =>
    faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
    faq.answer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    const userId = localStorage.getItem('userid');
    if (userId) {
      setFormData((prevData) => ({
        ...prevData,
        senderid: userId
      }));
    }
    
    // Fetch stores when the component mounts
    fetchStores();
  }, []);



  const fetchStores = async () => {
    try {
      const response = await fetch('https://zulushop.in/app/v1/api/get_sellers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });
      const data = await response.json();
  
      if (response.ok && !data.error) {
        const storeList = data.data.map(seller => ({
          id: seller.seller_id,
          name: seller.store_name
        }));
        setStores(storeList);
  
        // Log the fetched store options to the console
        console.log('Store options:', storeList);
      } else {
        console.error('Failed to fetch stores:', data.message);
      }
    } catch (error) {
      console.error('Error fetching stores:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

const handleSubmit = async (e) => {
  e.preventDefault();

  const formDataToSend = new FormData();
  Object.keys(formData).forEach((key) => {
    formDataToSend.append(key, formData[key]);
  });

  // Log the form data being submitted
  console.log('Form Data Submitted:', Object.fromEntries(formDataToSend.entries()));

  if (!formData.store_id) {
    toast.error('Store ID is required.');
    return;
  }

  try {
    const response = await fetch('https://zulushop.in/app/v1/api/create_ticketing_api', {
      method: 'POST',
      body: formDataToSend
    });

    if (response.ok) {
      const responseData = await response.json();
      toast.success('Ticket created successfully.');
    } else {
      const errorText = await response.text();
      toast.error('Failed to create ticket.');
    }
  } catch (error) {
    toast.error('An error occurred while creating the ticket.');
  }
};

const fetchTickets = async () => {
  setLoading(true);
  setError(null);

  try {
    const userId = localStorage.getItem('userid');
    if (!userId) throw new Error('User ID not found in local storage');

    const response = await fetch(`https://zulushop.in/app/v1/api/listofticketing?userid=${userId}`);
    const data = await response.json();

    if (response.ok) {
      let filteredTickets = data.filter(ticket => ticket.senderid === userId);
      
      if (selectedTicketType !== 'All') {
        filteredTickets = filteredTickets.filter(ticket => ticket.issuetype === selectedTicketType);
      }
      
      setTickets(filteredTickets);

      if (filteredTickets.length > 0) {
        toast.success('Tickets loaded.');
      } else {
        toast.info('No tickets found for this user.');
      }
    } else {
      setError('Failed to load tickets.');
    }
  } catch (error) {
    setError('An error occurred while fetching the tickets.');
  } finally {
    setLoading(false);
  }
};

useEffect(() => {
  if (activeSection === 'viewTickets') {
    fetchTickets();
  }
}, [activeSection, selectedTicketType]);

const Dropdown = ({ options, value, onChange, buttonText, isActive, setActiveSection, sectionName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  const handleButtonClick = () => {
    setActiveSection(sectionName);
    setIsOpen(!isOpen);
  };



  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => {
          setActiveSection(sectionName);
          setIsOpen(!isOpen);
        }}
        className={`px-4 py-2 rounded-md ${isActive ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'}`}
      >
        {buttonText}
      </button>
      {isOpen && (
        <ul className="absolute left-0 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-sm z-10">
          {options.map((option) => (
            <li
              key={option}
              onClick={() => handleSelect(option)}
              className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${option === value ? 'bg-blue-100' : ''}`}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

  const renderSection = () => {
    switch (activeSection) {
      case 'createTicket':
        return (
          <div className="mt-10 text-left">
            <h3 className="text-lg font-semibold mb-4">Create a Support Ticket</h3>
            <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="sendername" className="block text-sm font-medium text-gray-700">
                  Your Name
                </label>
                <input
                  type="text"
                  name="sendername"
                  id="sendername"
                  value={formData.sendername}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                />
              </div>
        
              <div>
                <label htmlFor="Sendertype" className="block text-sm font-medium text-gray-700">
                  Sender Type
                </label>
                <select
                  name="Sendertype"
                  id="Sendertype"
                  value={formData.Sendertype}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                >
                  <option value="">Select Sender Type</option>
                  <option value="customer">Customer</option>
                  <option value="vendor">Vendor</option>
                  <option value="employee">Employee</option>
                </select>
              </div>
        
              <div>
                <label htmlFor="phoneno" className="block text-sm font-medium text-gray-700">
                  Phone Number
                </label>
                <input
                  type="tel"
                  name="phoneno"
                  id="phoneno"
                  value={formData.phoneno}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                />
              </div>
        
              <div>
                <label htmlFor="store_id" className="block text-sm font-medium text-gray-700">
                  Select Store
                </label>
                <select
                  name="store_id"
                  id="store_id"
                  value={formData.store_id}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                >
                  <option value="">Select Store</option>
                  {stores.map((store) => (
                    <option key={store.id} value={store.id}>
                      {store.name}
                    </option>
                  ))}
                </select>
              </div>
        
              <div>
                <label htmlFor="issuetype" className="block text-sm font-medium text-gray-700">
                  Issue Type
                </label>
                <input
                  type="text"
                  name="issuetype"
                  id="issuetype"
                  value={formData.issuetype}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                />
              </div>
        
              <div className="col-span-2">
                <label htmlFor="question" className="block text-sm font-medium text-gray-700">
                  Question/Issue Description
                </label>
                <textarea
                  name="question"
                  id="question"
                  value={formData.question}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                ></textarea>
              </div>
        
              <div>
                <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                  Status
                </label>
                <input
                  type="text"
                  name="status"
                  id="status"
                  value={formData.status}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                />
              </div>
        
              <div>
                <label htmlFor="store_name" className="block text-sm font-medium text-gray-700">
                  Select Store
                </label>
                <select
                  name="store_name"
                  id="store_name"
                  value={formData.store_name}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                >
                  <option value="">Select Store</option>
                  {stores.map((store) => (
                    <option key={store.name} value={store.name}>
                      {store.name}
                    </option>
                  ))}
                </select>
              </div>
        
              <div>
                <label htmlFor="answer" className="block text-sm font-medium text-gray-700">
                  Answer
                </label>
                <input
                  type="text"
                  name="answer"
                  id="answer"
                  value={formData.answer}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                />
              </div>
        
              <div>
                <label htmlFor="disputed_party_name" className="block text-sm font-medium text-gray-700">
                  Disputed Party Name
                </label>
                <input
                  type="text"
                  name="disputed_party_name"
                  id="disputed_party_name"
                  value={formData.disputed_party_name}
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm"
                />
              </div>
        
              <div className="col-span-2">
                <button
                  type="submit"
                  className="w-full bg-blue-600 text-white p-2 rounded-md shadow-sm hover:bg-blue-700"
                >
                  Submit Ticket
                </button>
              </div>
            </form>
          </div>
        );
        
        case 'faqs':
          return (
            <div className="mt-10 text-left">
              {/* <h3 className="text-lg font-semibold mb-4">Frequently Asked Questions</h3> */}
                {/* Search bar */}
      <div className="mb-4">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search FAQs"
          className="w-full p-2 border border-gray-300 rounded-md shadow-sm"
        />
      </div>

      {/* Display filtered FAQs */}
      {filteredFaqs.length > 0 ? (
        <ul className="space-y-4">
          {filteredFaqs.map((faq) => (
            <li key={faq.id} className="text-gray-700">
              <strong>Q:</strong> <span className='font-bold'>{faq.question}</span>
              <br />
              <strong>A:</strong> {faq.answer}
            </li>
          ))}
        </ul>
      ) : (
        <p>No FAQs match your search.</p>
      )}
    </div>
  );
      case 'viewTickets':
        return (
          <div className="mt-10 text-left">
            <h3 className="text-lg font-semibold mb-4">View Tickets</h3>
            {loading ? (
              <p>Loading tickets...</p>
            ) : error ? (
              <p className="text-red-600">{error}</p>
            ) : (
              <ul className="space-y-4">
                {tickets.map((ticket) => (
                  <li key={ticket.id} className="border-b border-gray-300 pb-4">
                    <p><strong>Ticket ID:</strong> {ticket.id}</p>
                    <p><strong>Issue Type:</strong> {ticket.issuetype}</p>
                    <p><strong>Description:</strong> {ticket.question}</p>
                    <p><strong>Status:</strong> {ticket.status}</p>
                  </li>
                ))}
              </ul>
            )}
          </div>
        );
      case 'updateTicket':
        return (
          <div className="mt-10 text-left">
            <h3 className="text-lg font-semibold mb-4">Update Ticket</h3>
            <p>You can update your existing tickets here.</p>
            {/* Provide form or method to update tickets */}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="mx-auto mt-8 px-4 md:px-8 lg:px-16 max-w-4xl">
      <h2 className="text-2xl font-semibold text-center mb-8">Help Center</h2>

      {/* Navigation Header */}
      <div className="flex justify-around mb-8">
        <button
          onClick={() => setActiveSection('createTicket')}
          className={`px-4 py-2 rounded-md ${activeSection === 'createTicket' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'}`}
        >
          Create Ticket
        </button>
        <Dropdown
          options={ticketTypes}
          value={selectedTicketType}
          onChange={handleTicketTypeChange}
          buttonText="View Tickets"
          isActive={activeSection === 'viewTickets'}
          setActiveSection={setActiveSection}
          sectionName="viewTickets"
        />
        <Dropdown
          options={faqTypes}
          value={selectedFaqType}
          onChange={handleFaqTypeChange}
          buttonText="FAQs"
          isActive={activeSection === 'faqs'}
          setActiveSection={setActiveSection}
          sectionName="faqs"
        />
   
        {/* <button
          onClick={() => setActiveSection('updateTicket')}
          className={`px-4 py-2 rounded-md ${activeSection === 'updateTicket' ? 'bg-blue-600 text-white' : 'bg-gray-200 text-gray-700'}`}
        >
          Update Ticket
        </button> */}
      </div>

      {/* Render the selected section */}
      {renderSection()}
    </div>
  );
};

export default HelpCenterPage;
