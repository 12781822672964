import React, { useState, useEffect } from 'react'
import { url } from '../../components/api/Url';
import axios from 'axios';
import { json, useParams, Link } from "react-router-dom";

const SellerReturnPolicy = () => {

  const [privacypolice, setPrivacypolice] = useState([]);
  const sellerid = localStorage.getItem('seller_id');
  const sellerslug = localStorage.getItem('seller_slug');

  async function getTermsconditions() {
    try {
      console.log("setPrivacypolice data...");
      const json = JSON.stringify({ seller_id: sellerid});
      let response = await axios.post(url + "/app/v1/api/read_seller_privacy_policy", json); //get_seller_features_sections_final
      console.log("feat API response:", response);
      let res = await response.data.message;
      console.log("setPrivacypolice:", res);
      res.length > 0 ?  setPrivacypolice(res) : setPrivacypolice([]);
     
    }
    catch (error) {
      // console.error("Error submitting form:", error);
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getTermsconditions();
  }, []);
  return (
    <div className="mx-[100px]">
      <div className="grid" style={{ marginTop: 100 }}>
        {privacypolice?.map((item) => (
          <div key={item.id} className="banner" >{/* onClick={() => handleHighlightClick(item.id)} */}
            <div>{item.return_policy}</div>
          </div>
        ))}
      </div>

    </div>
  )
}
export default SellerReturnPolicy;