import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { url } from "../../../components/api/Url";

const SellerAddressModal = ({ show, onClose, onSave }) => {
  const [newAddress, setNewAddress] = useState({
    name: "",
    type: "Home",
    mobile: "",
    alternate_mobile: "",
    address: "",
    landmark: "",
    city_id: "",
    area_id: "",
    city: "",
    area: "",
    pincode: "",
    country_code: "",
    state: "",
    system_pincode: "1",
    country: "",
    latitude: "",
    general_area_name: "",
    longitude: "",
    is_default: "1",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.post(url + "/app/v1/api/get_country");
        setCountries(response.data || []);
      } catch (error) {
        toast.error("Error fetching countries: " + error.message);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    if (newAddress.country_code) {
      const fetchCities = async () => {
        try {
          const response = await axios.post(
            url + "/app/v1/api/get_cities",
            { country_code: newAddress.country_code }
          );
          setCities(response.data.data || []);
        } catch (error) {
          toast.error("Error fetching cities: " + error.message);
        }
      };
  
      fetchCities();
    }
  }, [newAddress.country_code]);

  if (!show) return null;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const user_id = localStorage.getItem("userid");

    const formData = new FormData();
    Object.entries(newAddress).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("user_id", user_id || "");

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Token not found in localStorage");
      }

      const response = await axios.post(
        url + "/app/v1/api/add_address",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success(response.data.message);
      onSave(newAddress);
      onClose();
    } catch (error) {
      toast.error("Error adding address: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex">
      <div className="relative p-8 bg-white w-full max-w-lg m-auto flex-col flex rounded-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Add Address</h2>
          <button onClick={onClose} className="text-2xl font-bold">
            ×
          </button>
        </div>
        <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-4 space-y-0">
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={newAddress.name}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border rounded-lg"
            required
          />
          <select
            name="type"
            value={newAddress.type}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border rounded-lg"
          >
            <option value="Home">Home</option>
            <option value="Office">Office</option>
            <option value="Other">Other</option>
          </select>
          <input
            type="number"
            name="mobile"
            placeholder="Mobile Number"
            value={newAddress.mobile}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border rounded-lg"
            required
          />
          <input
            type="number"
            name="alternate_mobile"
            placeholder="Alternate Mobile"
            value={newAddress.alternate_mobile}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border rounded-lg"
          />
          <input
            type="text"
            name="address"
            placeholder="Address"
            value={newAddress.address}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border rounded-lg"
            required
          />
          <input
            type="text"
            name="landmark"
            placeholder="Landmark"
            value={newAddress.landmark}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border rounded-lg"
          />
           <select
            name="country_code"
            value={newAddress.country_code}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border rounded-lg"
            required
          >
            <option value="">Select Country</option>
            {countries.map((country) => (
              <option key={country.id} value={country.id}>
                {country.name}
              </option>
            ))}
          </select>
          <select
            name="city_id"
            value={newAddress.city_id}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border rounded-lg"
            required
          >
            <option value="">Select City</option>
            {cities.map((city) => (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            ))}
          </select>
          <input
            type="text"
            name="area"
            placeholder="Area"
            value={newAddress.area}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border rounded-lg"
            required
          />
          <input
            type="number"
            name="pincode"
            placeholder="Pincode"
            value={newAddress.pincode}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border rounded-lg"
            required
          />
          <input
            type="text"
            name="state"
            placeholder="State"
            value={newAddress.state}
            onChange={handleInputChange}
            className="w-full px-4 py-2 border rounded-lg"
            required
          />
          <label className="flex items-center col-span-2">
            <input
              type="checkbox"
              name="is_default"
              checked={newAddress.is_default === "1"}
              onChange={(e) =>
                setNewAddress((prev) => ({
                  ...prev,
                  is_default: e.target.checked ? "1" : "0",
                }))
              }
              className="mr-2"
            />
            Default Address
          </label>
          <div className="col-span-2 mt-6 flex justify-end space-x-2">
            <button
              type="button"
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SellerAddressModal;
