import React, { useCallback, useMemo, useRef, useEffect } from 'react';
import { Trash2, Plus, Minus, X } from 'lucide-react';
import { useSellerCart } from '../../categaries_menu/SellerCartContext'; // Updated to use SellerCartContext
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { FaShoppingCart } from 'react-icons/fa';
import Empty from '../../../assets/images/States/empty-cart-illustration.gif';

const CartItem = React.memo(({ item, onAdd, onRemove, onDelete }) => {
  const calculateDiscount = useCallback((regularPrice, specialPrice) => {
    if (regularPrice > specialPrice) {
      return Math.round(((regularPrice - specialPrice) / regularPrice) * 100);
    }
    return 0;
  }, []);

  return (
    <div className="flex flex-row lg:flex-row bg-white p-3 mb-6 shadow-md text-sm rounded-lg">
      <img
        src={item.image}
        alt={item.name}
        className="w-16 mr-4 lg:w-16 h-16 object-cover rounded-lg lg:mr-4 mb-4 lg:mb-0"
      />
      <div className="flex-grow text-left">
        <h3 className="text-base font-semibold">{item.name}</h3>
      {/*   <p className="text-gray-600">Size: {item.size}</p>
        <p className="text-gray-600">Color: {item.color}</p> */}
        <div className="flex items-center mt-4 space-x-4">
          <button
            onClick={() => onDelete(item.id, item.size)}
            className="text-red-500 hover:text-red-700 flex text-xs items-center"
          >
            <Trash2 className="mr-1 w-4 h-4" /> Remove
          </button>
        </div>
      </div>
      <div className="flex items-center justify-between lg:flex-col mt-4 lg:mt-0">
        <div className="flex items-center text-xs">
          <button
            onClick={() => onRemove(item.id, item.size)}
            className="p-1 rounded-full hover:bg-gray-200"
          >
            <Minus size={15} />
          </button>
          <span className="mx-4 text-center">{item.quantity}</span>
          <button
            onClick={() => onAdd(item, item.size)}
            className="p-1 rounded-full hover:bg-gray-200"
          >
            <Plus size={15} />
          </button>
        </div>
        <div className="text-sm text-right mt-20 lg:mt-0">
          {item.min_max_price.regular_price > item.min_max_price.special_price ? (
            <div className="flex flex-col items-end">
              <span className="text-gray-500 line-through mr-2">
                ₹{item.min_max_price.regular_price}
              </span>
              <span className="font-semibold text-gray-800">
                ₹{item.min_max_price.special_price}
              </span>
              <span className="bg-green-500 text-white px-2 py-1 text-xs rounded mt-1">
                {calculateDiscount(
                  item.min_max_price.regular_price,
                  item.min_max_price.special_price
                )}% OFF
              </span>
            </div>
          ) : (
            <span className="font-semibold text-gray-800">
              ₹{item.min_max_price.special_price}
            </span>
          )}
        </div>
      </div>
    </div>
  );
});

const SellerShoppingCartWindow = ({ isOpen, onClose }) => {
  const { sellerCartItems, addToCart, removeFromCart, deleteFromCart } = useSellerCart(); // Access cart items from context
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const sellerid = localStorage.getItem('seller_id');
  const sellerslug = localStorage.getItem('seller_slug');
  // Log seller cart items to console
  useEffect(() => {
    console.log('Current Cart Items:', sellerCartItems);
  }, [sellerCartItems]);

  const handleGoToCart = useCallback(() => {
    navigate(`/o/${sellerslug}/cart`);
    onClose();
  }, [navigate, onClose]);

  const handleGoToCheckout = useCallback(() => {
    const userid = localStorage.getItem("userid");
    if(userid)
    {
     navigate(`/o/${sellerslug}/checkout`);
    }
    else
    {
     // alert('login');
      navigate(`/o/${sellerslug}/login`);
    }
    onClose();
  }, [navigate, onClose])

  const calculateTotal = useMemo(() => {
    return sellerCartItems.reduce(
      (total, item) => total + item.min_max_price.special_price * item.quantity,
      0
    );
  }, [sellerCartItems]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-40">
      {/* Modal Background */}
      <div className="fixed inset-0 bg-black opacity-70" onClick={onClose}></div>
      {/* Modal Content */}
      <div
        ref={modalRef}
        className="fixed inset-y-0 right-0 w-full sm:w-96 bg-white shadow-lg transform translate-x-0 transition-transform duration-300 ease-in-out z-50"
      >
        <div className="h-full flex flex-col">
          {/* Header */}
          <div className="flex justify-between items-center p-4 border-b">
            <h2 className="text-xl font-bold items-center flex">
              <FaShoppingCart className="mr-2" /> Cart
            </h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <X size={24} />
            </button>
          </div>

          {/* Cart Items */}
          <div className="flex-grow overflow-y-auto p-4 text-sm">
            {sellerCartItems.length === 0 ? (
              <div className="flex justify-center items-center flex-col">
                <img
                  src={Empty}
                  alt="Empty cart"
                  className="w-40 h-40"
                />
                <p className="text-gray-500 mt-4">Your cart is empty</p>
              </div>
            ) : (
              sellerCartItems.map((item) => (
                <CartItem
                  key={`${item.id}-${item.size}`}
                  item={item}
                  onAdd={addToCart}
                  onRemove={removeFromCart}
                  onDelete={deleteFromCart}
                />
              ))
            )}
          </div>

          {/* Footer with Total and Buttons */}
          <div className="border-t p-4">
            <div className="flex justify-between mb-4">
              <span>Total:</span>
              <span className="font-bold">₹{calculateTotal.toFixed(2)}</span>
            </div>
            <button
              onClick={handleGoToCart}
              className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mb-2"
            >
              View Cart
            </button>
            <button    onClick={handleGoToCheckout} className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded">
              Checkout
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default React.memo(SellerShoppingCartWindow);
