import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { json, useParams } from "react-router-dom";
import Card2 from "../../components/cards/Card2";
import Header from "../../components/header/Header";
import { url } from "../../components/api/Url";
import UserContext from "../../context/UserContext";
import Footer from "../../components/footer/Footer";
import Brandallcomponent from "../../components/template_one/Brandallcomponent";


const Brandall = () => {
  const { id } = useParams();
  const [data, setData] = useState([])
  const [categoriesdata, setCategoriesdata] = useState([]);
  const [sellerslug, setSellerslug] = useState(id);
  const [sellerid, setSellerid] = useState('');
  const [isloading, setIsloading] = useState(false);
  console.log("data",data)

  useEffect(() => {
    window.scrollTo(0, 0);
    
  }, []);

  return (
    <>
    <div style={{ marginTop: 100 }}>
     <Brandallcomponent/>
     </div>
    </>
  );
};

export default Brandall;
