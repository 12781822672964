import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { url } from '../api/Url'; // Assuming you have an api/Url.js file with the URL
import { FaRegHeart, FaTrash } from 'react-icons/fa';

function FavoritesPage() {
  const [favorites, setFavorites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const fetchFavorites = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('token');
      const userid = localStorage.getItem('userid');
      
      if (!token || !userid) {
        throw new Error('Token or user ID not found. Please log in again.');
      }

      const formData = new FormData();
      formData.append('user_id', userid);
      formData.append('limit', '10');
      formData.append('offset', '0');

      const response = await axios.post(
        'https://zulushop.in/app/v1/api/get_favorites',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data && Array.isArray(response.data.data)) {
        setFavorites(response.data.data);
      } else {
        throw new Error(response.data.message || 'Invalid response format from the server.');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError(error.message || 'An error occurred while fetching favorites.');
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleFavorite = async (product_id, isFavorite) => {
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('userid');

    if (!token || !user_id) {
      toast.error('Token or user ID not found. Please log in again.');
      return;
    }

    const url = isFavorite
      ? 'https://zulushop.in/app/v1/api/add_to_favorites'
      : 'https://zulushop.in/app/v1/api/remove_from_favorites';

    try {
      const formData = new FormData();
      formData.append('user_id', user_id);
      formData.append('product_id', product_id);

      await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });

      toast.success(isFavorite ? 'Product added to favorites!' : 'Product removed from favorites!');

      // Update local state to remove or add the item
      if (isFavorite) {
        setFavorites(prevFavorites => [...prevFavorites, { id: product_id }]);
      } else {
        setFavorites(prevFavorites => prevFavorites.filter(item => item.id !== product_id));
      }

      // Update favorite state in local storage
      const favorites = JSON.parse(localStorage.getItem('favorites')) || {};
      if (isFavorite) {
        favorites[product_id] = true;
      } else {
        delete favorites[product_id];
      }
      localStorage.setItem('favorites', JSON.stringify(favorites));
    } catch (error) {
      toast.error(isFavorite ? 'Failed to add product to favorites.' : 'Failed to remove product from favorites.');
    }
  };

  const handleViewDetails = (id) => {
    navigate(`/productdetail/${id}`);
  };

  useEffect(() => {
    fetchFavorites();
  }, [fetchFavorites]);

  if (isLoading) {
    return <div className="text-center mt-8">Loading favorites...</div>;
  }

  if (error) {
    return <div className="text-center mt-8 text-red-500">Error: {error}</div>;
  }

  return (
    <div className="mt-4 container mx-auto px-4">
      <ToastContainer />
      <h2 className="text-2xl font-semibold mb-4">Your Favorite Products</h2>
      {favorites.length === 0 ? (
        <p className="text-center text-gray-600">You don't have any favorite products yet.</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-6">
          {favorites.map((favorite) => (
            <div key={favorite.id} className="border rounded-lg p-4 flex shadow-sm hover:shadow-md transition-shadow duration-300">
              <img src={`${url}/${favorite.image}`} alt={favorite.name} className="w-16 h-24 object-cover mb-3 rounded" />
              <div className="flex-col w-full">
                <h3 className="font-semibold text-sm text-left ml-2 mb-2">{favorite.name}</h3>
                <p className="text-gray-600 text-xs text-left mb-2 ml-2">{favorite.short_description}</p>
                <button 
                  onClick={() => handleViewDetails(favorite.id)} 
                  className="text-white p-2 rounded transition-colors duration-300 mt-2 flex items-center bg-blue-400 text-xs ml-2"
                >
                  View Details
                </button>
                {/* <button 
                  onClick={() => handleFavorite(favorite.id, false)} 
                  className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors duration-300 mt-2"
                >
                  Remove from Favorites
                </button> */}
                <button 
                 onClick={() => handleFavorite(favorite.id, false)} 
                  className=" text-white p-2 rounded transition-colors duration-300 mt-2 flex items-center"
                >
                  <FaTrash className=" text-red-600" />
                  {/* <span className="ml-2">Remove from Favorites</span> */}
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default FavoritesPage;
