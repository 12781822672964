import React from "react";
import { url } from "../../components/api/Url";

const SellerTestimonialCard = ({ id, username, comments, image }) => {
  const path = "/uploads/seller/";
  return (   
    <a href="#" className="flex flex-col items-center bg-white border border-gray-200 text-left rounded-lg shadow md:flex-row min-w-[250px] hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
    <img className="object-cover  h-20 w-20 ml-2 mt-2 md:mt-0 rounded-2xl" 
    src={`${url}/${path}/${image}`}
    alt=""/>
  
    <div className="flex flex-col  p-4 leading-normal">
        <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white"> {comments}</h5>
        <p className="mb-3 font-normal text-xs text-gray-700 dark:text-gray-400"> {comments}</p>
    </div>
</a>
  );
};

export default SellerTestimonialCard;
