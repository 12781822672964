import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { url } from "../../components/api/Url";
import axios from "axios";
import Seller_brand_card from "./Seller_brand_card";


const Seller_brand = ({ data }) => {
  const [branddata, setBranddata] = useState([]);
  const sellerid = localStorage.getItem('seller_id');
  const sellerslug = localStorage.getItem('seller_slug');
  const getBrd = async () => {
    const json = JSON.stringify({ seller_id: sellerid });
    axios.post(url + '/app/v1/api/get_brand_data_product_web', json)
      .then((res) => {
        // console.log('data brand' + JSON.stringify(res.data.message));
        setBranddata(res.data.message.slice(0, 8))
        // setBranddata([])
      })
      .catch(() => {
        setBranddata([])
      });
  }
  useEffect(() => {
    getBrd();
  }, [])


  return (
    <div className="mt-16">
      <div className="flex justify-between mb-10">
        <div className="text-center mx-auto md:mx-auto">
        <div className="text-2xl font-semibold mx-auto  flex flex-col items-center justify-center">{data?.component?.brand?.title}

<div className="w-[100px] h-[3px] mb-[10px] mt-3 bg-black rounded-full"></div>
</div>
          {/* <div className="w-28 h-1 bg-white mx-auto mt-2 rounded">
            <Link to={`/o/${sellerslug}/brand-all`} className="text-black">
              view all
            </Link>
          </div> */}
        </div>

      </div>
      {/*  <div className="flex justify-between items-center">
        <h1 className="text-left font-bold text-[22px] mb-2" style={{color:`${data?.component?.image_gallery?.color}`}}>{data?.component?.brand?.title}</h1>
        <Link to={`/o/${sellerslug}/brand-all`} className="text-blue-700">view all</Link>
      </div> */}
      <div className=" flex-wrap grid md:grid-cols-8 grid-cols-3 gap-4 justify-center md:justify-between mt-5">

        {branddata && branddata.map(user => (

          <Seller_brand_card key={user.brand_id} className="max-w-[200px]" brand_id={user.brand_id}
            brand_name={user.brand_name}
            brand_slug={user.brand_slug}
            brand_img={user.brand_img}
          />
        ))}
      </div>
    </div>
  );
};

export default Seller_brand;
