import React, { useState, useEffect } from "react";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";

const SellerProductCard = ({ product, handleFavorite }) => {
  const [isProductFavorited, setIsProductFavorited] = useState(false);
  const sellerslug = localStorage.getItem('seller_slug');

  useEffect(() => {
    fetchFavoriteState();
  }, [product.id]);

  const fetchFavoriteState = async () => {
    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      const response = await axios.get(`https://zulushop.in/app/v1/api/get_favorites/${product.id}`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      setIsProductFavorited(response.data.is_favorite);

      // Update local storage
      const favorites = JSON.parse(localStorage.getItem('favorites')) || {};
      favorites[product.id] = response.data.is_favorite;
      localStorage.setItem('favorites', JSON.stringify(favorites));
    } catch (error) {
      console.error("Error fetching favorite state:", error);
    }
  };

  const roundPrice = (price) => Math.round(price);

  const toggleFavorite = async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      toast.error("Please log in to add to favorites.");
      return;
    }

    const updatedIsFavorited = !isProductFavorited;
    setIsProductFavorited(updatedIsFavorited);
    
    try {
      await handleFavorite(product.id, updatedIsFavorited);
      
      // Update local storage
      const favorites = JSON.parse(localStorage.getItem('favorites')) || {};
      if (updatedIsFavorited) {
        favorites[product.id] = true;
      } else {
        delete favorites[product.id];
      }
      localStorage.setItem('favorites', JSON.stringify(favorites));
    } catch (error) {
      console.error("Error toggling favorite:", error);
      setIsProductFavorited(!updatedIsFavorited); // Revert the state if the API call fails
      toast.error("Failed to update favorite status. Please try again.");
    }
  };

  const handleProductClick = () => {
    window.location.href = `/o/${sellerslug}/${product.id}/product`;
  };

  return (
    <div className="rounded-lg overflow-hidden mb-2 md:w-[250px] w-[250px] lg:w-[250px]">
      <img
        src={product.image}
        alt={product.name}
        className="w-full h-64 object-cover rounded-2xl border cursor-pointer"
        onClick={handleProductClick}
      />

      <div className="p-4">
        <div className="flex justify-between items-center mb-2">
          <span
            className="text-sm font-semibold flex cursor-pointer"
            onClick={handleProductClick}
          >
            {product.name || product.brand}
          </span>

          <button
            className={`text-gray-500 ${isProductFavorited ? 'text-red-500' : 'hover:text-red-500'}`}
            onClick={(e) => {
              e.stopPropagation();
              toggleFavorite();
            }}
          >
            {isProductFavorited ? <FaHeart /> : <FaRegHeart />}
          </button>
        </div>

        <p className="text-gray-700 text-xs text-left mb-2">{product.name}</p>

        <div className="flex items-center">
          <span className="mr-2">
            ₹{roundPrice(product.variants[0].special_price || product.variants[0].price)}
          </span>
          {product.min_max_price.discount_in_percentage > 0 && (
            <div className="flex items-center">
              <span className="text-gray-500 line-through text-sm mr-2">
                ₹{roundPrice(product.variants[0].price)}
              </span>
              <span className="bg-green-500 text-white px-2 py-1 text-[10px] rounded">
                {roundPrice(product.min_max_price.discount_in_percentage)}% OFF
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SellerProductCard;
