import React, { useState, useEffect } from 'react'
import { url } from '../api/Url';
import axios from 'axios';
import { json, useParams, Link } from "react-router-dom";
import SellerProductCard from '../cards/SellerProductCard';

const Feature_ssection_component = () => {

  const [featureData, setFeatureData] = useState([]);
  const [productData, setProductData] = useState([]);
  const sellerid = localStorage.getItem('seller_id');
  const sellerslug = localStorage.getItem('seller_slug');
  const { ids } = useParams();

  async function getFeatures() {
    const json = JSON.stringify({ seller_id: sellerid, id: ids });
    try {
      let response = await axios.post(url + "/app/v1/api/view_features_section_seller", json);
      let res = await response.data;
      //  const data = res[0]?.json_component
      setFeatureData(res);
      setProductData(res.product_details);
      console.log('....res....' + res.product_details);
      /* 
            setError(true);
      */
    } catch (error) {

      console.log("ERROR MESSAGE :: ", error.message);
    }
    /*  try {
       console.log("Fetching features data...");
       const json = JSON.stringify({ seller_id: sellerid, id: ids });
       let response = await axios.post(url + "/app/v1/api/view_features_section_seller", json); //get_seller_features_sections_final
       let res = await response.data;
       console.log("curate data:", res);
       setFeatureData(res);
       setProductData(res.product_details.productinfo);//product_details
       console.log('....data....' + res[0].product_details)
     }
     catch (error) {
       // console.error("Error submitting form:", error);
     } */
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getFeatures();
  }, []);

  const roundPrice = (price) => Math.round(price);

  return (
    <div>
      <div className="w-full justify-center items-center">
        {featureData.map((item) => (
          //const prod = item.product_details.productinfo;
          <div key={item.id} className="banner" >{/* onClick={() => handleHighlightClick(item.id)} */}
            <img
              src={`${url}/uploads/seller/${item.banner}`}
              className="w-full h-full object-cover rounded-lg"
              alt={item.title}
            />
            <p>{item.title}</p>
            <div>{item.short_description}</div>
            <div className='text-left flex-wrap grid grid-cols-2 gap-4 md:grid-cols-4 mt-20'>
              {
                item.product_details?.productinfo?.map((product => (
                  <Link
                    key={product.id}
                    to={`/o/${sellerslug}/${product.id}/product`}
                    // onClick={() => selectProduct(product)}
                    className="grid-cols-2 gap-4 md:grid-cols-4"
                  >
                    <Link to={`/o/${sellerslug}/${product.id}/product`}>
                      <img
                        src={url + '/' + product.image}
                        alt={product.name}
                        className="w-full h-64 object-cover rounded-2xl border cursor-pointer"
                      />
                    </Link>
                    <div>{product.name}</div>
                    <div className="flex items-center">
                      <span className="mr-2">
                        ₹{roundPrice(product.variants[0].special_price || product.variants[0].price)}
                      </span>
                   {/*    {product.min_max_price.discount_in_percentage > 0 && (
                        <div className="flex items-center">
                          <span className="text-gray-500 line-through text-sm mr-2">
                            ₹{roundPrice(product.variants[0].price)}
                          </span>
                          <span className="bg-green-500 text-white px-2 py-1 text-[10px] rounded">
                            {roundPrice(product.min_max_price.discount_in_percentage)}% OFF
                          </span>
                        </div>
                      )} */}
                    </div>
                    {/*  <SellerProductCard product={product}  /> */} {/* handleFavorite={handleFavorite} */}
                  </Link>
                )))
              }
            </div>
          </div>
        ))}
      </div>
      <div className="grid justify-center items-center">
        {productData?.map((item) => (
          <div key={item.id} className="banner" >{/* onClick={() => handleHighlightClick(item.id)} */}
            {/*   <img
              src={`${url}/uploads/seller/${item.banner}`}
              className="w-full h-full object-cover rounded-lg"
              alt={item.title}
            /> */}
            <p>RRRR {item.name}</p>
          </div>
        ))}
      </div>

    </div>
  )
}
export default Feature_ssection_component;