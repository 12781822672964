/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Categories from "../categaries_menu/CategoriesMenu";
import Brandcard from '../cards/Brandcard';
import { Link, useNavigate } from "react-router-dom";
import { url } from "../api/Url";
import axios from "axios";

const BrandsSection2 = ({data}) => {

  
  const [branddata, setBranddata] = useState([]);
  const navigate = useNavigate();

  const getBrd = async () => {

      // Fetch web control data from the second API
      const webControlResponse = await axios.get(url + '/app/v1/api/list_of_webcontroll');
      const webControlData = webControlResponse.data.message || [];
      const brandsIds = webControlData.flatMap(control => control.brands_id.split(','));
     // console.log('...brands....' + brandsIds)


   // const json = JSON.stringify({ seller_id: "85" });
    axios.post(url + '/app/v1/api/get_brand_data_product_web')
   // axios.post(url + '/app/v1/api/get_brand_data_product','85')
      .then((res) => {
        const datalist = res.data.message;
        const filteredUsers = datalist.filter(item => brandsIds.includes(item.brand_id.toString()));
      //  datalist.filter(data => data.brand_id == brandsIds.includes(data.id.toString()))

    /*   filteredUsers.map(data => {
          console.log('...found brand' + JSON.stringify(data));
        }) */
        setBranddata(filteredUsers);
      })
      .catch(() => {
        setBranddata([]);
      });
  };

  useEffect(() => {
    getBrd();
  }, []);

  const handleBrandClick = (brand_name) => {
    navigate(`/allproducts?brand=${encodeURIComponent(brand_name)}`);
  };

  return (
    <div className="mt-10">
      <div className="flex justify-between items-center">
      <div className="text-center mx-auto">
  <div className="text-2xl font-semibold">Exclusive Brands</div>
  <Link to="/AllBrands" className="text-black">
          View All
        </Link>
  {/* <div className="w-28 h-1 bg-black mx-auto mt-2 rounded"></div> */}
      </div>
      </div>
      <div className="flex flex-wrap justify-center md:justify-between mt-5">
        {branddata && branddata.map(user => (
          <div onClick={() => handleBrandClick(user.brand_name)} key={user.brand_id}>
            <Brandcard 
              brand_id={user.brand_id}
              brand_name={user.brand_name}
              brand_slug={user.brand_slug}
              brand_img={user.brand_img}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrandsSection2;