import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { X, MapPin, ChevronRight } from 'lucide-react';
import { url } from '../../components/api/Url';
import Spinner from '../../components/Spinner/Spinner';

const BookingModal = ({ show, onClose, onSelectPromo, bookingid, childid, bookingdate }) => {
    const [poromolist, setPromolist] = useState([]);
    const [selectedPromo, setSelectedPromo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [username, setUsername] = useState('');
    const [booking_type, setBooking_type] = useState('Try at home');
    const [pickuplocation, setPickuplocation] = useState('');
    const [deliverylocation, setDeliverylocation] = useState('');
    const [phone, setPhone] = useState('');

    useEffect(() => {
        if (show) {
            getPromolist();
        }
    }, [show]);

    const getPromolist = async () => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem("token");
            const userid = localStorage.getItem("userid");
            if (!token || !userid) {
                throw new Error("Token or User ID not found in localStorage");
            }
            const formData = new FormData();
            formData.append("limit", '20');
            const response = await axios.post(
                url + "/app/v1/api/get_promo_codes",
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = response.data.promo_codes;
            console.log(' poromo ' + JSON.stringify(data))
            /*    if (!Array.isArray(addressData) || addressData.length === 0) {
                 throw new Error("No promo found");
               } */
            setPromolist(data);
            //  setSelectedAddress(addressData.find(address => address.is_default === "1")?.id || null);
        } catch (error) {
            console.error("Error fetching address:", error);
            // toast.error("Error fetching addresses: " + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSelectPromocodes = (item) => {
        // setSelectedAddress(address.id);
        //  alert(id);
        onSelectPromo(item);
        onClose();
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const user_id = localStorage.getItem("userid");
        if (user_id == null) {
            setIsLoading(false);
            alert('User ID Not Found');
            return;
        }
        const formData = new FormData();
        formData.append("user_id", user_id);
        formData.append('booking_date', bookingdate);
        formData.append('child_id', childid);
        formData.append('booking_id', bookingid);
        formData.append('user_name', username);
        formData.append('booking_type', booking_type);
        formData.append('pickup_location', pickuplocation);
        formData.append('phone', phone);
        formData.append('delivery_location', deliverylocation);


        try {
            const response = await fetch(url + '/add_book', {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                setIsLoading(false)
                // toast('Network response was not ok')
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log('Success:', data);
            if (data.message == "User id Not Found") {
                setIsLoading(false);
            }
            else if (data.message == "Slots id Not Found") {
                setIsLoading(false);
            }
            else if (data.message == "Booking id Not Found") {
                setIsLoading(false);
            }
            else if (data.message == "Boooking Already Exits") {
                setIsLoading(false);
            }
            else if (data.message == "update") {
                setIsLoading(false);
                onClose();
            }
            else {

            }
            setIsLoading(false);
            /*   reloadfunction();
              setIsLoading(false) */
        }
        catch (error) {
            setIsLoading(false);
        }
    };
    if (!show) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50  flex items-center justify-center">
            <div className="relative bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
                <div className="flex justify-between items-center p-4 border-b">
                    <h2 className="text-lg font-semibold">Address Info</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <X size={20} />
                    </button>
                </div>
                {isLoading ? (
                    <div className="p-4"><Spinner /></div>
                ) : (
                    <div className="p-4">
                        <div
                            /*   key={item.id} */
                            className="flex items-center p-2 cursor-pointer hover:bg-gray-100">
                            <div className="flex-grow text-left">
                                <form className="space-y-6" onSubmit={handleSubmit} action="#" method="POST">
                                    <div>
                                        <div className="mt-2">
                                        <label for="email" className="block text-sm font-medium 
                                            leading-6 text-gray-900">
                                           User Name
                                        </label>
                                            <input id="username" name="username" type="text"
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                className="block w-full p-3 rounded-md border-0 
          py-1.5 text-gray-900 shadow-sm ring-1 ring-inset
           ring-gray-300 placeholder:text-gray-400 focus:ring-2 
           focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                        </div>
                                    </div>

                                    <div>
                                        <div className="flex items-center justify-between">
                                            <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Booking Type</label>

                                        </div>
                                        <div className="mt-2">
                                            <select name="booking_type" id="booking_type"
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 p-3 
            shadow-sm ring-1 ring-inset ring-gray-300
             placeholder:text-gray-400 focus:ring-2 focus:ring-inset
              focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                value={booking_type}
                                                onChange={(e) => setBooking_type(e.target.value)}
                                            >
                                                <option value="Try at home">Try at home</option>
                                                <option value="Instant Delivery">Instant Delivery</option>
                                                <option value="Tailor at home">Tailor at home</option>
                                                <option value="Premium Gifting">Premium Gifting</option>
                                                <option value="Made to Measure">Made to Measure</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="flex items-center justify-between">
                                            <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Pickup Location</label>

                                        </div>
                                        <div className="mt-2">
                                            <input id="pickup_location" name="pickup_location" type="text"
                                                value={pickuplocation}
                                                onChange={(e) => setPickuplocation(e.target.value)}
                                                class="block w-full rounded-md border-0 py-1.5 text-gray-900 p-3  
          shadow-sm ring-1 ring-inset ring-gray-300
           placeholder:text-gray-400 focus:ring-2 
           focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                        </div>
                                    </div>

                                    <div>
                                        <div className="flex items-center justify-between">
                                            <label for="delivery_location" class="block text-sm font-medium leading-6 text-gray-900">Delivery Location</label>
                                        </div>
                                        <div className="mt-2">
                                            <input id="delivery_location" name="delivery_location" type="text"
                                                value={deliverylocation}
                                                onChange={(e) => setDeliverylocation(e.target.value)}
                                                class="block w-full rounded-md border-0 py-1.5 text-gray-900 p-3 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                        </div>
                                    </div>

                                    <div>
                                        <div className="flex items-center justify-between">
                                            <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Phone</label>
                                        </div>
                                        <div className="mt-2">
                                            <input id="phone" name="phone" type="text"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                class="block w-full rounded-md border-0 py-1.5 p-3 
          text-gray-900 shadow-sm ring-1 
          ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset
           focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                        </div>
                                    </div>

                                    <div>
                                        <button type="submit" class="flex w-full justify-center rounded-md
         bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 
         text-white shadow-sm hover:bg-indigo-500 focus-visible:outline 
         focus-visible:outline-2 focus-visible:outline-offset-2 
         focus-visible:outline-indigo-600"
                                        >Submit</button>
                                    </div>
                                </form>

                            </div>

                        </div>
                    </div>
                )}
                <div className="p-4 border-t">
                    {/* <button
            className="w-full py-2 px-4 bg-blue-600 text-white rounded-lg flex items-center justify-center"
          
          >
            <MapPin size={20} className="mr-2" />
            Create a New Address
          </button> */}
                </div>
            </div>
        </div>
    );
};

export default BookingModal;