import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { url } from "../../components/api/Url";
import axios from "axios";
import { ArrowLeft } from "lucide-react";
import { Blogcard } from "../../components/cards/Blogcard";

const BlogSellerDetails = () => {
  const [blogDetails, setBlogDetails] = useState(null);
  const [allBlogs, setAllBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { ids } = useParams();
  const navigate = useNavigate();

  const fetchBlogs = async () => {
    const json = JSON.stringify({ id: ids });

    try {
      const res = await axios.post(url + "/app/v1/api/viewblog", json);
      console.log("API Response:", res.data);

      const blogs = res.data.message || [];
      setAllBlogs(blogs);
/* 
      const foundBlog = blogs.find((blog) => blog.id === ids);
      if (foundBlog) {
        setBlogDetails(foundBlog);
      } else {
        setError("Blog not found");
      } */

      setLoading(false);
    } catch (error) {
      console.error("Error fetching blog data:", error);
      setError("Failed to fetch blog details.");
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchBlogs();
  }, [ids]);

  const handleBlogClick = () => {
    navigate("/AllBlogs");
  };

  const handleBlogCardClick = (blogId) => {
    window.location.href = `/blog/${blogId}`;
  };

  if (loading) return <p>Loading blog details...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="flex" style={{ marginTop: 100 }}>
      {/* Scrollable blog list */}
      <div className="w-full h-screen scrollbar  p-4 border-r">
        {/* <h2 className="text-xl font-bold mb-4">All Blogs</h2> */}
        <div className="grid gap-4">
          {allBlogs.map((blog) => (
      <div 
      key={blog.id} 
      onClick={() => handleBlogCardClick(blog.id)}
      style={{ cursor: 'pointer' }}
    >
      <Blogcard
        name="blog"
        width="100%"
        height="100%"
        title={blog.Title}
        id={blog.id}
        Blogimage={`uploads/seller/${blog.Blogimage}`}
      />
      <div className="text-left mt-10">
        {blog.Content}
      </div>
    </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogSellerDetails;