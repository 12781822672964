import { ChevronRight } from "lucide-react";
import React from "react";
import { url } from "../../components/api/Url";

const CategorySlidercard = ({ 
  zulu_category_name, 
  seller_category_name, 
  zulu_category_image, 
  seller_category_image, 
  subcategories,  
  onImageLoad 
}) => {
  let path = "/uploads/seller/";
  
  return (
    <div className="relative md:w-[570px] w-[350px] mx-2 md:mx-0 cursor-pointer mb-4">
      {seller_category_image ? (
        <img 
          src={`${url}${path}${seller_category_image}`} 
          alt="" 
          className="w-full h-[500px] object-cover bg-gray-200 rounded-[30px]" 
          onLoad={onImageLoad} 
        />
      ) : (
        <img 
          src={`${url}/${zulu_category_image}`} 
          alt="" 
          className="w-full h-[500px] object-cover bg-gray-200 rounded-[30px]" 
          onLoad={onImageLoad} 
        />
      )}
      
      <div className="absolute bottom-0 w-full bg-gradient-to-t from-black via-black/60 to-transparent text-white text-center py-8 rounded-b-[30px] flex flex-col items-center">
        <p className="text-2xl font-bold mb-4 flex items-center">
          {seller_category_name ? seller_category_name : zulu_category_name} 
          <ChevronRight className="ml-1 font-bold" />
        </p>
        
        {/* Display subcategories instead of numbers */}
        <div className="flex flex-wrap justify-center gap-4 mb-4">
          {subcategories?.length > 0 ? (
            subcategories.map((subcategory, index) => (
              <button
                key={index}
                className="bg-transparent border border-white text-white px-4 py-2 rounded-full text-sm hover:bg-white hover:text-black transition"
              >
                {subcategory.children}
              </button>
            ))
          ) : (
            <p></p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CategorySlidercard;
