import React, { useEffect, useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import UserContextProvider from "../../context/UserContextProvider";
import SellerHeader from "./Header/SellerHeader";
import SellerSuperTopBar from "./Header/SellerSuperTopBar";
import SellerFooter from "./Footer/SellerFooter";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { url } from "../../components/api/Url";
import SellerNotice from "./SellerNotice";

function SellerLayout() {
  const sellerid = localStorage.getItem('seller_id');
  const sellerslug = localStorage.getItem('seller_slug');
  const [sellerListdata,setSellerListdata] = useState([]);
  const [sellerdata, setSellerdata] = useState([]);
  const [sellerlogo, setSellerlogo] = useState('');
  const [sellername, setSellername] = useState('');
  const [headertextcolorone, setHeadertextcolorone] = useState('');

  async function sellerListheader() {
    console.log('' + localStorage.getItem('seller_id'))
    if (localStorage.getItem('seller_id') == "") {
      return
    }
    try {
      let response = await axios.post(url + "/app/v1/api/seller_list?id=" + localStorage.getItem('seller_id'));
      let res = await response.data;
      const data = res[0]?.json_component
      const Jsonres = JSON.parse(data)
      setSellerListdata(res[0]);
      console.log('....logo....' + res[0]?.json_component)
      localStorage.setItem('sellerinfo',res[0]?.json_component)
      setSellerdata(res[0]?.json_component);
      setSellerlogo(res[0]['logo']);
      setSellername(res[0]['store_name']);
      setHeadertextcolorone(res[0]?.json_component.top_bar_menu.text_color1)
      console.log('textcolor1' + res[0].json_component.component.text_color1)
      //component?.top_bar_menu.text_color1
      //sellerinfo?.component?.top_bar_menu.BG_type
    /*   BG_Color1
      BG_Color2
      font_style */
   //   setData(Jsonres)
    //  setLoader(false);
    //  setError(false);
    } catch (error) {
     // setError(true);
      console.log("ERROR MESSAGE :: ", error.message);
    }
  }

  useEffect(() => {
    sellerListheader();
  },[])
  
  return (
    <>
      <UserContextProvider>
      {/* <SellerNotice /> */}
         <SellerSuperTopBar /> 
      
        <div className="text-center mx-auto  w-full "> {/* mt-36 container  */ }
    
        <ToastContainer />

            <SellerHeader sellername={sellername} sellerlogo={sellerlogo} sellerinfo={sellerdata} headertextcolorone=""/> 
           <div className="items-center justify-center min-h-screen   overflow-hidden">
           <Outlet /> 
           </div>
          <SellerFooter sellerlogo={sellerlogo} sellerinfo={sellerdata}/> 
        </div>
      </UserContextProvider>
    </>
  );
}

export default SellerLayout;