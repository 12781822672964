// src/components/ProductCardSkeleton.js
import React from 'react';

const ProductCardSkeleton = () => {
    return (
        <div className="rounded-lg overflow-hidden mb-2 md:w-[250px] w-[250px] mr-10 lg:w-[250px]">
        <div className="w-full h-64 bg-gray-300 animate-pulse rounded-2xl border"></div>
  
        <div className="p-4">
          <div className="flex justify-between items-center mb-2">
            <div className="w-3/4 bg-gray-300 animate-pulse h-4 rounded"></div>
            <div className="w-6 h-6 bg-gray-300 animate-pulse rounded-full"></div>
          </div>
  
          <div className="w-full bg-gray-300 animate-pulse h-4 mb-2 rounded"></div>
  
          <div className="flex items-center">
            <div className="w-20 bg-gray-300 animate-pulse h-4 rounded mr-2"></div>
            <div className="flex items-center">
              <div className="w-16 bg-gray-300 animate-pulse h-4 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default ProductCardSkeleton;
  


