import React, { useEffect, useState } from "react";
import Card8 from "../cards/Card8";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Card3 from "../cards/Card3";

const Features = ({ data, selectedHighlightId }) => {
  const [featureData, setFeatureData] = useState([]);
  const navigate = useNavigate();

  const url2 =
    "https://zulushop.in/app/v1/api/get_seller_features_sections_final";

  async function getFeatures() {
    const jsonData = JSON.stringify({ seller_id: "85" });
    try {
      let response = await axios.post(url2, jsonData);
      let res = await response.data;
      setFeatureData(res);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  }

  useEffect(() => {
    getFeatures();
  }, []);

  // Use the first feature as the default if no `selectedHighlightId` is provided
  const activeFeature = selectedHighlightId
    ? featureData.find(item => item.id === selectedHighlightId)
    : featureData[0];

  const handleViewAll = (id) => {
    navigate(`/highlight/${id}`);
  };

  // Ensure `activeFeature` exists before rendering
  return (
    <div className="mt-10">
      <div className="mt-5 mx-auto">
        {activeFeature && (
          <div key={activeFeature.id}>
            <div className="flex justify-between mt-10 mb-5 items-center">
              <div className="flex flex-col gap-2">
                <h1
                  className="text-left font-bold text-[22px]"
                  style={{ color: `${data?.component?.image_gallery?.color}` }}
                >
                  {activeFeature.title}
                </h1>
                <p className="text-left">{activeFeature.short_description}</p>
              </div>
              <button
                onClick={() => handleViewAll(activeFeature.id)}
                className="text-blue-700"
              >
                {/* view all */}
              </button>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 w-[90%] mx-auto gap-5">
              {activeFeature.product_details?.productinfo?.map((product) => (
                <div key={product.id}>
                  <Card8
                    image={product.image}
                    title={product.name}
                    price={product.variants[0].price}
                    discount={product.variants[0].special_price}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Features;
