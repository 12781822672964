import React from 'react';

const ModalYesNo = ({ message, onClose, onConfirm, dataid }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
      <div className="bg-white rounded-lg shadow-lg p-5 w-1/3 text-left">
        <h2 className="text-lg font-bold">Confirmation</h2>
        <p className="mt-2">{message} {dataid}</p>
        <div className="mt-4 flex justify-end">
          <button
            className="bg-green-500 text-white px-4 py-2 rounded mr-2"
            onClick={onConfirm}
          >
            Yes
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded"
            onClick={onClose}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalYesNo;
