import React, { useEffect, useState } from "react";
import Card3 from "../cards/Card3";
import axios from "axios";
import { url } from "../api/Url";
import { useNavigate } from "react-router-dom";

const Highlights2 = () => {
  const [featureData, setFeatureData] = useState([]);
  const navigate = useNavigate();

  async function getFeatures() {
    try {
      console.log("Fetching features data...");
      let response = await axios.post(url + "/app/v1/api/get_admin_features_sections");
      console.log("API response:", response);
      
      let res = response.data;
      console.log("Response data:", res);
  
      // Process the data to include product IDs for each feature
      const processedData = res.map(section => {
        const productIds = section.product_details?.productinfo?.map(product => product.id) || [];
        return {
          ...section,
          productIds
        };
      });
  
      // Set the first four processed sections to the state
      setFeatureData(processedData.slice(0, 4));
  
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    getFeatures();
  }, []);

  const handleHighlightClick = (feature) => {
    // Navigate to the HighlightDetail page with feature ID and product IDs
    navigate(`/highlights/${feature.id}`, { 
      state: { productIds: feature.productIds }
    });
  };

  return (
    <div className="mt-10">
      <div className="mt-5">
        <div className="flex justify-between">
          <div className="text-center mx-auto">
            <div className="text-2xl font-semibold">Curated Collections</div>
            <div className="w-28 h-1 bg-white mx-auto mt-2 rounded"></div>
          </div>
          <a href="/AllHighlights" className="text-white">
            view all
          </a>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 mt-10 mb-5 justify-center items-center">
          {featureData.map((item) => (
            <div
              key={item.id}
              className="banner cursor-pointer"
              onClick={() => handleHighlightClick(item)}
            >
              <Card3
                title={item.title}
                short_description={item.short_description}
                image={`${item.banner}`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Highlights2;