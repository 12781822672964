import React, { useEffect, useState } from "react";
import { Blogcard } from "../cards/Blogcard";
import { Link } from "react-router-dom";
import { url } from "../api/Url";
import axios from "axios";
import Testimonialcard from "../cards/Testimonialcard";

const Testimonials2 = ({data}) => {
  const [test, setTest] = useState([]);

  const getTest = async () => {
    const json = JSON.stringify({ seller_id: "" });
   // axios.post(url + '/app/v1/api/listoftestimonal', json)
    axios.post(url + '/app/v1/api/listoftestimonal',json)
      .then((res) => {
        setTest(res.data.slice(0,4));
      })
      .catch(() => {
        setTest([]);
      });
  };

  useEffect(() => {
    getTest();
  }, []);

  return (
    <div className="mt-10">
      <div className="flex justify-between items-center">
      <div className="text-center mx-auto">
  <div className="text-2xl font-semibold">Testimonials </div>
  <Link to="/AllTestimonials" className="text-black">
          View All
        </Link>
  {/* <div className="w-28 h-1 bg-black mx-auto mt-2 rounded"></div> */}
      </div>
      </div>

      {/* Scrollable on small screens, grid on larger screens */}
      <div className="flex md:grid md:grid-cols-4 gap-4 mt-5 overflow-x-auto flex-nowrap">
        {test && test.map(user => (
          <div key={user.id} className="flex-shrink-0 w-[80%] sm:w-[40%] md:w-auto">
            <Testimonialcard 
              id={user.id}
              comments={user.comments}
              username={user.username}
              user_image={user.user_image}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials2;
