import React, { useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";

const Popup = ({ isVisible, onClose }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [result, setResult] = useState("");

  // Handle form submission
  const onSubmit = async (event) => {
    event.preventDefault();
    setIsModalOpen(true); // Show loading state

    const formData = new FormData();
    formData.append("name", event.target.name.value);
    formData.append("phone", event.target.mobile.value);
    formData.append("email", event.target.email.value);
    formData.append("user_type", event.target.UserType.value);
    formData.append("lead_type", "Waitlist"); // You can set this dynamically as needed
    formData.append("comments", "Interested in joining"); // Example comment or input if you want to collect it
    formData.append("lead_source", "Website Popup"); // Set the lead source dynamically

    try {
      const response = await fetch("https://zulushop.in/storeuserinfo", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();

      if (response.ok && data.success) {
        setResult("Joined the waitlist successfully!");
        event.target.reset();
      } else {
        console.error("Error:", data);
        setResult(data.message || "An error occurred.");
      }
    } catch (error) {
      console.error("Submission failed:", error);
      setResult("An error occurred while joining the waitlist.");
    } finally {
      setIsModalOpen(false); // Hide loading state
    }
  };

  return (
    <div
      id="Popup"
      className={`fixed inset-0 w-full h-full bg-[#1f0a1dc5] text-left ${
        isVisible ? "flex" : "hidden"
      } justify-center items-center`}
    >
      <div className="relative bg-[#1f0a1d] text-white border-2 border-[#dbca90] rounded-2xl shadow-[0_0_20px_#dbca90c1] py-8 px-10 md:w-[500px]">
        <IoIosCloseCircleOutline
          onClick={onClose}
          className="text-3xl absolute right-5 top-4 cursor-pointer text-[#dbca90]"
        />
        <form onSubmit={onSubmit}>
          <h1 className="pt-3 text-xl font-bold">Register</h1>

          <label htmlFor="name" className="block mt-10 font-semibold">
            Name
          </label>
          <input
            className="w-full mt-2 px-2 py-3 border-0 rounded outline-none text-black"
            type="text"
            placeholder="Enter Name"
            name="name"
            required
          />

          <label className="block mt-3 font-semibold" htmlFor="mobile">
            Phone No
          </label>
          <input
            className="w-full mt-2 px-2 py-3 border-0 rounded outline-none text-black"
            type="tel"
            placeholder="Enter Phone No"
            name="mobile"
            required
          />

          <label className="block mt-3 font-semibold" htmlFor="email">
            Email
          </label>
          <input
            className="w-full mt-2 px-2 py-3 border-0 rounded outline-none text-black"
            type="email"
            placeholder="Enter Email"
            name="email"
            required
          />

          <label htmlFor="UserType" className="block mt-3 font-semibold">
            User Type
          </label>
          <input
            className="w-full mt-2 px-2 py-3 border-0 rounded outline-none text-black"
            type="text"
            placeholder="User Type"
            name="UserType"
            required
          />

          <button
            type="submit"
            className="w-full mt-6 py-2 bg-green-600 text-white font-bold rounded hover:bg-green-700 transition-colors flex items-center justify-center"
            disabled={isModalOpen}
          >
            {isModalOpen ? (
              <span className="loader border-2 border-t-2 border-white rounded-full w-5 h-5 animate-spin"></span>
            ) : (
              "Submit"
            )}
          </button>
        </form>
        {result && <p className="mt-4 text-center">{result}</p>}
      </div>
    </div>
  );
};

export default Popup;
