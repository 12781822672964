import React, { useMemo, useCallback, useEffect } from 'react';
import { Trash2, Plus, Minus } from 'lucide-react';
import { useSellerCart } from '../../categaries_menu/SellerCartContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';

const CartItem = React.memo(({ item, onAdd, onRemove, onDelete }) => {
  const calculateDiscount = useCallback((regularPrice, specialPrice) => {
    if (regularPrice > specialPrice) {
      return Math.round(((regularPrice - specialPrice) / regularPrice) * 100);
    }
    return 0;
  }, []);

  return (
    <div className="flex flex-row lg:flex-row bg-white p-6 mb-6 shadow-md rounded-lg">
      <img
        src={item.image}
        alt={item.name}
        className="w-24 mr-4 lg:w-24 h-24 object-cover rounded-lg lg:mr-4 mb-4 lg:mb-0"
      />
      <div className="flex-grow text-left">
        <h3 className="text-lg font-semibold">{item.name}</h3>
        <p className="text-gray-600">Size: {item.size}</p>
        <p className="text-gray-600">Color: {item.color}</p>
        <div className="flex items-center mt-4 space-x-4">
          <button
            onClick={() => onDelete(item.id, item.size)}
            className="text-red-500 hover:text-red-700 flex text-xs items-center"
          >
            <Trash2 className="mr-1 w-4 h-4" /> Remove
          </button>
        </div>
      </div>
      <div className="flex items-center justify-between lg:flex-col mt-4 lg:mt-0">
        <div className="flex items-center">
          <button
            onClick={() => onRemove(item.id, item.size)}
            className="p-1 rounded-full hover:bg-gray-200"
          >
            <Minus size={20} />
          </button>
          <span className="mx-4 text-center">{item.quantity}</span>
          <button
            onClick={() => onAdd(item, item.size)}
            className="p-1 rounded-full hover:bg-gray-200"
          >
            <Plus size={20} />
          </button>
        </div>
        <div className="text-sm text-right mt-4 lg:mt-0">
          {item.min_max_price.regular_price > item.min_max_price.special_price ? (
            <div className="flex flex-col items-end">
              <span className="text-gray-500 line-through mr-2">
                ₹{item.min_max_price.regular_price}
              </span>
              <span className="font-semibold text-gray-800">
                ₹{item.min_max_price.special_price}
              </span>
              <span className="bg-green-500 text-white px-2 py-1 text-xs rounded mt-1">
                {calculateDiscount(
                  item.min_max_price.regular_price,
                  item.min_max_price.special_price
                )}% OFF
              </span>
            </div>
          ) : (
            <span className="font-semibold text-gray-800">
              ₹{item.min_max_price.special_price}
            </span>
          )}
        </div>
      </div>
    </div>
  );
});

const SellerShoppingCart = () => {
  const { sellerCartItems, addToCart, removeFromCart, deleteFromCart } = useSellerCart();
  const navigate = useNavigate();
  const sellerslug = localStorage.getItem('seller_slug');

  const calculateTotal = useMemo(() => {
    return sellerCartItems.reduce(
      (total, item) => total + item.min_max_price.special_price * item.quantity,
      0
    );
  }, [sellerCartItems]);

  const loginCheck = () => {
    // alert('test');
     const userid = localStorage.getItem("userid");
     if(userid)
     {
      navigate(`/o/${sellerslug}/checkout`);
     }
     else
     {
      // alert('login');
       navigate(`/o/${sellerslug}/login`);
     }
   }
   useEffect(() => {
    window.scrollTo(0, 0);
   },[])

  return (
    <div className="container mx-auto px-4 py-8 flex flex-col lg:flex-row gap-8">
      {/* Left Section - Cart Items */}
      <div className="flex-grow">
        <h1 className="text-3xl font-bold mb-8">Your Shopping Cart</h1>
        {sellerCartItems.length === 0 ? (
          <p className="text-xl text-gray-600">Your cart is empty.</p>
        ) : (
          sellerCartItems.map((item) => (
            <CartItem
              key={`${item.id}-${item.size}`}
              item={item}
              onAdd={addToCart}
              onRemove={removeFromCart}
              onDelete={deleteFromCart}
            />
          ))
        )}
      </div>

      {/* Right Section - Summary */}
      <div className="lg:w-1/3">
        <div className="bg-white p-6 shadow-md rounded-lg">
          <h2 className="text-xl font-bold mb-6">The total amount of</h2>
          <div className="flex justify-between mb-4">
            <span>Temporary amount</span>
            <span>₹{calculateTotal.toFixed(2)}</span>
          </div>
          <div className="flex justify-between font-bold text-lg mb-6">
            <span>The total amount (including VAT)</span>
            <span>₹{calculateTotal.toFixed(2)}</span>
          </div>
            <button onClick={loginCheck} className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded">
              Proceed to Checkout
            </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SellerShoppingCart);
