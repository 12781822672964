import React from 'react'

export default function SellerNotice() {
  return (
<>
{/* <div class="relative flex overflow-x-hidden bg-red-100 mt-5">
    <div class="py-3 animate-marquee whitespace-nowrap">
      <span class="text-sm mx-4">Try at home is now available in Gurgaon</span>
      <span class="text-sm mx-4">Try at home is now available in Gurgaon</span>
      <span class="text-sm mx-4">Try at home is now available in Gurgaon</span>
      <span class="text-sm mx-4">Try at home is now available in Gurgaon</span>
      <span class="text-sm mx-4">Try at home is now available in Gurgaon</span>
    </div>
  
  </div> */}
</>
  
  )
}
