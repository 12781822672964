import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { json, useParams } from "react-router-dom";
import Card2 from "../../components/cards/Card2";
import Header from "../../components/header/Header";
import { url } from "../../components/api/Url";
import UserContext from "../../context/UserContext";
import Footer from "../../components/footer/Footer";
import Feature_ssection_component from "../../components/template_one/Feature_ssection_component";


const Features_section = () => {

  const [data, setData] = useState([])
  const [categoriesdata, setCategoriesdata] = useState([]);
  const [sellerid, setSellerid] = useState('');
  const [isloading, setIsloading] = useState(false);
  console.log("data", data)

  return (
    <>
    <div className="mx-[100px]">
      <div className="mt-10 my-30" style={{ marginTop: 100 }}>
        <div className="mt-5">
          <div className="flex justify-between">
            <Feature_ssection_component />
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default Features_section;
