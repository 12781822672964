import React from 'react';

export default function OrderCard({ name, orderNumber, date, amount, onClick }) {
  return (
    <div className="p-2 text-sm text-gray-800 border rounded-lg" onClick={onClick}>
      <h3 className="font-bold text-lg">{name}</h3>
   
    </div>

  );
}
