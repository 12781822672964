import React, { useState, useEffect } from "react";
import { url } from "../api/Url";
import axios from "axios";


const Blogcomponent = () => {
  const sellerid = localStorage.getItem('seller_id');
  const sellerslug = localStorage.getItem('seller_slug');
  const [blogdata, setBlogdata] = useState([]);

  const getBlg = async () => {
    const json = JSON.stringify({ seller_id: sellerid });
    axios.post(url + '/app/v1/api/listofblog', json)
      .then((res) => {
        //  console.log('data blog' + JSON.stringify(res.data.message));
        setBlogdata(res.data.message.slice(0, 4))
        // setBranddata([])
      })
      .catch(() => {
        setBlogdata([])
      });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getBlg();
  }, [])


  return (
    <>
      <section className="bg-white pb-10 pt-20 dark:bg-dark lg:pb-20 lg:pt-[120px]">
        <div className="container">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full px-4">
              <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
                <span className="mb-2 block text-lg font-semibold text-primary">
                  Our Blogs
                </span>
                <h2 className="mb-4 text-3xl font-bold text-dark dark:text-white sm:text-4xl md:text-[40px]">
                  Our Recent News
                </h2>
                <p className="text-base text-body-color dark:text-dark-6">
                  There are many variations of passages of Lorem Ipsum available
                  but the majority have suffered alteration in some form.
                </p>
              </div>
            </div>
          </div>

          <div className="-mx-4 flex flex-wrap">
          {blogdata && blogdata?.map(user => (
            <BlogCard
              date={user.created_by}
              CardTitle={user.Title}
              CardDescription={user.Title}
              image={`${url}/uploads/seller/${user.Blogimage}`}
            />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogcomponent;

const BlogCard = ({ image, date, CardTitle, CardDescription }) => {
  return (
    <>
      <div className="w-full px-4 md:w-1/2 lg:w-1/3">
        <div className="mb-10 w-full">
          <div className="mb-8 overflow-hidden rounded">
            <img src={image} alt="" className="w-100" />
          </div>
          <div>
            {date && (
              <span className="mb-5 inline-block rounded bg-primary px-4 py-1 text-center text-xs font-semibold leading-loose text-white">
                {date}
              </span>
            )}
            <h3>
              <a
                href="/#"
                className="mb-4 inline-block text-xl font-semibold text-dark hover:text-primary dark:text-white sm:text-2xl lg:text-xl xl:text-2xl"
              >
                {CardTitle}
              </a>
            </h3>
            <p className="text-base text-body-color dark:text-dark-6">
              {CardDescription}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
