/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { url } from "../../components/api/Url";
import Banner3 from "../../components/cards/Banner3";
import UserContext from "../../context/UserContext";
import CategoriesMenu2 from "../../components/categaries_menu/CategoriesMenu2";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Card6 from "../../components/cards/Card6";
import BrandsSection2 from "../../components/brands/BrandsSection2";
import Highlights2 from "../../components/highlights/Highlights2";
import Features from "../../components/features/Features";
import Blogs2 from "../../components/blogs/Blogs2";
import Testimonials2 from "../../components/testimonials/Testimonials2";
import Model3D2 from "../../components/model3D/Model3D2";
import Video from "../../components/model3D/Video";
import { Link } from "react-router-dom";
import LinkInput from "../zulu_club/LinkInput";
import NavigationBar from "./NavigationBar"; // Import the NavigationBar component
import CategoriesMenu3 from "../../components/categaries_menu/CategoriesMenu3";
import { CategoryProvider } from "../../components/categaries_menu/CategoryContext";
import Marquee from "../../components/cards/Marquee";
import OurApp from "../../components/logo_App/OurApp";
import ZFINALSQUARE from "../../../src/assets/images/ZFINALSQUARELatest.png";
import outletImage from "../../../src/assets/images/outlet.jpeg";
import starImage from "../../../src/assets/images/star.png";
import premiumImage from "../../../src/assets/images/premium.png";
import luxuryImage from "../../../src/assets/images/luxury.png";
import shoppingBagImage from "../../../src/assets/images/shopping bag.png";

const SellerHome = () => {
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sliderImages, setSliderImages] = useState([]);
  const [sliderImagesdata, setSliderImagesData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const { setError, setLoader, loader, error } = useContext(UserContext);
  const navigate = useNavigate(); // For programmatic navigation
  const [selectedHighlightId, setSelectedHighlightId] = useState(null);
  const [premiumOutlets, setPremiumOutlets] = useState([]); // Removed duplicate declaration

  const sellersUrl = "https://zulushop.in/app/v1/api/get_sellers";
  const categoriesUrl = "https://zulushop.in/zulu_category_list";
  const sliderUrl = "https://zulushop.in/app/v1/api/get_slider_backup2";
  const baseUrl = "https://zulushop.in/";
  const webControllUrl = "https://zulushop.in/app/v1/api/list_of_webcontroll";

  const handleHighlightClick = (id) => {
    setSelectedHighlightId(id);
  };

  const handleClick = async (slug) => {
    localStorage.clear();
    console.log('Link was clicked!' + slug);
    axios
      .post(url + "/slug_id/" + slug)
      .then((res) => {
        console.log('seller info....' + res);
        // setSellerid(res.data.info.user_id);
        localStorage.setItem('seller_id', res.data.info.user_id);
        localStorage.setItem('seller_slug', res.data.info.slug);
        localStorage.setItem('seller_info', res.data.info);
        navigate(`${slug}`); // Redirects to the /about route

      })
      .catch(() => {
        // setCategoriesdata([]);
      });
  };

  async function fetchData() {
    try {
      const response = await axios.post(sellersUrl);
      setData(response.data.data || []);

      // Mapping the data to match the premiumOutletsSlides structure
      const slides = response.data.data.map((item) => ({
        image: item.image,
        title: item.name,
        description: item.category,
      }));
      // setPremiumOutlets(slides);
    } catch (error) {
      console.error("Error fetching sellers:", error);
      setError(true);
    }
  }

  async function fetchCategories() {
    try {
      const response = await axios.post(categoriesUrl);
      let res = response.data;

      if (typeof res === "string") {
        try {
          res = JSON.parse(res);
        } catch (parseError) {
          console.error("Error parsing JSON:", parseError);
          setCategories([]);
          return;
        }
      }

      if (Array.isArray(res.data)) {
        const categoryImages = res.data
          .map((item) => ({
            image: item.image,
            name: item.name,
          }))
          .slice(0, 4);

        setCategories(categoryImages);
      } else {
        console.warn("Unexpected data structure:", res);
        setCategories([]);
      }
    } catch (error) {
      setError(true);
      console.error("Error fetching categories:", error);
    }
  }

  async function fetchSliderData() {
    try {
      const response = await axios.post(sliderUrl);
      const res = response.data;

      if (Array.isArray(res)) {
        const bannerImages = res.map((item) => `${baseUrl}${item.image}`);
        setSliderImages(bannerImages);
        setSliderImagesData(res);
      } else {
        setSliderImages([]);
      }
    } catch (error) {
      setError(true);
      console.error("Error fetching slider images:", error);
    }
  }

  // async function fetch3DModels() {
  //   try {
  //     const response = await axios.post(`${url}/get_all_videos`, {
  //       video_type: "3dModel",
  //     });

  //     console.log("3D Models Response:", response.data.message); // Log the response

  //     setModelData(response.data.message || []);
  //   } catch (error) {
  //     console.error("Error fetching 3D models:", error);
  //     setError(true);
  //   }
  // }

  useEffect(() => {
    const fetchAllData = async () => {
      setLoader(true);
      await Promise.all([
        fetchData(),
        fetchCategories(),
        fetchSliderData(),
        // fetch3DModels(),
      ]);
      setLoader(false);
    };
    fetchAllData();
  }, [setLoader, setError]);

  async function fetchSellers() {
    try {
      setLoader(true);

      // Fetch web control data from the second API
      const webControlResponse = await axios.get('https://zulushop.in/app/v1/api/list_of_webcontroll');
      const webControlData = webControlResponse.data.message || [];

      // Extract retail store IDs from the web control data
      const retailStoreIds = webControlData.flatMap(control => control.retail_stores_id.split(','));
      const brandsIds = webControlData.flatMap(control => control.brands_id.split(','));

      // Fetch sellers data
      const sellersResponse = await axios.post(sellersUrl);
      const sellers = sellersResponse.data.data || [];

      // Filter and map sellers that match retail store IDs
      const premiumOutletSlides = sellers
        .filter(seller => seller.outlet === "outlet" && retailStoreIds.includes(seller.seller_id.toString()))
        .map(seller => {
          let sliderImages = [""];

          // Check if seller_slider exists and contains slider images
          if (seller.seller_slider?.length > 0 && seller.seller_slider[0].slider_images) {
            try {
              // Try parsing slider_images
              sliderImages = JSON.parse(seller.seller_slider[0].slider_images).map(image => image.file_name);
            } catch (error) {
              console.error("Error parsing slider images for seller", seller.seller_id, error);
            }
          }

          return {
            id: seller.seller_id,
            image: seller.seller_profile,
            title: seller.store_name || seller.seller_name,
            description: seller.store_description || "No description available",
            productCount: seller.total_products,
            rating: seller.seller_rating,
            sections: seller.info?.length > 0 ? seller.info[0].sections : "Kids, Men, Women",
            slider: sliderImages,
            slug: seller.slug
          };
        });

      //  console.log("Premium Outlet Slides:", premiumOutletSlides);

      setPremiumOutlets(premiumOutletSlides);

    } catch (error) {
      //  console.error("Error fetching sellers:", error);
      setError(true);
    } finally {
      setLoader(false);
    }
  }


  useEffect(() => {
    fetchSellers();
  }, []);


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const sections = [
    { id: "categories", label: "Categories" },
    { id: "outlets", label: "Outlets Near Me" },
    { id: "brands", label: "Brands" },
    { id: "highlights", label: "Highlights" },
    { id: "features", label: "Features" },
    { id: "blogs", label: "Blogs" },
    { id: "testimonials", label: "Testimonials" },
    { id: "model3d", label: "3D Models" },
  ];

  const sliderSettings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };


  return (
    <>
      <CategoryProvider>
        <section id="categories" className="mt-[-40px]">
          <CategoriesMenu3 data={data} />
        </section>

        <section id="categories" className="mt-10">
          <CategoriesMenu2 data={data} />
        </section>
      </CategoryProvider>

      {sliderImages.length > 0 ? (
        <>
          <div
            className="new-experiences-header"
            style={{
              backgroundImage: `url(${ZFINALSQUARE})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              padding: "20px",
              color: "#fff",
              // width: "100",
              borderRadius: "0px",
            }}
          >
            <Banner3 images={sliderImages} data={sliderImagesdata} />

            {/* 3D Models Section */}
            <section id="model3d" className="mt-10">
              {/* <Model3D2 data={data} modelData={modelData} /> */}
            </section>
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center">
          {/* Loader Animation for Empty Banners */}
          <div className="w-full h-[300px] bg-gray-300 animate-pulse mb-5 rounded-lg"></div>
          <div className="w-full h-[300px] bg-gray-300 animate-pulse mb-5 rounded-lg"></div>

          {/* Animated No Images Message */}
          <p className="text-gray-500 text-lg animate-bounce">No images to display</p>
        </div>
      )}

      <section id="outlets" className="mt-20  mb-10">
        <div className="flex flex-row items-center mx-[20px] justify-between mb-4">
          <div className="text-center mx-auto">
            <div className="text-2xl font-semibold">Premium Outlets</div>
            <div>
              <Link
                to="/AllOutlets"
                state={{ data: [], loader: false, error: false }}
                className="text-black underline"
              >
                Explore
              </Link>
            </div>
          </div>
          {/* <div className="w-28 h-1 bg-black mx-auto mt-1 rounded"></div> */}

        </div>


        <div className="relative w-full  h-[330px] overflow-hidden rounded-t-xl rounded-xl">

          <Slider {...settings}>
            {premiumOutlets.map((outlet) => (
              <div key={outlet.id} className="p-4 rounded-t-xl">
                <div className="relative rounded-xl bg-transparent h-80 ">
                  {/* Slider for the outlet images */}
                  <Link
                        onClick={() => handleClick(`${outlet.slug}`)} /* to={`${data.slug}`}  */
                        className="text-black-500 hover:underline"
                      >
                  <Slider {...sliderSettings} className="mt-[-30px] ">
                    {outlet.slider.map((sliderImage, index) => (
                      <div key={index} className=" h-[340px]">
                        <img
                          src={/^https?:\/\//.test(sliderImage) && !sliderImage.includes('uploads/seller/')
                            ? sliderImage
                            : `https://zulushop.in/uploads/seller/${sliderImage.replace(/^uploads\/seller\//, '')}`} 
                          alt={`Slide ${index + 1}`}
                          className="w-full object-cover h-[160px] rounded-t-xl mt-4"
                        />


                      </div>
                    ))}
                  </Slider>
                  </Link>
                  <div className="absolute inset-x-0 bottom-0 p-4 bg-white rounded-xl shadow-lg">
                    <div className="mb-4">
                      <div className="flex items-center mb-2">
                        <img
                          src={shoppingBagImage}
                          alt="Shopping Bag"
                          className="w-4 h-4"
                        />
                        <h2 className="text-md ml-2 mr-2">{outlet.productCount}+ Products</h2>
                        <img src={starImage} alt="Star" className="w-6 h-6" />
                        <span className="ml-1">{parseFloat(outlet.rating).toFixed(1)}</span>
                      </div>
                      <h2 className="text-xl font-semibold mb-2 text-left">
                        {outlet.title}
                      </h2>

                      <p className="text-sm text-left">{outlet.description}</p>
                   
                   
                     
                      {/* Display sections if they exist */}
                      {Array.isArray(outlet.sections) ? (
                        <div className="text-sm text-left">
                          {outlet.sections.map((section, index) => (
                            <span key={index} className="mr-2">
                              {section}
                            </span>
                          ))}
                        </div>
                      ) : (
                        <p className="text-sm text-left mb-[-15px]">{outlet.sections || "Men, Women, Kids"}</p>
                      )}
                    </div>

                    <div className="absolute top-4 right-4 flex flex-col items-end gap-2">
                      <div className="flex items-center">
                        <span className="mr-2 text-lg">Premium:</span>
                        <img src={premiumImage} alt="Premium" className="w-6 h-5" />
                      </div>
                      <div className="flex items-center">
                        <span className="mr-2 text-lg">Luxury:</span>
                        <img src={luxuryImage} alt="Luxury" className="w-12 h-10" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>

        </div>
      </section>

      <section id="outlets" className="mt-20 mb-10">
        <div className="flex flex-row items-center mx-[20px] justify-between">
          <div className="text-center mx-auto mb-4">
            <div className="text-2xl font-semibold ">All Outlets</div>
            <div>
              <Link
                to="/AllOutlets"
                state={{ data: [], loader: false, error: false }}
                className="text-black underline "
              >
                Explore
              </Link>
            </div>
          </div>
          {/* <div className="w-28 h-1 bg-black mx-auto mt-1 rounded"></div> */}

        </div>

        <Slider {...settings2}>
          {data.map((value) => (
            <div className="px-2" key={value.seller_id}>
              <Card6 data={value} />
            </div>
          ))}
        </Slider>
      </section>

      {/* Highlights Section */}
      <div
        className="new-experiences-header w-full"
        style={{
          backgroundImage: `url(${ZFINALSQUARE})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          padding: "20px",
          color: "#fff",
          borderRadius: "0px",
        }}
      >
        {/*   curated collection */}
        <section id="highlights" className="mt-10">
          <Highlights2 data={data} onHighlightClick={handleHighlightClick} />
        </section>

        {/* Features Section */}
        {/*    <section id="features" className="mt-10">
          <Features data={data} selectedHighlightId={selectedHighlightId} />
        </section> */}
      </div>

      {/* 3D Models Section */}
      <section id="model3d" className="mt-10 mx-[20px]">
        {/* <Model3D2 data={data} modelData={modelData} /> */}
        <Video data={data} modelData={modelData} />
      </section>

      {/* Brands Section */}
      <section id="brands" className="mt-10 mx-[20px]" >
        <BrandsSection2 data={data} />
      </section>



      {/* Blogs Section */}
      <section id="blogs" className="mt-10 mx-[20px]">
        <Blogs2 data={data} />
      </section>

      {/* Testimonials Section */}
      <section id="testimonials" className="mt-10 mx-[20px]">
        <Testimonials2 data={data} />
      </section>

      <OurApp />
    </>
  );
};

export default SellerHome;
