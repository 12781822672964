/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import "./HeaderMain.css";
import logo from "../../../assets/images/zulu-logo-3.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { IoMdMenu, IoMdClose } from "react-icons/io";
import { FaHome, FaShoppingBag, FaBlog, FaInfoCircle, FaUser, FaFireAlt, FaRegUser, FaShoppingCart, FaStoreAlt, FaSignOutAlt, FaBriefcase, FaTimes, FaHeart } from "react-icons/fa";
import { FaHome as FaHomeOutline, FaShoppingBag as FaShoppingBagOutline, FaBlog as FaBlogOutline, FaInfoCircle as FaInfoCircleOutline, FaUser as FaUserOutline } from "react-icons/fa";
import Popup from "../Popup";
import { FaCircleInfo, FaCompass, FaRegCompass } from "react-icons/fa6";
import { useDispatch } from 'react-redux';
import loginUser from "../../template_one/redux/slice/authSlice";
import { url } from "../../api/Url";
import LocationDropdown from "./LocationDropdown"; // Adjust the import path as needed
import ShoppingCart from "./ShoppingCart";
import { useCart } from "../../categaries_menu/CartContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';
import Spinner from "../../Spinner/Spinner";
import ShoppingCartWindow from "./ShoppingCartWindow";
import FavoritesSidebar from "../../profile/FavoritesSidebar";


const HeaderNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [mobile, setMobile] = useState('');
  const [otp, setOtp] = useState('');
  const [isloading, setIsloading] = useState('');
  const [errorMessage, setErrorMessage] = useState("")
  const [islogin, setIslogin] = useState(true)
  const [isotpscreen, setIsotpscreen] = useState(false)
  const [timer, setTimer] = useState(120); // 60 seconds timer
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const { cartItems } = useCart();
  const cartItemCount = cartItems.reduce((total, item) => total + item.quantity, 0);


  const menuRef = useRef(null);
  const [isCartSidebarOpen, setIsCartSidebarOpen] = useState(false);
  const [ isFavoritesSidebarOpen,  setIsFavoritesSidebarOpen] = useState(false);
  const [isFavoritesOpen, setIsFavoritesOpen] = useState(false);

  const toggleCartSidebar = () => {
    setIsCartSidebarOpen((prev) => !prev);
  };

  const toggleFavoritesSidebar = () => {
    setIsFavoritesSidebarOpen((prev) => !prev);
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => {

    setModalOpen(true);
  }

  const closeModal = () => setModalOpen(false);


  const toggleMenu = () => {
    menuRef.current.classList?.toggle('show__menu');
    setIsMenuOpen(prevState => !prevState);
  };

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    if (prevScrollPos > currentScrollPos) {
      setVisible(true);
    } else {
      setVisible(false);
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // Check if user data exists in localStorage
    const userData = localStorage.getItem('mobileno');
    // alert(userData);
    if (userData) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }


    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const handleOpenPopup = () => {
    setIsPopupVisible(true);
  };

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };
  const manual_login_for_development = async () => {
    setIsLoggedIn(false)
    setIsloading(true)
    //return
    const datafrom = new FormData();
    datafrom.append('mobile', '01885553021'); //localStorage.getItem('tem_mobileno') localStorage.getItem('tem_mobileno')
    datafrom.append('password', '12345678');
    datafrom.append('type', 'phone');
    console.log(datafrom);
    try {
      const response = await fetch(url + '/app/v1/api/sign_up_with_mobile', {
        method: 'POST',
        body: datafrom
      });

      if (!response.ok) {
        toast('Network response was not ok')
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('data list ' + data)
      if (data.message == "mobile no already exits") {
        let st = '01885553021';//localStorage.getItem('tem_mobileno');
        localStorage.setItem('mobileno', st);
        localStorage.setItem('token', data.token);
        localStorage.setItem('userid', data.data[0].id);
        console.log('token here.....' + JSON.stringify(data.data))
        console.log('userid.....' + JSON.stringify(data.data[0].id))
        localStorage.setItem('userinfo', data);
        setIsloading(false)
        isModalOpen(false)
  
       // window.location.href = '/profile-page';
        navigate(`/profile-page`);

      }
      else if (data.message == "Registered Successfully") {
        let st = localStorage.getItem('tem_mobileno');
        localStorage.setItem('token', data.token);
        localStorage.setItem('mobileno', st);
        localStorage.setItem('userinfo', data);
        localStorage.setItem('userid', data.data[0].id);
        setIsloading(false)

        isModalOpen(false)
        toast('Login Successfully')
        navigate(`/profile-page`);
      }
      else if (data.message == "ALREADY_VERIFIED") {
        toast('Already Verified')
        setIsloading(false)
       window.location.href = '/profile-page';
      }
      console.log('Success:', data);
    } catch (error) {
      toast(error)
     // window.location.href = '/o';
      setIsloading(false)
    }
    setIsloading(false)

  }
  const handleSubmitotp_test = async () => {
    setIsLoggedIn(false)
    setIsloading(true)
    //return
    const datafrom = new FormData();
    datafrom.append('mobile', localStorage.getItem('tem_mobileno')); //localStorage.getItem('tem_mobileno') localStorage.getItem('tem_mobileno')
    datafrom.append('password', '12345678');
    datafrom.append('type', 'phone');
    console.log('from' + datafrom);
    try {
      const response = await fetch(url + '/app/v1/api/sign_up_with_mobile', {
        method: 'POST',
        body: datafrom
      });

      if (!response.ok) {

        toast('Network response was not ok')
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      if (data.message == "mobile no already exits") {
        let st = localStorage.getItem('tem_mobileno');
        localStorage.setItem('mobileno', st);
        localStorage.setItem('token', data.token);
        localStorage.setItem('userid', data.data[0].id);
        localStorage.setItem('userinfo', data);
        setIsloading(false)
        isModalOpen(false)
        toast('Login Successfully')
        window.location.href = '/profile-page';

      }
      else if (data.message == "Registered Successfully") {
        let st = localStorage.getItem('tem_mobileno');
        localStorage.setItem('token', data.token);
        localStorage.setItem('mobileno', st);
        localStorage.setItem('userid', data.data[0].id);
        setIsloading(false)

        isModalOpen(false)
        toast('Login Successfully')
        window.location.href = '/profile-page';
      }
      else if (data.message == "ALREADY_VERIFIED") {
        toast('Already Verified')
        setIsloading(false)
        window.location.href = '/profile-page';
      }
      console.log('Success:', data);
    } catch (error) {
      toast(error)
      window.location.href = '/profile-page';
      setIsloading(false)
    }
    setIsloading(false)

  }

  const handleSubmitotp = async (e) => {
    e.preventDefault();
    // alert(otp);
    setIsloading(true)
    if (otp == "") {
      toast('Insert the OTP')
      setIsloading(false)
    }
    else { //`/results?${queryParams}`
      let verification = localStorage.getItem('verificationId');
      let sendurl = `https://cpaas.messagecentral.com/verification/v3/validateOtp?verificationId=${verification}&code=${otp}`;

      fetch(sendurl, { ///api/login
        // method: 'GET',
        headers: {
          'authToken': `${localStorage.getItem('token')}`,
        },

      })
        .then((response) => response.json())
        .then((response) => {
          setIsloading(false);
          console.log('verification response' + JSON.stringify(response));
          if (response.message == "SUCCESS" && response.data.verificationStatus == "VERIFICATION_COMPLETED") {
            //alert('verified');
            //start checking to insert into database
            handleSubmitotp_test();

            //end db checking
          }
          /* {"responseCode":505,"message":"verificationId is invalid","data":null} */
          else if (response.message == "verificationId is invalid") {
            // alert('Invalid SMS Code');
            toast("Invalid, Invalid SMS Code!");
          }
        })
        .catch((err) => {
          console.log(err.message);
        });

    }

  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsloading(true);
    const response = await fetch('https://cpaas.messagecentral.com/auth/v1/authentication/token?customerId=C-659CA72D61C94C9&key=QWRhcnNoMTIzIQ==&scope=NEW&country=91&email=adarsh.bhatia@gmail.com', {
    });

    const data = await response.json();
    let token = data.token;
    localStorage.setItem('token', token);
    fetch("https://cpaas.messagecentral.com/verification/v3/send?countryCode=91&flowType=SMS&mobileNumber=" + mobile, { ///api/login
      method: 'POST',
      headers: {
        'authToken': `${token}`,
      },
      body: ''
    })
      .then((response) => response.json())
      .then((response) => {
        setIsloading(false);
        console.log('sms' + JSON.stringify(response));
        if (response.message == "SUCCESS") {
          localStorage.setItem("verificationId", response.data.verificationId);
          localStorage.setItem("tem_mobileno", mobile);
          // let code = response.verificationId;
          console.log('verification id : ' + response.data.verificationId);
          setIslogin(false);
          setIsotpscreen(true);

        }
      })
      .catch((err) => {
        console.log(err.message);
      });


  };

  useEffect(() => {
    let interval = null;

    if (timer > 0 && !isResendEnabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsResendEnabled(true);
    }

    return () => clearInterval(interval);
  }, [timer, isResendEnabled]);

  const handleResendOtp = () => {

  }

  //   const toggleCart = () => {
  //     if (isLoggedIn) {
  //       setShowCart(!showCart);
  //     } else {
  //       openModal();
  //     }
  //   };
  // //testopt
  const testopt = async () => {


  };
  return (
    <div className="w-full">
      <header
        id="header"
        className={`fixed top-0  w-full h-20 right-0 z-10 flex items-center justify-between  bg-[#1f0a1dae] transition-transform duration-500 ${visible ? "top-0" : "-top-20"}`}
      >
        <div className="flex items-center justify-between w-[1050px] mx-auto">
          <div className="w-[400px] flex items-center">
            <a href="/o" className="logo w-16">
              <img src={logo} className="w-full h-full" alt="ZULU Logo" />
            </a>
            {/* LOCATION DROPDOWN */}
            <div className=" w-full flex ml-2 text-lg text-white">        <LocationDropdown /></div>


          </div>
          <nav
            id="navbar"
            className="hidden lg:flex items-center space-x-8 text-sm w-full uppercase"
            ref={menuRef}
          >

            <NavLink
              to="/o"
              className={({ isActive }) =>
                `flex items-center ${isActive ? "text-[#ffde59]" : "text-white"}`
              }
            >
              <FaRegCompass className="mr-2" />
              Explore
            </NavLink>

            <NavLink
              to="/allproducts"
              className={({ isActive }) =>
                `flex items-center ${isActive ? "text-[#ffde59]" : "text-white"}`
              }
            >
              <FaShoppingBagOutline className="mr-2" />
              Shop now
            </NavLink>


            <NavLink
              to="/alloutlets"
              className={({ isActive }) =>
                `flex items-center ${isActive ? "text-[#ffde59]" : "text-white"}`
              }
            >
              <FaStoreAlt className="mr-2" />
              Outlets
            </NavLink>

            <NavLink
              to="/image/5"
              className={({ isActive }) =>
                `flex items-center ${isActive ? "text-[#ffde59]" : "text-white"}`
              }
            >
              <FaHomeOutline className="mr-2" />
              At Home
            </NavLink>

            <NavLink
              to="/"
              className={({ isActive }) =>
                `flex items-center ${isActive ? "text-[#ffde59]" : "text-white"}`
              }
            >
              <FaBriefcase className="mr-2" />
             Business
            </NavLink>


            <NavLink
              to="#"
              onClick={toggleCartSidebar}
              className="flex items-center text-white"
            >
             <div className="relative">
              <FaShoppingCart className="mr-1 w-6 h-6" />
              {cartItemCount > 0 && (
                <span className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-blue-500 text-white text-xs rounded-full w-4 h-4 flex items-center justify-center">
                  {cartItemCount}
                </span>
              )}
            </div>

            </NavLink>

            <NavLink
              to="#"
              onClick={toggleFavoritesSidebar}
              className="flex items-center text-white"
            >
              <FaHeart className="mr-1 w-5 h-5" />
      
            </NavLink>

            {isLoggedIn ? (
        <>
          {/* Profile Link when logged in */}
          <NavLink
            to="/profile-page"
            className={({ isActive }) =>
              `flex items-center ${isActive ? "text-[#ffde59]" : "text-white"}`
            }
          >
            <FaRegUser className="mr-1 w-5 h-5" />
            {/* Profile */}
          </NavLink>

          {/* LogOut Link */}
          {/* <NavLink
            to="#"
            onClick={logOut}
            className={({ isActive }) =>
              `flex items-center ${isActive ? "text-[#ffde59]" : "text-white"}`
            }
          >
            <FaSignOutAlt className="mr-2 w-4 h-4" />
            LogOut
          </NavLink> */}
        </>
      ) : (
        <>
          {/* If not logged in, open login modal */}
          <NavLink
            to="#"
            onClick={openModal}
            className={({ isActive }) =>
              `flex items-center ${isActive ? "text-[#ffde59]" : "text-white"}`
            }
          >
            <FaRegUser className="mr-2" />
            {/* Profile */}
          </NavLink>
        </>
      )}



          </nav>
          <div className="lg:hidden flex items-center" onClick={toggleMenu}>
            {isMenuOpen ? <IoMdClose className="text-xl" /> : <IoMdMenu className="text-xl" />}
          </div>
        </div>
      </header>

      <Popup isVisible={isPopupVisible} onClose={handleClosePopup} />
      {/* Cart Sidebar Component */}
      <div>
        <ShoppingCartWindow
          isOpen={isCartSidebarOpen}
          onClose={toggleCartSidebar}
        />
      </div>

      <div>
        <FavoritesSidebar
          isOpen={isFavoritesSidebarOpen}
         onClose={toggleFavoritesSidebar}
          // onClose={togglefavoritesSidebar}
        />
      </div>


      {/*  <!-- modal --> */}
      {
        isModalOpen ?
          <>
             <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
      <div className="relative container mx-auto px-4">
        <div className="bg-white  shadow-xl rounded-lg max-w-lg h-[600px] mx-auto w-full">
          {/* Header */}
          <div className="flex justify-between items-center p-6 border-b ">
          <div className="flex items-center">
          <img
              src={logo}
              alt="Dribbble Logo"
              className="w-20"
            />
              <h2 className="text-xl font-bold text-gray-800">
              Welcome Back,
              {/* Fill your email and password to sign in. */}
              </h2>
            
          </div>
            <Link to="#" onClick={closeModal} className="text-gray-600 ">
              <FaTimes size={20} />
            </Link>
          </div>

          {/* Body */}
          <div className="p-6 space-y-6">
          <h2 className="text-lg  font-bold text-gray-800 ">
              {islogin ? "Sign In" : "Verify OTP"}
            </h2>
            <ToastContainer />

            {islogin ? (
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label htmlFor="phone-input" className="block text-sm font-medium text-gray-700 ">
                    Phone Number
                  </label>
                  <div className="relative mt-1">
                    <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500 ">
                      <svg
                        className="w-4 h-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 19 18"
                      >
                        <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                      </svg>
                    </span>
                    <input
                      type="text"
                      id="phone-input"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                      placeholder="Mobile No"
                      required
                      className="block w-full pl-10 pr-3 py-2 border rounded-lg text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>
                <div>
                <button
                type="submit"
                className={`w-full py-2 rounded-lg text-white transition duration-300 ${isloading ? 'bg-gray-400' : 'bg-gray-500 hover:bg-gray-600'}`}
                disabled={isloading}
              >
                {isloading ? (
                  <div className="flex justify-center items-center">
                    <svg
                      className="animate-spin h-5 w-5 text-white mr-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      />
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      />
                    </svg>
                    {/* Loading... */}
                  </div>
                ) : (
                  "Submit"
                )}
                </button>

                </div>
              </form>
            ) : (
              <form onSubmit={handleSubmitotp} className="space-y-6">
                <div>
                  <label htmlFor="otp-input" className="block text-sm font-medium text-gray-700">
                    OTP
                  </label>
                  <div className="relative mt-1">
                    <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500 ">
                      <svg
                        className="w-4 h-4"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 19 18"
                      >
                        <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                      </svg>
                    </span>
                    <input
                      type="text"
                      id="otp-input"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      placeholder="Enter OTP"
                      required
                      className="block w-full pl-10 pr-3 py-2 border rounded-lg text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 "
                    />
                  </div>
                </div>
                <div>
                  {isloading ? (
                    <div className="flex justify-center">
                      <Spinner />
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className="w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300"
                    >
                      Verify OTP
                    </button>
                  )}
                </div>
                <div className="text-center mt-4">
                  {timer > 0 ? (
                    <p>Resend OTP in {timer} seconds</p>
                  ) : (
                    <button
                      onClick={handleResendOtp}
                      disabled={!isResendEnabled}
                      className={`bg-blue-500 text-white py-2 px-4 rounded-lg ${!isResendEnabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600 transition duration-300'}`}
                    >
                      Resend OTP
                    </button>
                  )}
                </div>
              </form>
            )}

            {/* Manual Login for Development */}
            <div className="text-center mt-6">
              <button onClick={manual_login_for_development} className="text-sm text-blue-500 hover:underline">
                Manual Login (Development)
              </button>
            </div>
          </div>

          {/* Footer */}
          <div className="text-center p-6 border-t text-xs mt-32 dark:border-gray-700">
            By proceeding, you agree to our{" "}
            <Link to="/privacy-policy" className="underline">
              Terms of Use
            </Link>{" "}
            and confirm you have read our{" "}
            <Link to="/privacy-policy" className="underline">
              Privacy and Cookie Statement
            </Link>.
          </div>
        </div>
      </div>
    </div>

          </>
          :
          ''
      }




    </div>
  );
};

export default HeaderNew;
