import React from "react";
import { url } from "../api/Url";



const Card7 = ({ zulu_category_name, seller_category_name, zulu_category_image, seller_category_image, onImageDisplayed}) => {
  const handleImageLoad = () => {
    // Call the onImageDisplayed function when the image is fully displayed
    if (onImageDisplayed) onImageDisplayed();
  };
  
  return (
    <div className="w-full flex justify-center mb-4">
      <div className="relative w-[120px] cursor-pointer rounded-t-lg border-gray-800 overflow-hidden"> {/* Added overflow-hidden */}
        {
          seller_category_image ?
            <>
              <img src={`${seller_category_image}`} alt="" className="w-full h-[120px] object-cover rounded-lg bg-gray-200 mt-4" onLoad={handleImageLoad}/>
              <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-gray-900 to-transparent rounded-b-lg"></div>
            </>
            :
            <>
              <img src={`${zulu_category_image}`} alt="" className="w-full h-[120px] object-cover rounded-lg bg-gray-200 mt-4" onLoad={handleImageLoad} />
              <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-gray-900 to-transparent rounded-b-lg"></div>
            </>
        }

        <div className="absolute bottom-0 w-full bg-transparent bg-opacity-80 border-gray-800 text-white text-center py-2 rounded-b-lg "> 
          <p className="text-sm font-bold uppercase">
            {seller_category_name ? seller_category_name : zulu_category_name}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card7;
