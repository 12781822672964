import React from "react";
import { url } from "../api/Url";

// Function to generate star rating
const generateStars = (rating) => {
  const numRating = parseFloat(rating); // Convert rating to a number
  if (isNaN(numRating) || numRating < 0) return null; // Return nothing if rating is invalid

  const fullStars = Math.floor(numRating); // Full stars
  const hasHalfStar = numRating % 1 >= 0.5; // Check if there's a half star
  const emptyStars = 5 - Math.ceil(numRating); // Empty stars

  return (
    <>
      {[...Array(fullStars)].map((_, index) => (
        <span key={`full-${index}`} className="text-yellow-500">★</span>
      ))}
      {hasHalfStar && <span className="text-yellow-500">☆</span>}
      {[...Array(emptyStars)].map((_, index) => (
        <span key={`empty-${index}`} className="text-gray-300">☆</span>
      ))}
    </>
  );
};

// Testimonialcard component with star rating
const Testimonialcard = ({ id, username, user_image, comments, rating }) => {
  return (
    <div className="rounded-xl overflow-hidden shadow-md bg-slate-100 md:w-full w-full lg:w-full mb-4 flex gap-5 p-5 items-center text-left">
      <div className="flex-shrink-0">
        {/* User Image */}
        <img
          src={url + `/uploads/seller/${user_image}`} // Adjust the URL to the correct image path
          alt={`${username}`}
          className="w-16 h-16 rounded-full object-cover"
        />
      </div>

      <div>
        {/* Username */}
        <h3 className="font-bold text-lg">{username}</h3>
        {/* Rating */}
        <div className="flex items-center mb-2">
          {generateStars(rating)}
        </div>
        {/* Comments */}
        <p className="mt-3 text-gray-700">{comments}</p>
      </div>
    </div>
  );
};

export default Testimonialcard;
