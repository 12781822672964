import React, { useEffect, useState, useCallback, useRef } from "react";
import Card7 from "../cards/Card7";
import axios from "axios";
import { useCategory } from "./CategoryContext";
import { useLoading } from "./LoadingContext";
import "./styles.css";
import Load from "../../assets/images/States/Loader.gif";

const CategoriesMenu3 = () => {
  const [categoriesData, setCategoriesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imagesDisplayed, setImagesDisplayed] = useState(0);
  const timeoutRef = useRef(null);

  const { setSelectedCategory } = useCategory();
  const { isLoading, setIsLoading } = useLoading();

  const getCategories = async () => {
    const categoriesUrl = "https://zulushop.in/zulu_category_list";

    try {
      const response = await axios.post(categoriesUrl);
      let res = response.data;

      if (typeof res === "string") {
        try {
          res = JSON.parse(res);
        } catch (error) {
          console.error("Error parsing JSON response:", error);
          setCategoriesData([]);
          return;
        }
      }

      if (Array.isArray(res.data)) {
        const categoriesWithChildren = res.data.filter(
          (category) => category.children && category.children.length > 0
        );

        const categories = categoriesWithChildren.map((category) => ({
          zulu_category_name: category.name,
          zulu_category_image: `${category.image}`,
          zulu_category_id: category.id,
          children: category.children,
        }));

        setCategoriesData(categories);
      } else {
        console.warn("Unexpected data structure:", res);
        setCategoriesData([]);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      setCategoriesData([]);
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryClick = (category) => {
    setIsLoading(true);
    setSelectedCategory(category);
  };

  // Handle image fully displayed
  const handleImageDisplayed = useCallback(() => {
    setImagesDisplayed((prev) => prev + 1);
  }, []);

  useEffect(() => {
    // Check if all images are loaded
    if (imagesDisplayed === categoriesData.length && categoriesData.length > 0) {
      // Wait for 3 seconds after images are fully displayed
      const delayAfterImagesLoaded = setTimeout(() => {
        setIsLoading(false); // Stop showing the loading modal
      }, 3000);

      // Clear timeout if the component is unmounted
      return () => clearTimeout(delayAfterImagesLoaded);
    }
  }, [imagesDisplayed, categoriesData.length]);

  useEffect(() => {
    // Set initial loading state and start fetching categories
    setIsLoading(true);
    getCategories();
  }, []);

  const skeletonCards = new Array(5).fill(0);

  return (
    <div className="mt-6">
      <div className="mt-1">
        <div className="flex overflow-x-auto space-x-4 md:grid md:grid-cols-3 md:gap-4 lg:grid-cols-5 w-full sm:w-[80%] md:w-[70%] lg:w-[60%] mx-auto">
          {loading ? (
            skeletonCards.map((_, index) => (
              <div key={index} className="flex-shrink-0 w-[150px] sm:w-auto animate-pulse">
                <div className="bg-gray-300 h-32 w-full mb-2 rounded-md"></div>
                <div className="h-4 bg-gray-300 w-3/4 mx-auto mb-2 rounded-md"></div>
                <div className="h-4 bg-gray-300 w-1/2 mx-auto rounded-md"></div>
              </div>
            ))
          ) : categoriesData.length > 0 ? (
            categoriesData.map((category) => (
              <div
                key={category.zulu_category_id}
                onClick={() => handleCategoryClick(category)}
                className="flex-shrink-0 w-[150px] sm:w-auto cursor-pointer"
              >
                <Card7
                  zulu_category_name={category.zulu_category_name}
                  zulu_category_image={category.zulu_category_image}
                  onImageDisplayed={handleImageDisplayed}
                />
              </div>
            ))
          ) : (
            <p>No categories to display</p>
          )}
        </div>
      </div>

      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50">
          <img src={Load} alt="Loading..." />
        </div>
      )}
    </div>
  );
};

export default CategoriesMenu3;
