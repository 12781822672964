import React, { useEffect, useState, useCallback } from 'react';
import Spinner from '../../../components/Spinner/Spinner';
import { url } from '../../../components/api/Url';
import { User, Truck, FileText, CreditCard, Calendar, Phone, UserCircle, Mail, Grid, Bike, CheckCircle } from 'lucide-react';

const Modal = ({ order, onClose }) => {
    if (!order) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg w-full max-w-md shadow-lg">
                <div className="p-4 flex justify-between items-center bg-gray-100 rounded-t-lg">
                    <button onClick={onClose} className="text-gray-600 hover:text-gray-800">
                        Back
                    </button>
                    <h2 className="text-xl font-bold text-gray-800">Order Details</h2>
                    <div className="w-10"></div> {/* Placeholder for symmetry */}
                </div>
                <div className="p-6">
                    <div className="flex justify-between items-center mb-4">
                        <span className="px-3 py-1 rounded-full text-white text-sm font-semibold bg-yellow-500">
                            {order.status || 'Pending'}
                        </span>
                        <span className="text-gray-600 font-medium">Order No.{order.id}</span>
                        {/* <Phone size={20} className="text-gray-600" /> */}
                    </div>
                    <div className="space-y-4 text-sm">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <User size={20} className="mr-2 text-gray-600" />
                                <span>{order.username}</span>
                            </div>
                            {/* <Truck size={20} className="text-gray-600" /> */}
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <FileText size={20} className="mr-2 text-gray-600" />
                                <span>₹ {order.total_payable}</span>
                            </div>
                            {/* <CreditCard size={20} className="text-gray-600" /> */}
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <Calendar size={20} className="mr-2 text-gray-600" />
                                <span>{order.date_added}</span>
                            </div>
                            {/* <Grid size={20} className="text-gray-600" /> */}
                        </div>
                        <div className="flex items-center">
                            <Phone size={20} className="mr-2 text-gray-600" />
                            <span>{order.phone || '01885553021'}</span>
                        </div>
                        <div className="flex items-center">
                            <UserCircle size={20} className="mr-2 text-gray-600" />
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <Mail size={20} className="mr-2 text-gray-600" />
                                <span>{order.email || 'manirul26@gmail...'}</span>
                            </div>
                            {/* <div className="flex items-center">
                            <Bike size={20} className="mr-2 text-gray-600" />
                            <span>0</span>
                        </div> */}
                        </div>
                    </div>

                    <div className="space-y-4 text-sm">
                        <div className="flex items-center justify-between">
                            <div className="flex items-center">
                                <h1>Item Details</h1>
                                {
                                   order.order_items.map((item,index) => {
                                      <div key={index}>
                                        {item.product_name}
                                      </div>

                                   })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4 mt-6">
                        <button className="bg-yellow-500 text-white py-2 px-4 rounded-lg font-semibold">
                            Print Invoice
                        </button>
                        <button className="bg-yellow-500 text-white py-2 px-4 rounded-lg font-semibold">
                            Loyalty
                        </button>
                        {/* <button className="bg-yellow-500 text-white py-2 px-4 rounded-lg font-semibold col-span-2">
                        Shipping
                    </button>
                    <button className="bg-yellow-500 text-white py-2 px-4 rounded-lg font-semibold col-span-2">
                        Payment
                    </button> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

const ViewOrder = () => {
    const [orderData, setOrderData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const getOrder = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        const userId = localStorage.getItem('userid');
        const token = localStorage.getItem('token');
       // alert(userId);

        if (!userId || isNaN(userId)) {
            setError('Invalid user ID. Please log in again.');
            setIsLoading(false);
            return;
        }

        const formData = new FormData();
        formData.append('user_id', userId);

        try {
            const response = await fetch(`${url}/seller/app/v1/api/get_orders`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            if (data.error) {
                throw new Error(data.message || 'An error occurred while fetching orders.');
            }

            console.log('Orders:', data.data); // Log all orders here

            setOrderData(data.data || []);
        } catch (error) {
            console.error('Error fetching orders:', error);
            setError('Failed to fetch orders. Please try again later.');
        } finally {
            setIsLoading(false);
        }
    }, []);

    useEffect(() => {
        getOrder();
    }, [getOrder]);

    const handleViewOrder = (orderId) => {
        const order = orderData.find(o => o.id === orderId);
        if (order) {
            setSelectedOrder(order); // Pass the order with active_status directly
        }
    };

    const closeModal = () => {
        setSelectedOrder(null);
    };

    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const formattedDate = date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        }).replace(/ /g, '-');

        const formattedTime = date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });

        return `${formattedDate} ${formattedTime}`;
    };

    if (isLoading) return <Spinner />;
    if (error) return <div className="text-red-500 text-center mt-4">{error}</div>;

    return (
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-10'>
            {orderData.length > 0 ? (
                orderData.map((order) => (
                    <div key={order.id} className="bg-white rounded-lg shadow-md p-4 cursor-pointer " onClick={() => handleViewOrder(order.id)} >
                        <div className="flex justify-between items-center mb-4">
                            <span className={`px-3 py-1 rounded-full text-white text-sm font-semibold ${order.active_status === 'Delivered' ? 'bg-green-500' : 'bg-gray-400'}`}>
                                {order.active_status || 'Details'}
                            </span>
                            <span className="text-gray-600 font-medium">Order No.{order.id}</span>
                        </div>
                        <div className="space-y-2 text-sm">
                            <div className="flex items-center">
                                <User size={20} className="mr-2" />
                                <span>{order.username || 'N/A'}</span>
                            </div>
                            <div className="flex items-center">
                                <Truck size={20} className="mr-2" />
                                <span>{order.address}</span>
                            </div>
                            <div className="flex items-center">
                                <FileText size={20} className="mr-2" />
                                <span>{order.total_payable}</span>
                            </div>
                            <div className="flex items-center">
                                <CreditCard size={20} className="mr-2" />
                                <span>{order.payment_method}</span>
                            </div>
                            <div className="flex items-center">
                                <Calendar size={20} className="mr-2" />
                                <span>{formatDateTime(order.date_added)}</span>
                            </div>
                        </div>
                        <div className="flex justify-end mt-4">
                            {order.active_status === 'Delivered' && <CheckCircle size={24} className="text-green-500" />}
                        </div>
                    </div>
                ))
            ) : (
                <div className='text-center col-span-3'>No orders found</div>
            )}

            {/* Modal to show order details */}
            {selectedOrder && <Modal order={selectedOrder} onClose={closeModal} />}
        </div>
    );
};

export default ViewOrder;
