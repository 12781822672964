import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Heart, Share2 } from "lucide-react";
import { FaCheckCircle, FaCreditCard, FaShoppingBag } from "react-icons/fa";
import { useProduct } from "./ProductContext";
import { FaHeart, FaTentArrowTurnLeft } from "react-icons/fa6";
import ProductCard from "../cards/ProductCard";
import { useCart } from "./CartContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Load from "../../assets/images/States/Loader.gif";
import { FaStar, FaStarHalfAlt, FaRegStar } from "react-icons/fa"; // I

const ProductDetailPage = () => {
  const { productId } = useParams();
  const { products, loading, error, selectedProduct, selectProduct } =
    useProduct();
  const variant = selectedProduct.variants?.[0];
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const predefinedSizes = ["S", "M", "L", "XL", "XXL"];
  const [activeTab, setActiveTab] = useState("specification");
  const { addToCart } = useCart();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loadingImages, setLoadingImages] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [defaultImage] = useState(
    selectedProduct.other_images?.[currentImageIndex] ||
      selectedProduct.image ||
      "/path/to/default-image.png"
  );
  const [image, setImage] = useState(defaultImage);
  const [price, setPrice] = useState(
    variant?.special_price || variant?.price || "N/A"
  );
  const [updatedProduct, setUpdatedProduct] = useState({ ...selectedProduct });
  const [isProductFavorited, setIsProductFavorited] = useState(false);
  const [recommended, setRecommended] = useState([]);

  const fetchFavoriteState = async () => {
    const token = localStorage.getItem("token");
    if (!token || !selectedProduct?.id) return;

    try {
      const response = await axios.get(`https://zulushop.in/app/v1/api/get_favorites/${selectedProduct.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setIsProductFavorited(response.data.is_favorite);
    } catch (error) {
      console.error("Error fetching favorite state:", error);
    }
  };

  useEffect(() => {
    fetchFavoriteState();
  }, [selectedProduct?.id]);

  const isSizeAvailable = (size) => {
    return (
      selectedProduct?.variants?.some(
        (variant) => variant.size === size && variant.stock > 0
      ) ?? false
    );
  };
  
  useEffect(() => {
    if (products.length > 0) {
      const product = products.find((p) => p.id === parseInt(productId, 10));
      if (product) {
        selectProduct(product);
      }
    }
  }, [products, productId, selectProduct]);

  useEffect(() => {
    const fetchRecommendedProducts = async () => {
      console.log('Fetching recommended products for category_id:', selectedProduct.category_id, 'and product_id:', selectedProduct.id);
  
      if (selectedProduct) {
        const recommended = await recommendedProducts(selectedProduct.category_id, selectedProduct.id);
        setRecommended(recommended || []);
        
        console.log('Final recommended products result:', recommended);
      }
    };
  
    fetchRecommendedProducts();
  }, [selectedProduct]);
  

  const recommendedProducts = async (category_id, product_id) => {
    console.log('Starting recommendedProducts fetch process with FormData');
  
    try {
      console.log('Preparing FormData without using append...');
      
      const formData = new FormData();
      const formDataObject = {
        category_id: category_id,
        id: product_id,
        is_similar_products: 1,  // Ensures similar products are fetched
      };
  
      // Construct FormData from an object directly
      Object.keys(formDataObject).forEach(key => {
        formData[key] = formDataObject[key];
      });
  
      console.log('FormData object:', formData);
  
      const response = await fetch('https://zulushop.in/app/v1/api/get_products', {
        method: 'POST',
        body: formData, // Sending FormData
      });
  
      console.log('Response received:', response);
      console.log('Response status:', response.status);
      
      const data = await response.json();
      console.log('Parsed JSON data:', data);
  
      if (!data.error) {
        console.log('Recommended Products data:', data.data);
        return data.data; // Assuming products are inside data.data
      } else {
        console.error('Error fetching products:', data.message);
        return [];
      }
    } catch (error) {
      console.error('Fetch error occurred:', error);
      return [];
    } finally {
      console.log('Fetching process completed');
    }
  };
  
  


  
  
  // const recommendedProducts = products.filter(
  //   (p) =>
  //     p.category_id === selectedProduct?.category_id &&
  //     p.id !== selectedProduct?.id &&
  //     p.is_similar_products === 1
  // );
    
  const roundPrice = (price) => Math.round(price);

  const calculateDiscount = (price, specialPrice) => {
    return Math.round(((price - specialPrice) / price) * 100);
  };

  useEffect(() => {
    const variantImages = selectedVariant?.images || [];
    const productImages = selectedProduct?.other_images || [];
    const combinedImages = [...variantImages, ...productImages];

    if (combinedImages.length > 0) {
      setImage(combinedImages[0]); // Set the first image initially
      setCurrentImageIndex(0); // Reset index
    }
  }, [selectedVariant, selectedProduct]);

  // Handle image click on thumbnails
  const handleImageClick = (index) => {
    const variantImages = selectedVariant?.images || [];
    const productImages = selectedProduct?.other_images || [];
    const combinedImages = [...variantImages, ...productImages];

    setLoadingImages(true); // Start loading state
    setCurrentImageIndex(index);
    setImage(combinedImages[index]); // Set the clicked image
  };

  // Handle next image
  const nextImage = () => {
    const variantImages = selectedVariant?.images || [];
    const productImages = selectedProduct?.other_images || [];
    const combinedImages = [...variantImages, ...productImages];

    setLoadingImages(true); // Start loading state
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % combinedImages.length);
    setImage(combinedImages[(currentImageIndex + 1) % combinedImages.length]);
  };

  // Handle previous image
  const prevImage = () => {
    const variantImages = selectedVariant?.images || [];
    const productImages = selectedProduct?.other_images || [];
    const combinedImages = [...variantImages, ...productImages];

    setLoadingImages(true); // Start loading state
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + combinedImages.length) % combinedImages.length
    );
    setImage(combinedImages[(currentImageIndex - 1 + combinedImages.length) % combinedImages.length]);
  };

  // Once image has loaded, stop showing skeleton loader
  const handleImageLoad = () => {
    setLoadingImages(false); // Stop loading state when image loads
  };

  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };



  

  const toggleFavorite = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      toast.error("Please log in to add to favorites.");
      return;
    }

    const updatedIsFavorited = !isProductFavorited;
    setIsProductFavorited(updatedIsFavorited);

    try {
      await handleFavorite(selectedProduct.id, updatedIsFavorited);
    } catch (error) {
      console.error("Error toggling favorite:", error);
      setIsProductFavorited(!updatedIsFavorited); // Revert state if API call fails
      toast.error("Failed to update favorite status. Please try again.");
    }
  };

  const handleFavorite = async (product_id, isFavorite) => {
    const token = localStorage.getItem("token");
    if (!token) return;

    const url = isFavorite
      ? "https://zulushop.in/app/v1/api/add_to_favorites"
      : "https://zulushop.in/app/v1/api/remove_from_favorites";

    try {
      const formData = new FormData();
      formData.append("user_id", localStorage.getItem("userid"));
      formData.append("product_id", product_id);

      await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success(isFavorite ? "Product added to favorites!" : "Product removed from favorites!");

      const favorites = JSON.parse(localStorage.getItem("favorites")) || {};
      if (isFavorite) {
        favorites[product_id] = true;
      } else {
        delete favorites[product_id];
      }
      localStorage.setItem("favorites", JSON.stringify(favorites));
    } catch (error) {
      console.error("Error handling favorite action:", error);
      toast.error(isFavorite ? "Failed to add product to favorites." : "Failed to remove product from favorites.");
    }
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating); // Full stars
    const hasHalfStar = rating % 1 >= 0.5; // Check if there's a half star
    const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0); // Remaining empty stars
  
    return (
      <div className="flex gap-1">
        {/* Render full stars */}
        {Array(fullStars)
          .fill(0)
          .map((_, index) => (
            <FaStar key={index} className="text-yellow-500" />
          ))}
  
        {/* Render half star */}
        {hasHalfStar && <FaStarHalfAlt className="text-yellow-500" />}
  
        {/* Render empty stars */}
        {Array(emptyStars)
          .fill(0)
          .map((_, index) => (
            <FaRegStar key={index} className="text-yellow-500" />
          ))}
      </div>
    );
  };
  

  return (
    <div className="container mx-auto px-4 py-8">
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50">
          <img src={Load} alt="Loading..." />
        </div>
      )}

      <div className="flex flex-col  md:flex-row gap-8">
        {/* Image Gallery */}
        <div className="w-full md:w-[60%] max-w-[600px] ml-auto flex gap-4">
      {/* Thumbnails */}
      <div className="flex flex-col gap-2">
        {selectedProduct.other_images?.map((image, index) => (
          <div key={index} className="relative">
            {loadingImages && index === currentImageIndex && (
              <Skeleton width={64} height={64} />
            )}
            <img
              src={image}
              alt={`${selectedProduct.name || "N/A"} - thumbnail ${index + 1}`}
              className={`w-16 h-16 object-cover cursor-pointer ${
                index === currentImageIndex ? "border-2 border-gray-400" : ""
              }`}
              onClick={() => handleImageClick(index)}
              onLoad={handleImageLoad} // Triggers when image loads
              style={{
                display: loadingImages && index === currentImageIndex ? "none" : "block",
              }}
            />
          </div>
        ))}
      </div>

      {/* Main Image */}
      <div className="relative">
        {loadingImages && <Skeleton width={500} height={500} />} {/* Show skeleton while loading */}
        <img
          src={image}
          alt={selectedProduct.name || "N/A"}
          className={`w-[500px] h-[500px] rounded-sm object-cover ${
            loadingImages ? "hidden" : ""
          }`} // Hide the image while loading
          onLoad={handleImageLoad} // Stop showing skeleton when image loads
        />
        <button
          onClick={prevImage}
          className="absolute left-4 top-1/3 transform -translate-y-1/2 bg-white bg-opacity-70 p-3 w-12 rounded-full shadow-md hover:bg-opacity-90 transition duration-150 ease-in-out"
        >
          &lt;
        </button>
        <button
          onClick={nextImage}
          className="absolute right-4 top-1/3 transform -translate-y-1/2 bg-white w-12 bg-opacity-70 p-3 rounded-full shadow-md hover:bg-opacity-90 transition duration-150 ease-in-out"
        >
          &gt;
        </button>
      </div>
    </div>{" "}
        {/* Product Details */}
        <div className="md:w-1/3">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl font-bold">
              {selectedProduct.name || "N/A"}
            </h1>
            <div className="flex gap-2">
              <Share2 className="w-6 h-6" />
              <FaHeart
        className={`w-6 h-6 ${isProductFavorited ? "text-red-500" : "text-gray-400"}`}
        onClick={toggleFavorite}
        style={{ cursor: "pointer" }} // Make it clickable
      />
              
            </div>
          </div>
          <div className="flex items-center gap-4 text-left mb-4">
            <span className="text-2xl font-bold">₹{roundPrice(price)}</span>
            {selectedVariant
              ? selectedVariant?.price !== selectedVariant?.special_price && (
                  <>
                    <span className="text-gray-500 line-through">
                      MRP: ₹{roundPrice(selectedVariant?.price)}
                    </span>
                    <span className="bg-green-500 text-white px-2 py-1 text-sm rounded">
                      {calculateDiscount(
                        selectedVariant?.price,
                        selectedVariant?.special_price
                      )}
                      % OFF
                    </span>
                  </>
                )
              : variant?.price !== variant?.special_price && (
                  <>
                    <span className="text-gray-500 line-through">
                      MRP: ₹{roundPrice(variant?.price)}
                    </span>
                    <span className="bg-green-500 text-white px-2 py-1 text-sm rounded">
                      {calculateDiscount(
                        variant?.price,
                        variant?.special_price
                      )}
                      % OFF
                    </span>
                  </>
                )}
          </div>
          {/* Additional Product Information */}
          <div className="text-left font-semibold">
            <p className="mb-4">Store: {selectedProduct.store_name || "N/A"}</p>
            <p className="mb-4">
              Seller Rating: {selectedProduct.seller_rating || "N/A"}
            </p>
            <div className="mb-4 flex items-center">
          <span className="font-semibold mr-1">Rating:</span>
          {renderStars(selectedProduct.rating || 0)}
        </div>
            {/* <p className="mb-4">Color: {selectedProduct.color || "N/A"}</p> */}
          </div>

          {/* Tags */}
          {selectedProduct?.tags?.length > 0 && (
            <div className="mb-4 text-left font-semibold">
              {/* <p className="">Tags:</p> */}
              <div className="flex gap-2">
                {selectedProduct?.tags?.map((tag) => (
                  <button
                    key={tag}
                    className="px-3 py-1 border bg-gray-200 text-gray-500 uppercase text-xs rounded-md"
                  >
                    {tag}
                  </button>
                ))}
              </div>
            </div>
          )}

          {/* Variant Selection */}
          {selectedProduct?.variants?.length > 0 && (
  <div className="mb-4 text-left font-semibold">
    <p className="mb-2">{variant?.attr_name}</p>
    <div className="flex gap-2 flex-wrap">
      {selectedProduct?.variants
        ?.filter((variant) => variant?.variant_values) // Ensure there's a value
        .map((variant) => (
          <button
            key={variant.id}
            className={`text-sm px-4 py-2 rounded-lg ${
              variant === selectedVariant ? "bg-gray-300 border-[2px]" : "border-[1px] border-gray-700"
            }`}
            onClick={() => setSelectedVariant(variant)}
          >
            {variant?.variant_values}
          </button>
        ))}
    </div>
  </div>
)}



          {/* Size Selection */}
          <div className="mb-4 text-left font-semibold hidden">
            <p className="">Size:</p>
            <div className="flex gap-2">
              {predefinedSizes.map((size) => (
                <button
                  key={size}
                  className={`w-16 h-16 border ${
                    selectedSize === size
                      ? "bg-black text-white"
                      : "bg-white text-black"
                  } ${
                    isSizeAvailable(size) ? "" : "opacity-50 cursor-not-allowed"
                  }`}
                  onClick={() => isSizeAvailable(size) && setSelectedSize(size)}
                  disabled={!isSizeAvailable(size)}
                >
                  {size}
                </button>
              ))}
              <button
                className={`w-16 h-16 border ${
                  selectedSize === null
                    ? "bg-black text-white"
                    : "bg-white text-black"
                }`}
                onClick={() => setSelectedSize(null)}
              >
                Any
              </button>
            </div>
          </div>
          <div className="flex gap-4 mb-8">
            <button className="px-10 py-4 border border-black text-black font-semibold rounded-xl">
              Buy Now
            </button>
            <button
              className="px-10 py-4 bg-black text-white font-semibold rounded-xl"
              onClick={() => {
                addToCart(
                  updatedProduct
                  // selectedSize
                );
              }}
            >
              Add to Cart
            </button>
          </div>
          <div className="grid grid-cols-2 gap-4 mb-8">
            <div className="text-center flex">
              <FaCreditCard className="mx-2 " />
              <p className="text-sm">Secure Payments</p>
            </div>
            <div className="text-center flex">
              <FaCheckCircle className="mx-2 " />
              <p className="text-sm">Genuine Product</p>
            </div>
            <div className="text-center flex">
              <FaShoppingBag className="mx-2 " />
              <p className="text-sm">Try & Buy</p>
            </div>
            <div className="text-center flex">
              <FaTentArrowTurnLeft className="mx-2 " />
              <p className="text-sm">7 Day Return</p>
            </div>
          </div>
          {/* SPECIFICATIONS */}
          <div className="border rounded-xl p-4 text-left h-[320px]">
            <div className="flex justify-center space-x-8 border-gray-300">
              <button
                className={`text-lg font-semibold pb-2 ${
                  activeTab === "specification"
                    ? "border-b-2 border-black"
                    : "text-gray-500"
                }`}
                onClick={() => setActiveTab("specification")}
              >
                Specifications
              </button>
              <button
                className={`text-lg font-semibold pb-2 ${
                  activeTab === "description"
                    ? "border-b-2 border-black"
                    : "text-gray-500"
                }`}
                onClick={() => setActiveTab("description")}
              >
                Description
              </button>
            </div>

            {/* Tab Content */}
            <div className="mt-6">
            {activeTab === "specification" && selectedVariant && (
  <div className="space-y-4">
    {/* <p className="font-semibold">Category: {selectedProduct.category || "N/A"}</p>
    <p className="font-semibold">Size: {selectedVariant?.size || "N/A"}</p>
    <p className="font-semibold">Color: {selectedVariant?.color || "N/A"}</p> */}
    <p className="font-semibold">Brand: {selectedProduct.brand || "N/A"}</p>
    <p className="font-semibold">
      Stock: {selectedVariant?.stock > 0 ? `In Stock (${selectedVariant?.stock} available)` : "Out of Stock"}
    </p>
  </div>
)}
              {activeTab === "description" && (
               <p>{stripHtml(selectedProduct?.description)}</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* RECOMMENDED PRODUCTS */}
      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Recommended Products</h2>
        <div className="flex flex-wrap justify-center gap-4">
        {recommended.length > 0 ? (
  <div className="recommended-products">
    {/* <h3 className="text-xl font-semibold mb-4">Recommended Products</h3> */}
    <div className="flex gap-4">
      {recommended.map((product) => (
        <ProductCard key={product.id} product={product} />
      ))}
    </div>
  </div>
) : (
  <p>No recommended products available.</p>
)}

        </div>
      </div>
    </div>
  );
};

export default ProductDetailPage;