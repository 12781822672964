import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { url } from "../../components/api/Url";
import axios from "axios";
import { ArrowLeft } from "lucide-react";
import { Blogcard } from "../../components/cards/Blogcard";

const BlogDetails = () => {
  const [blogDetails, setBlogDetails] = useState(null);
  const [allBlogs, setAllBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { ids } = useParams();
  const navigate = useNavigate();

  const fetchBlogs = async () => {
    const json = JSON.stringify({ seller_id: localStorage.getItem("seller_id") });

    try {
      const res = await axios.post(url + "/app/v1/api/listofblog", json);
      console.log("API Response:", res.data);

      const blogs = res.data.message || [];
      setAllBlogs(blogs);

      const foundBlog = blogs.find((blog) => blog.id === ids);
      if (foundBlog) {
        setBlogDetails(foundBlog);
      } else {
        setError("Blog not found");
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching blog data:", error);
      setError("Failed to fetch blog details.");
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchBlogs();
  }, [ids]);

  const handleBlogClick = () => {
    navigate("/AllBlogs");
  };

  const handleBlogCardClick = (blogId) => {
    window.location.href = `/blog/${blogId}`;
  };

  if (loading) return <p>Loading blog details...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="flex">
      {/* Scrollable blog list */}
      <div className="w-1/3 h-screen overflow-y-auto scrollbar  p-4 border-r">
        {/* <h2 className="text-xl font-bold mb-4">All Blogs</h2> */}
        <div className="grid gap-4">
          {allBlogs.map((blog) => (
      <div 
      key={blog.id} 
      onClick={() => handleBlogCardClick(blog.id)}
      style={{ cursor: 'pointer' }}
    >
      <Blogcard
        name="blog"
        width="100%"
        height="200px"
        title={blog.Title}
        id={blog.id}
        Blogimage={`uploads/seller/${blog.Blogimage}`}
      />
    </div>
          ))}
        </div>
      </div>

      {/* Blog details */}
      <div className="w-2/3 p-8">
        {blogDetails ? (
          <div>
            <div className="flex items-center mb-4">
              {/* <button
                onClick={handleBlogClick}
                className="text-black mr-4 rounded-lg transition duration-300 ease-in-out"
              >
                <ArrowLeft />
              </button> */}
              <h1 className="text-3xl font-bold text-gray-900">
                {blogDetails.Title}
              </h1>
            </div>

            <img
              src={`${url}/uploads/seller/${blogDetails.Blogimage}`}
              alt={blogDetails.Title}
              className="w-full h-[350px] object-cover rounded-lg mb-6"
            />

            <div className="flex justify-between items-center mb-4">
              <a
                href={`https://${blogDetails.Bloglink}`}
                className="text-blue-600 hover:underline text-sm"
                target="_blank"
                rel="noopener noreferrer"
              >
                {blogDetails.Bloglink}
              </a>
              <span className="text-gray-500 text-sm">
                {new Date(blogDetails.created_by).toDateString()}
              </span>
            </div>

            <div className="flex space-x-2 mb-6">
              {blogDetails.Titletag?.split(',').map((tag, index) => (
                <span
                  key={index}
                  className="px-3 py-1 text-xs bg-gray-200 text-gray-600 rounded-full"
                >
                  {tag.trim()}
                </span>
              ))}
            </div>

            <p className="text-gray-700 text-sm leading-relaxed">
              {blogDetails.Content}
            </p>
          </div>
        ) : (
          <p className="text-gray-500 text-center">No blog details available.</p>
        )}
      </div>
    </div>
  );
};

export default BlogDetails;