import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { X, ChevronRight, Edit3, Trash2 } from 'lucide-react';
import { url } from '../../../components/api/Url';

const SellerEditAddressModal = ({ show, onClose, onSelectAddress }) => {
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(null); // To handle editing state
  const [editFormData, setEditFormData] = useState({
    name: '',
    type: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    mobile: ''
  });

  useEffect(() => {
    if (show) {
      console.log('Modal opened, fetching addresses');
      getAddress();
    }
  }, [show]);

  const getAddress = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const userid = localStorage.getItem("userid");
      if (!token || !userid) {
        throw new Error("Token or User ID not found in localStorage");
      }
      console.log('Fetching addresses for user:', userid);
      const formData = new FormData();
      formData.append("user_id", userid);
      const response = await axios.post(
        url + "/app/v1/api/get_user_address",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const addressData = response.data;
      console.log('Address data received:', addressData);
      if (!Array.isArray(addressData) || addressData.length === 0) {
        throw new Error("No addresses found");
      }
      setAddresses(addressData);
      setSelectedAddress(
        addressData.find((address) => address.is_default === "1")?.id || null
      );
    } catch (error) {
      console.error("Error fetching address:", error);
      toast.error("Error fetching addresses: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectAddress = (address) => {
    console.log('Selected address:', address);
    setSelectedAddress(address.id);
    onSelectAddress(address);
    onClose();
  };

  const handleDeleteAddress = async (id) => {
    try {
      const token = localStorage.getItem("token");
      console.log('Deleting address with ID:', id);
      const formData = new FormData();
      formData.append("id", id);
      const response = await axios.post(
        url + "/app/v1/api/delete_address",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('Delete response:', response.data);
      if (response.data.success) {
        toast.success("Address deleted successfully!");
        getAddress(); // Refresh the addresses after deletion
      } else {
        throw new Error('Failed to delete address');
      }
    } catch (error) {
      console.error("Error deleting address:", error);
      toast.error("Error deleting address: " + error.message);
    }
  };

  const handleEditAddress = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");
      console.log('Updating address with ID:', isEditing);
      console.log('Edit form data:', editFormData);
      const formData = new FormData();
      formData.append("id", isEditing); 
      formData.append("name", editFormData.name);
      formData.append("type", editFormData.type);
      formData.append("address", editFormData.address);
      formData.append("city", editFormData.city);
      formData.append("state", editFormData.state);
      formData.append("country", editFormData.country);
      formData.append("pincode", editFormData.pincode);
      formData.append("mobile", editFormData.mobile);
      await axios.post(
        url + "/app/v1/api/update_address",
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('Address updated:', isEditing);
      toast.success("Address updated successfully!");
      setIsEditing(null); // Close edit form
      getAddress(); // Refresh addresses after updating
    } catch (error) {
      console.error("Error updating address:", error);
      toast.error("Error updating address: " + error.message);
    }
  };

  const handleEditClick = (address) => {
    console.log('Editing address:', address);
    setIsEditing(address.id);
    setEditFormData(address);
  };

  const handleInputChange = (e) => {
    setEditFormData({
      ...editFormData,
      [e.target.name]: e.target.value,
    });
  };

  if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex items-center justify-center">
      <div className="relative bg-white w-full max-w-md m-auto flex-col flex rounded-lg">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-semibold">Shipping Address</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={20} />
          </button>
        </div>
        {isLoading ? (
          <div className="p-4">Loading...</div>
        ) : (
          <div className="p-4">
            {addresses.length === 0 ? (
              <p>No addresses found.</p>
            ) : (
              addresses.map((address) => (
                <div
                  key={address.id}
                  className="flex items-center p-2 cursor-pointer hover:bg-gray-100 justify-between"
                >
                  <div className="flex-grow text-left" onClick={() => handleSelectAddress(address)}>
                    <p className="font-semibold">
                      {address.name} - {address.type}
                    </p>
                    <p className="text-sm text-gray-600">
                      {address.address}, {address.city}, {address.state}, {address.country} -{" "}
                      {address.pincode}
                    </p>
                    <p className="text-sm text-gray-600">{address.mobile}</p>
                  </div>
                  <div className="flex items-center">
                    <button
                      className="mr-2 p-2 text-blue-500"
                      onClick={() => handleEditClick(address)}
                    >
                      <Edit3 size={20} />
                    </button>
                    <button
                      className="p-2 text-red-500"
                      onClick={() => handleDeleteAddress(address.id)}
                    >
                      <Trash2 size={20} />
                    </button>
                    <ChevronRight size={20} className="text-gray-400" />
                  </div>
                </div>
              ))
            )}
          </div>
        )}
        {isEditing && (
          <div className="p-4">
            <h3>Edit Address</h3>
            <form onSubmit={handleEditAddress}>
              <input
                name="name"
                value={editFormData.name}
                onChange={handleInputChange}
                className="w-full p-2 border rounded mb-2"
                placeholder="Name"
              />
              <input
                name="type"
                value={editFormData.type}
                onChange={handleInputChange}
                className="w-full p-2 border rounded mb-2"
                placeholder="Type (e.g., Home, Office)"
              />
              {/* Add more input fields for address, city, etc., similarly */}
              <button
                type="submit"
                className="w-full py-2 px-4 bg-blue-600 text-white rounded-lg"
              >
                Update Address
              </button>
            </form>
          </div>
        )}
        <div className="p-4 border-t">
          {/* Add the button for creating a new address here */}
        </div>
      </div>
    </div>
  );
};

export default SellerEditAddressModal;
