import React, { useContext, useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import UserContext from "../../context/UserContext";

import { url } from "../../components/api/Url";
import axios from "axios";

const Seller_banner = ({ images, sellerid }) => {
  const [data, setData] = useState();

  const {
    sellerListdata,
    setSellerListdata,
    loader,
    error,
    setLoader,
    setError,
  } = useContext(UserContext);

  async function sellerList() {
    try {
      let response = await axios.post(url + "/app/v1/api/seller_list?id=" + sellerid);
      let res = await response.data;

      const data = res[0]?.json_component;
      const Jsonres = JSON.parse(data);
      setSellerListdata(res[0]);

      setData(Jsonres);
      setLoader(false);
      setError(false);
    } catch (error) {
      setError(true);
      console.log("ERROR MESSAGE :: ", error.message);
    }
  }

  useEffect(() => {
    sellerList();
  }, []);

  return (
    <div className="text-left font-bold text-[22px]  mt-5">
      {data?.component?.image_gallery?.show_title === "on" && (
        <h1 className="text-black mx-auto text-2xl flex flex-col font-semibold items-center justify-center">
            <div>  {data?.component?.image_gallery?.title}</div>
          <div className="w-[100px] h-[3px] mb-[20px] mt-3 bg-black rounded-full"></div>
        </h1>
      )}
  <div className="mt-5 aspect-[2/1] min-h-[500px] h-auto md:w-full md:mx-0 mx-auto w-[360px]">


        <Swiper
          navigation={true}
          modules={[Pagination, Navigation, Autoplay]}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          pagination={{
            clickable: true,
            el: ".swiper-pagination",
            bulletClass: "swiper-pagination-bullet",
            bulletActiveClass: "swiper-pagination-bullet-active",
            modifierClass: "swiper-pagination-",
          }}
          onSwiper={(swiper) => {
            // Style navigation buttons inline
            const nextButton = swiper.navigation.nextEl;
            const prevButton = swiper.navigation.prevEl;

            if (nextButton && prevButton) {
              const arrowStyles = {
                color: "rgba(255, 255, 255, 0.7)",
                width: "40px",
                height: "40px",
                transition: "opacity 0.3s ease",
              };

              Object.assign(nextButton.style, arrowStyles);
              Object.assign(prevButton.style, arrowStyles);

              // Add hover effect
              nextButton.onmouseover = () => {
                nextButton.style.opacity = "1";
              };
              nextButton.onmouseleave = () => {
                nextButton.style.opacity = "0.7";
              };

              prevButton.onmouseover = () => {
                prevButton.style.opacity = "1";
              };
              prevButton.onmouseleave = () => {
                prevButton.style.opacity = "0.7";
              };
            }
          }}
          className="rounded-[30px]"
        >
          {images?.map((item, index) => (
            <SwiperSlide key={index}>
              <img
                src={`${item.file_name}`}
                alt=""
                // className="object-contain md:w-36 h-20 w-20 md:h-36"
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Seller_banner;
