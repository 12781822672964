import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaRegHeart, FaTrash, FaTimes } from 'react-icons/fa';
import { url } from '../api/Url'; // Assuming you have an api/Url.js file with the URL
// import Load from '../assets/loading-icon.svg'; // Make sure to update this path if necessary

function FavoritesModal({ isOpen, onClose }) {
  const [favorites, setFavorites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFavoritesOpen, setIsFavoritesOpen] = useState(false);
  const [isLoadingOverlay, setIsLoadingOverlay] = useState(false);

  const modalRef = useRef(null);

  const fetchFavorites = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('token');
      const userid = localStorage.getItem('userid');
      
      if (!token || !userid) {
        throw new Error('Token or user ID not found. Please log in again.');
      }

      const formData = new FormData();
      formData.append('user_id', userid);
      formData.append('limit', '10');
      formData.append('offset', '0');

      const response = await axios.post(
        'https://zulushop.in/app/v1/api/get_favorites',
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data && Array.isArray(response.data.data)) {
        setFavorites(response.data.data);
      } else {
        throw new Error(response.data.message || 'Invalid response format from the server.');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError(error.message || 'An error occurred while fetching favorites.');
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleFavorite = async (product_id, isFavorite) => {
    const token = localStorage.getItem('token');
    const user_id = localStorage.getItem('userid');

    if (!token || !user_id) {
      toast.error('Token or user ID not found. Please log in again.');
      return;
    }

    const url = isFavorite
      ? 'https://zulushop.in/app/v1/api/add_to_favorites'
      : 'https://zulushop.in/app/v1/api/remove_from_favorites';

    try {
      const formData = new FormData();
      formData.append('user_id', user_id);
      formData.append('product_id', product_id);

      await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });

      toast.success(isFavorite ? 'Product added to favorites!' : 'Product removed from favorites!');

      // Update local state to remove or add the item
      if (isFavorite) {
        setFavorites(prevFavorites => [...prevFavorites, { id: product_id }]);
      } else {
        setFavorites(prevFavorites => prevFavorites.filter(item => item.id !== product_id));
      }

      // Update favorite state in local storage
      const favorites = JSON.parse(localStorage.getItem('favorites')) || {};
      if (isFavorite) {
        favorites[product_id] = true;
      } else {
        delete favorites[product_id];
      }
      localStorage.setItem('favorites', JSON.stringify(favorites));
    } catch (error) {
      toast.error(isFavorite ? 'Failed to add product to favorites.' : 'Failed to remove product from favorites.');
    }
  };

  const handleViewDetails = (id) => {
    setIsLoadingOverlay(true);
    setTimeout(() => {
      window.location.href = `/o/${localStorage.getItem("seller_slug")}/${id}/product` //`/productdetail/${id}`; // to={`/o/${sellerslug}/${product.id}/product`}
    }, 100); // Small delay to ensure the overlay is visible
  };

  useEffect(() => {
    if (isOpen) fetchFavorites();
  }, [fetchFavorites, isOpen]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-40">
      {/* Modal Background */}
      <div className="fixed inset-0 bg-black opacity-70" onClick={onClose}></div>

      {/* Modal Content */}
      <div
        ref={modalRef}
        className="fixed inset-y-0 right-0 w-full sm:w-96 bg-white shadow-lg transform translate-x-0 transition-transform duration-300 ease-in-out z-50"
      >
        <div className="h-full flex flex-col">
          {/* Header */}
          <div className="flex justify-between items-center p-4 border-b">
            <h2 className="text-xl font-bold items-center flex">
              <FaRegHeart className="mr-2" /> Favorites
            </h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <FaTimes size={24} />
            </button>
          </div>

          {/* Favorites Content */}
          <div className="flex-grow overflow-y-auto p-4 text-sm">
            {isLoading ? (
              <div className="text-center mt-8">Loading favorites...</div>
            ) : error ? (
              <div className="text-center mt-8 text-red-500">Error: {error}</div>
            ) : favorites.length === 0 ? (
              <p className="text-center text-gray-600">You don't have any favorite products yet.</p>
            ) : (
              <div className="grid grid-cols-1 gap-6">
                {favorites.map((favorite) => (
                  <div key={favorite.id} className="border rounded-lg p-4 flex shadow-sm hover:shadow-md transition-shadow duration-300">
                    <img src={`${url}/${favorite.image}`} alt={favorite.name} className="w-16 h-24 object-cover mb-3 rounded" />
                    <div className="flex-col w-full">
                      <h3 className="font-semibold text-sm text-left ml-2 mb-2">{favorite.name}</h3>
                      <p className="text-gray-600 text-xs text-left mb-2 ml-2">{favorite.short_description}</p>
                      <button 
                        onClick={() => handleViewDetails(favorite.id)} 
                        className="text-white p-2 rounded transition-colors duration-300 mt-2 flex items-center bg-blue-400 text-xs ml-2"
                      >
                        View Details
                      </button>
                      <button 
                        onClick={() => handleFavorite(favorite.id, false)} 
                        className="text-white p-2 rounded transition-colors duration-300 mt-2 flex items-center"
                      >
                        <FaTrash className="text-red-600" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <ToastContainer />
      </div>
{/* 
      {isLoadingOverlay && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
          <img src={Load} alt="Loading..." />
        </div>
      )} */}
    </div>
  );
}

export default FavoritesModal;
