import React, { useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import loaderGif from './loader.gif';
import { url } from "../api/Url";
import axios from "axios";

const tripo3dApiKey = "tsk_tZd2LyKRAsbjFSrH8PKHLz3rZsue8wJAzFyKqvJzoqt";
const tripo3dBaseUrl = "https://api.tripo3d.ai/v2/openapi";

const Model3D2 = ({ queryParams }) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [models, setModels] = useState({});
  const sceneRefs = useRef([]);

  const getVideos = async () => {
    try {
      const response = await axios.post(`${url}/get_all_videos`, queryParams);
      const videoData = response.data.message;
      const unique3DModelVideos = videoData.filter(video => video.video_type === "3dModel");
      setVideos(unique3DModelVideos);
      setLoading(false);
    } catch (err) {
      setError(err.response?.data?.message || "Failed to load videos");
      setLoading(false);
    }
  };

  const getModelFromTripo3D = async (taskId, videoId) => {
    try {
      const response = await axios.get(`${tripo3dBaseUrl}/task/${taskId}`, {
        headers: {
          'Authorization': `Bearer ${tripo3dApiKey}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (response.data.code === 0) {
        const modelUrl = response.data.data.output.model;
        setModels(prevModels => ({
          ...prevModels,
          [videoId]: modelUrl,
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (err) {
      console.error(`Error fetching model for videoId ${videoId}:`, err);
    }
  };

  useEffect(() => {
    getVideos();
  }, [queryParams]);

  useEffect(() => {
    if (videos.length > 0) {
      videos.forEach(video => getModelFromTripo3D(video.weblink, video.id));
    }
  }, [videos]);

  const renderModel = (modelUrl, container) => {
    if (!container || !modelUrl) return;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(container.clientWidth, container.clientHeight);
    container.innerHTML = "";
    container.appendChild(renderer.domElement);

    const loader = new GLTFLoader();
    loader.load(
      modelUrl,
      (gltf) => {
        scene.add(gltf.scene);
        camera.position.z = 5;
        animate();
      },
      undefined,
      (error) => console.error("Error loading model:", error)
    );

    const animate = () => {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    };
  };

  useEffect(() => {
    videos.forEach((video, index) => {
      if (models[video.id]) {
        const container = sceneRefs.current[index];
        renderModel(models[video.id], container);
      }
    });
  }, [models, videos]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">Error: {error}</div>;
  }

  return (
    <div className="mt-10">
      <div className="text-center mx-auto mb-6">
        <div className="text-2xl font-semibold">Experience VR</div>
        <div className="w-28 h-1 bg-white mx-auto mt-2 rounded"></div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {videos.slice(0, 4).length > 0 ? (
          videos.slice(0, 4).map((video, index) => (
            <div key={video.id} className="bg-gray-100 p-3 rounded shadow-md relative">
              <div
                ref={(el) => (sceneRefs.current[index] = el)}
                className="relative w-full h-40 bg-gray-300 flex items-center justify-center rounded-md mb-2"
              >
                {!models[video.id] && <img src={loaderGif} alt="Loading..." className="w-12 h-12" />}
              </div>
              <h2 className="font-semibold text-sm">{video.video_name || `Video`}</h2>
              {video.video_description && (
                <p className="text-sm text-gray-600">{video.video_description}</p>
              )}
            </div>
          ))
        ) : (
          <p>No videos available</p>
        )}
      </div>
    </div>
  );
};

export default Model3D2;
