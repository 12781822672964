import React from "react";
import { url } from "../../components/api/Url";
import { Link } from "react-router-dom";

const Seller_threed_card = ({ brand_name, brand_img }) => {
  return (
    <Link to={`/sellerproduct/${brand_name}`} className="text-black border-2 px-2 py-2 rounded-2xl ">
    <div className="max-w-[200px] justify-center flex flex-col items-center max-h-[130px]] ">
      {
        brand_img ?
          <>
            <img src={`${url}/${brand_img}`} alt="" className="w-full h-full p-4 max-h-[100px] mix-blend-color-burn  rounded-lg" />
          </>
          :
          <>
            <img src={`${url}/${brand_img}`} alt="" className="size-[70px] rounded-lg " />
          </>
      }
      <p className="text-xs">
        {
       brand_name
        }
      </p>
    </div>
    </Link>
  );
};

export default Seller_threed_card;
