import React from 'react'

export default function Playstore() {
  return (
    <div style={{padding: 50}}>
<p className="demoTitle">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
<p className="p1 p-4">Last updated: 1 Nov 2024</p>
<p className="p1 p-4">1. Introduction</p>
<p className="p1 p-4">This Privacy Policy explains how Zulu Consumer collects, uses, discloses, and protects information through our e-commerce application. By using our application, you agree to the collection and use of information in accordance with this Privacy Policy.</p>
<p className="p1 p-4">2. Information We Collect</p>
<p className="p1 p-4">&bull; Personal Information: When you register, we may collect information such as your name, email address, phone number, and shipping address.</p>
<p className="p1 p-4">&bull; Payment Information: We use third-party services to process payments. We do not store your payment details, but we may store transaction history.</p>
<p className="p1 p-4">&bull; Location Information: With your permission, we may collect location data to provide location-based services.</p>
<p className="p1 p-4">&bull; Device Information: We may collect data about the device you use to access our app, including IP address, device ID, and app usage statistics.</p>
<p className="p1 p-4">3. How We Use Your Information</p>
<p className="p1 p-4">We use the collected information to:</p>
<p className="p1 p-4">&bull; Process and manage your orders</p>
<p className="p1 p-4">&bull; Communicate with you about your orders, offers, and promotions</p>
<p className="p1 p-4">&bull; Improve our services and app performance</p>
<p className="p1 p-4">&bull; Personalize your experience within the app</p>
<p className="p1 p-4">&bull; Prevent fraudulent activity and enhance security</p>
<p className="p1 p-4">4. Sharing Your Information</p>
<p className="p1 p-4">We may share your information with:</p>
<p className="p1 p-4">&bull; Service Providers: Third-party vendors who assist us in processing orders, payments, and delivery.</p>
<p className="p1 p-4">&bull; Legal Requirements: In response to legal requests or when required by law to protect the rights and safety of our users and the public.</p>
<p className="p1 p-4">5. Data Security</p>
<p className="p1 p-4">We use industry-standard encryption and secure storage methods to protect your data. However, no transmission over the internet is entirely secure, so we cannot guarantee absolute security.</p>
<p className="p1 p-4">6. Children&rsquo;s Privacy</p>
<p className="p1 p-4">Our app is not directed toward individuals under the age of 13, and we do not knowingly collect data from children.</p>
<p className="p1 p-4">7. Changes to This Privacy Policy</p>
<p className="p1 p-4">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy in the app.</p>
<p className="p1 p-4">8. Contact Us</p>
<p className="p1 p-4">If you have any questions about this Privacy Policy, please contact us zuluclubindia@gmail.com.</p>
<p>&nbsp;</p>
    </div>
  )
}
