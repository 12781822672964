import React from "react";
import { Link } from "react-router-dom";
import { url } from "../../components/api/Url";

const CurateCard = ({ image, title, short_description, price, discount, width, featureid }) => {
  const sellerid = localStorage.getItem('seller_id');
  const sellerslug = localStorage.getItem('seller_slug');
  return (
    <Link to={`/o/${sellerslug}/${featureid}/features-section`} className=""> {/* o/:id/:ids/features-section */}
    <div
      className={`relative rounded-2xl overflow-hidden h-auto aspect-[16/9] w-[${width}] shadow-lg`}
    
    >
      {/* Image Section */}
      <div className="relative h-[100%] w-full rounded-xl overflow-hidden">
      
        <img
          src={`${url}/uploads/seller/${image}`}
          className="w-full h-auto object-cover rounded-lg"
          alt="Cute baby dresses"
        />
      
        {/* Gradient Fade at the Bottom */}
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-gray-900 to-transparent"></div>
      </div>

      {/* Text Section */}
      <div className="absolute bottom-[5%] left-0 w-[250px] text-left text-sm p-4 bg-transparent  text-white">
        <p className="text-base font-semibold">{title}</p>
        <p className="text-xs">{short_description}</p>
      </div>

      {/* Explore Button */}
      <Link to={`/o/${sellerslug}/${featureid}/features-section`}>
        <button
          className="absolute bottom-[10%] right-2 px-4 py-3 rounded-xl text-white border-2 text-xs border-white bg-transparent"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
        >
          EXPLORE
        </button>
      </Link>
    </div>
    </Link>
  );
};

export default CurateCard;
