import React, { useState, useEffect } from "react";
import { FaCaretDown } from "react-icons/fa6";
import axios from "axios";

const LocationDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("Delivering to");
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.post("https://zulushop.in/app/v1/api/get_cities");
        setCities(response.data.data || []);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching cities:", error);
        setIsLoading(false);
      }
    };

    fetchCities();
  }, []);

  useEffect(() => {
    const savedLocation = localStorage.getItem("selectedLocation");
    if (savedLocation) {
      setSelectedLocation(savedLocation);
    }
  }, []);

  const handleLocationClick = (location) => {
    setSelectedLocation(location);
    setShowDropdown(false);
    localStorage.setItem("selectedLocation", location);
  };

  const allOptions = [{ id: 'default', name: 'Delivering to' }, ...cities];

  return (
    <div className="relative w-full">
      <div
        className="w-full flex ml-2 text-sm text-white items-center cursor-pointer"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {isLoading ? "Loading..." : selectedLocation}
        <FaCaretDown className="ml-1" />
      </div>

      {showDropdown && !isLoading && (
        <ul className="absolute mt-2 w-full bg-white text-black shadow-lg rounded-lg z-10">
          {allOptions.map((option) => (
            <li
              key={option.id}
              className="px-4 py-2 hover:bg-gray-200 cursor-pointer"
              onClick={() => handleLocationClick(option.name)}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LocationDropdown;