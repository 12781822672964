import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Import CSS for styling
import moment from 'moment';
import axios from 'axios';
import { url } from '../../components/api/Url';
import Spinner from '../../components/Spinner/Spinner';
import BookingModal from './BookingModal';

const Booking = () => {
    const today = moment(); // Get the current date
    const todaydate = today.format('YYYY-MM-DD');
    const [date, setDate] = useState(todaydate);
    const [isCalendarOpen, setCalendarOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [bookingdata, setBookingdata] = useState([]);
    const [isbookingmodel, setIsbookingmodel] = useState(false);
    const [bookingid, setBookingid] = useState('');
    const [childid, setChildid] = useState('');


    const handleDateChange = async (newDate) => {
        // Format the date to YYYY-MM-DD
        const year = newDate.getFullYear();
        const month = String(newDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(newDate.getDate()).padStart(2, '0');
        let d = `${year}-${month}-${day}`;
        setDate(`${year}-${month}-${day}`);

        //start to finding form api
        try {
            let response = await axios.post(url + "/showcurratedate/" + d);
            // let response = await axios.post("http://localhost/Zulu-Backend/showcurratedate/" + d);
            let res = response.data;
            console.log('booking..' + JSON.stringify(response.data.slots))
            setBookingdata(response.data.slots)
        } catch (error) {
            //SSsetError(true);
            setBookingdata('')
            console.log("ERROR MESSAGE :: ", error.message);
        }

        //end
    };

    const toggleCalendar = () => {
        setCalendarOpen((prev) => !prev);
    };
    const handleBooking = async (id, date, bookingid) => {

        setIsbookingmodel(true);
        setBookingid(bookingid);
        setChildid(id);

    };

    const reloadfunction = async () => {
        try {
            //http://localhost/Zulu-Backend/showcurratedate/" + d
            let response = await axios.post(url + "/showcurratedate/" + date);
            // let response = await axios.post("http://localhost/Zulu-Backend/showcurratedate/" + date);
            let res = response.data;
            console.log('booking..' + JSON.stringify(response.data.message))
            setBookingdata(response.data.message)
        } catch (error) {
            //SSsetError(true);
            setBookingdata('')
            console.log("ERROR MESSAGE :: ", error.message);
        }
    }


    return (
        <>
            <div className='flex items-center justify-center'>
                <Calendar
                    onChange={handleDateChange}
                    value={date}
                />
            </div>
            <div className="flex justify-center items-center">
                <div className="w-3 h-3 bg-green-500 rounded-full flex items-center justify-center" style={{
                    marginRight: 4
                }}>
                    <span className="text-white font-bold"></span>
                </div>
                Availiable
                <div className="w-3 h-3 bg-red-500 rounded-full flex items-center justify-center" style={{
                    marginRight: 4, marginLeft: 10
                }}>
                    <span className="text-white font-bold"></span>
                </div>
                Booked
            </div>
            <div className='flex items-center justify-center mt-2'>

                <p>Selected Date:  {date}</p>
            </div>
            <div className='flex items-center justify-center mt-10'>

                <div className="flex space-x-4">
                    {
                        bookingdata?.length > 0 ?
                            bookingdata && bookingdata?.map((item, index) => (
                                <div key={index}>
                                    {

                                        item.user_booking_info[0]?.user_id == localStorage.getItem("userid") ?
                                            <button className="bg-red-500 hover:bg-red-700
                             text-white font-bold py-2 px-4 rounded" disabled>
                                                {item.start_time}
                                            </button>
                                            :
                                            <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                                onClick={() => handleBooking(item.childid, item.booking_date, item.booking_id)}
                                            >
                                                {item.start_time}
                                            </button>


                                    }


                                </div>
                            ))
                            :
                            ''
                    }
                </div>
            </div>
            <BookingModal show={isbookingmodel}
                bookingid={bookingid} childid={childid}
                bookingdate={date}
                /*         onSelectPromo={(item) => {
                         console.log('ddddd....' + item.discount)
                          setPromoCode(item.promo_code); 
                          setPromoDiscount(item.discount); 
                        }} */
                onClose={() => setIsbookingmodel(false)} />
        </>
    );
};

export default Booking;
