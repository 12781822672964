import React from "react";
import { Link } from "react-router-dom";
import { url } from "../api/Url";

const Card3 = ({ image, title, short_description, price, discount, width }) => {
  return (
    <div
      className={`relative rounded-2xl overflow-hidden w-[${width}] shadow-lg`}
      style={{
        height: "300px",
        borderRadius: "20px", // Extra rounded corners
      }}
    >
      {/* Image Section */}
      <div className="relative h-[75%] w-full rounded-xl overflow-hidden">
        <img
          src={`${url}/${image}`}
          className="w-full h-full object-cover rounded-lg"
          alt="Cute baby dresses"
        />
        {/* Gradient Fade at the Bottom */}
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-gray-900 to-transparent"></div>
      </div>

      {/* Text Section */}
      <div className="absolute bottom-20 left-0 w-[250px] text-left text-sm p-4 bg-transparent  text-white">
        <p className="text-base font-semibold">{title}</p>
        <p className="text-xs">{short_description}</p>
      </div>

      {/* Explore Button */}
      {/* <Link to="/productpage">
        <button
          className="absolute bottom-20 right-2 px-2 py-1 rounded-xl text-white border-2 text-xs border-white bg-transparent"
          style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
        >
          Explore
        </button>
      </Link> */}
    </div>
  );
};

export default Card3;
