import { ChevronRight } from "lucide-react";
import React from "react";

const Card2 = ({ zulu_category_name, seller_category_name, zulu_category_image, seller_category_image, onImageLoad }) => {
  return (
    <div className="relative w-[670px] cursor-pointer mb-4">
      {
        seller_category_image ?
          <img 
            src={`${seller_category_image}`} 
            alt="" 
            className="w-full h-[500px] object-cover bg-gray-200 rounded-[30px]" 
            onLoad={onImageLoad}
          />
          :
          <img 
            src={`${zulu_category_image}`} 
            alt="" 
            className="w-full h-[500px] object-cover bg-gray-200 rounded-[30px]" 
            onLoad={onImageLoad}
          />
      }
      <div className="absolute bottom-0 w-full bg-gradient-to-t from-black via-black/60 to-transparent text-white text-center py-8 rounded-b-[30px] flex flex-col items-center">
        <p className="text-2xl font-bold mb-4 flex items-center">
          {seller_category_name ? seller_category_name : zulu_category_name} <ChevronRight  className="ml-1 font-bold" />
        </p>
        <div className="flex flex-wrap justify-center gap-4 mb-4">
          {[1, 2, 3, 4, 5].map((item, index) => (
            <button
              key={index}
              className="bg-transparent border border-white text-white px-4 py-2 rounded-full text-sm hover:bg-white hover:text-black transition"
            >
              {`Box ${item}`}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Card2;