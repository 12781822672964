import React, { useEffect, useState } from 'react';
import ProfileMenu from '../../components/profile/ProfileMenu';
import ViewProfile from '../../components/profile/ViewProfile';
import axios from 'axios';
import Spinner from '../../components/Spinner/Spinner';
import FavoritesPage from '../../components/profile/FavoritesPage';
import ViewOrder from '../../components/checkout/ViewOrder';
import LoyaltyPage from '../../components/profile/LoyaltyPage'; // Import the LoyaltyPage component
import HelpCenterPage from '../../components/profile/HelpCenterPage';
import Address from '../../components/profile/Address';
import Wallet from '../../components/profile/Wallet';

const Profilepage = () => {
  const [profiledata, setProfiledata] = useState([]);
  const [username, setUsername] = useState('');
  const [image, setImage] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [isloading, setIsloading] = useState(true);
  const [showFavorites, setShowFavorites] = useState(false);
  const [showProfile, setShowProfile] = useState(true);
  const [showOrders, setShowOrders] = useState(false);
  const [showLoyalty, setShowLoyalty] = useState(false);
  const [showHelpCenter, setShowHelpCenter] = useState(false);
  const [showAddress, setShowAddress] = useState(false);
  const [showWallet, setShowWallet] = useState(false);

  const getProfile = async () => {
    setIsloading(true);
    const json = JSON.stringify({ mobileno: localStorage.getItem('mobileno') });
    try {
      const res = await axios.post("https://zulushop.in/app/v1/api/user_profile", json, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setUsername(res.data.data[0].username);
      setEmail(res.data.data[0].email);
      setMobile(res.data.data[0].mobile);
      setIsloading(false);
    } catch (error) {
      console.error('Error fetching profile:', error);
      setIsloading(false);
    }
  };

  const handleFormSubmit = (updatedUser) => {
    console.log('Updated User Data:', updatedUser);
    // Handle form submission, e.g., send it to an API
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handleShowFavorites = () => {
    setShowFavorites(true);
    setShowProfile(false);
    setShowOrders(false);
    setShowLoyalty(false);
    setShowAddress(false);
    setShowWallet(false);
  };

  const handleShowOrders = () => {
    setShowFavorites(false);
    setShowProfile(false);
    setShowOrders(true);
    setShowAddress(false);
    setShowLoyalty(false);
    setShowWallet(false);
  };

  const handleShowProfile = () => {
    setShowFavorites(false);
    setShowProfile(true);
    setShowOrders(false);
    setShowLoyalty(false);
    setShowAddress(false);
    setShowWallet(false);
  };

  const handleShowLoyalty = () => { 
    setShowFavorites(false);
    setShowProfile(false);
    setShowWallet(false);
    setShowOrders(false);
    setShowLoyalty(true);
    setShowAddress(false);
  };

  const handleShowHelpCenter = () => { 
    setShowFavorites(false);
    setShowProfile(false);
    setShowOrders(false);
    setShowLoyalty(false);
    setShowHelpCenter(true);
    setShowAddress(false);
    setShowWallet(false);
  };

  const handleShowAddress = () => { 
    setShowFavorites(false);
    setShowProfile(false);
    setShowOrders(false);
    setShowLoyalty(false);
    setShowHelpCenter(false);
    setShowAddress(true);
    setShowWallet(false);
  };

  const handleShowWallet = () => { 
    setShowFavorites(false);
    setShowProfile(false);
    setShowOrders(false);
    setShowLoyalty(false);
    setShowHelpCenter(false);
    setShowAddress(false);
    setShowWallet(true);
  };
  

  return (
    <>
      {isloading ? (
        <Spinner />
      ) : (
        <>
          <ProfileMenu 
            onShowFavorites={handleShowFavorites} 
            onShowProfile={handleShowProfile} 
            onShowOrders={handleShowOrders}
            onShowLoyalty={handleShowLoyalty} 
            onShowHelpCenter={handleShowHelpCenter} 
            onShowAddress={handleShowAddress} 
            onShowWallet={handleShowWallet} 
          />
          {showFavorites ? (
            <FavoritesPage />
          ) : showProfile ? (
            <ViewProfile 
              username={username}
              email={email} 
              userimage={image} 
              mobile={mobile}
              onSubmit={handleFormSubmit} 
            />
          ) : showOrders ? (
            <ViewOrder />
          ) : showLoyalty ? (
            <LoyaltyPage />
          ) : showHelpCenter ? ( 
            <HelpCenterPage />
          ) : showAddress ? ( 
            <Address />
          ) : showWallet ? ( 
          <Wallet />
        ) : null
          }
        </>
      )}
    </>
  );
};

export default Profilepage;
