import React, {useState, useEffect} from 'react'
import { url } from "../api/Url";
import { ToastContainer, toast } from 'react-toastify';
import Spinner from "../Spinner/Spinner";
import axios from 'axios';

export default function Deplaystore() {
    
    const [isotpscreen, setIsotpscreen] = useState(false)
    const [timer, setTimer] = useState(120); // 60 seconds timer
    const [isResendEnabled, setIsResendEnabled] = useState(false);
    const [islogin, setIslogin] = useState(true)
    const [isloading, setIsloading] = useState('');
    const [visible, setVisible] = useState(true);
    const [mobile, setMobile] = useState('');
    const [otp, setOtp] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);


    const manual_login_for_development = async () => {
      setIsLoggedIn(false)
      setIsloading(true)
      //return
      const datafrom = new FormData();
      datafrom.append('mobile', localStorage.getItem('tem_mobileno')); //localStorage.getItem('tem_mobileno') localStorage.getItem('tem_mobileno')
      //alert(JSON.stringify(datafrom));
      console.log(datafrom);
      try {
        const response = await fetch(url + '/app/v1/api/user_delete_mobile', { //app/v1/api/sign_up_with_mobile
          method: 'POST',
          body: datafrom
        });
        //alert(response);
        if (!response.ok) {
          toast('Network response was not ok')
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('data list ' + data)
            console.log('Success:', data);
            toast(data.message);
           // location.reload();
           window.location.href = '/deletesuccess';
      } catch (error) {
        toast(error)
        // window.location.href = '/o';
        //setIsloading(false)
      }
      //setIsloading(false)
  
    }
  


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true);
        const response = await fetch('https://cpaas.messagecentral.com/auth/v1/authentication/token?customerId=C-659CA72D61C94C9&key=QWRhcnNoMTIzIQ==&scope=NEW&country=91&email=adarsh.bhatia@gmail.com', {
        });
    
        const data = await response.json();
        let token = data.token;
        localStorage.setItem('token', token);
        fetch("https://cpaas.messagecentral.com/verification/v3/send?countryCode=91&flowType=SMS&mobileNumber=" + mobile, { ///api/login
          method: 'POST',
          headers: {
            'authToken': `${token}`,
          },
          body: ''
        })
          .then((response) => response.json())
          .then((response) => {
            setIsloading(false);
         //   console.log('sms' + JSON.stringify(response));
            if (response.message == "SUCCESS") {
              localStorage.setItem("verificationId", response.data.verificationId);
              localStorage.setItem("tem_mobileno", mobile);
              // let code = response.verificationId;
              console.log('verification id : ' + response.data.verificationId);
              setIslogin(false);
              setIsotpscreen(true);
    
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
    
    
      };

      const handleSubmitotp = async (e) => {
        e.preventDefault();
        // alert(otp);
        setIsloading(true)
        if (otp == "") {
          toast('Insert the OTP')
          setIsloading(false)
        }
        else { //`/results?${queryParams}`
          let verification = localStorage.getItem('verificationId');
          let sendurl = `https://cpaas.messagecentral.com/verification/v3/validateOtp?verificationId=${verification}&code=${otp}`;
    
          fetch(sendurl, { ///api/login
            // method: 'GET',
            headers: {
              'authToken': `${localStorage.getItem('token')}`,
            },
    
          })
            .then((response) => response.json())
            .then((response) => {
              setIsloading(false);
              console.log('verification response' + JSON.stringify(response));
              if (response.message == "SUCCESS" && response.data.verificationStatus == "VERIFICATION_COMPLETED") {
      
               manual_login_for_development();

//////////////////////////////////////////////////
                //end db checking
              }
              /* {"responseCode":505,"message":"verificationId is invalid","data":null} */
              else if (response.message == "verificationId is invalid") {
                // alert('Invalid SMS Code');
                toast("Invalid, Invalid SMS Code!");
              }
            })
            .catch((err) => {
              console.log(err.message);
            });
    
        }
    
      }
      const handleSubmitotp_test = async () => {
        setIsLoggedIn(false)
        setIsloading(true)
        //return
        alert(localStorage.getItem('tem_mobileno'));
        setIsloading(false)
        return
      }



      const handleResendOtp = () => {

      }
    return (
        <div style={{ padding: 50 }}>
  {islogin ? (
                        <form onSubmit={handleSubmit} className="space-y-6">
                          <div className="text-left">
                            <label htmlFor="phone-input" className="block text-sm font-medium text-gray-700 ">
                              Phone Number
                            </label>
                            <div className="relative mt-1">
                              <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500 ">
                                <svg
                                  className="w-4 h-6"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 19 18"
                                >
                                  <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                                </svg>
                              </span>
                              <input
                                type="text"
                                id="phone-input"
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                placeholder="Mobile No"
                                required
                                className="block w-full pl-10 pr-3 py-2 border rounded-lg text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                              />
                            </div>
                          </div>
                          <div>
                            <button
                              type="submit"
                              className={`w-full py-2 rounded-lg text-white transition duration-300 ${isloading ? 'bg-gray-400' : 'bg-gray-500 hover:bg-gray-600'}`}
                              disabled={isloading}
                            >
                              {isloading ? (
                                <div className="flex justify-center items-center">
                                  <svg
                                    className="animate-spin h-5 w-5 text-white mr-3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    />
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    />
                                  </svg>
                                  {/* Loading... */}
                                </div>
                              ) : (
                                "Submit"
                              )}
                            </button>

                          </div>
                        </form>
                      ) : (
                        <form onSubmit={handleSubmitotp} className="space-y-6">
                          <div>
                            <label htmlFor="otp-input" className="block text-sm font-medium text-gray-700">
                              OTP
                            </label>
                            <div className="relative mt-1">
                              <span className="absolute inset-y-0 left-0 pl-3 flex items-center text-gray-500 ">
                                <svg
                                  className="w-4 h-4"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="currentColor"
                                  viewBox="0 0 19 18"
                                >
                                  <path d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z" />
                                </svg>
                              </span>
                              <input
                                type="text"
                                id="otp-input"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                placeholder="Enter OTP"
                                required
                                className="block w-full pl-10 pr-3 py-2 border rounded-lg text-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 "
                              />
                            </div>
                          </div>
                          <div>
                            {isloading ? (
                              <div className="flex justify-center">
                                <Spinner />
                              </div>
                            ) : (
                              <button
                                type="submit"
                                className="w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300"
                              >
                                Verify OTP
                              </button>
                            )}
                          </div>
                          <div className="text-center mt-4">
                            {timer > 0 ? (
                              <p>Resend OTP in {timer} seconds</p>
                            ) : (
                              <button
                                onClick={handleResendOtp}
                                disabled={!isResendEnabled}
                                className={`bg-blue-500 text-white py-2 px-4 rounded-lg ${!isResendEnabled ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600 transition duration-300'}`}
                              >
                                Resend OTP
                              </button>
                            )}
                          </div>
                        </form>
                      )}

                     {/*    <button onClick={manual_login_for_development}
                          className="text-sm text-blue-500 hover:underline">
                          Manual Login (Development)
                        </button> */}
        </div>
    )
}
