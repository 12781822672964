import React, { useEffect, useState, useCallback, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useCategory } from "../../components/categaries_menu/CategoryContext";
import { useLoading } from "../../components/categaries_menu/LoadingContext";
import { ChevronRight, ChevronLeft } from "lucide-react"; // For left/right arrows
import { url } from "../../components/api/Url";
import CategorySlidercard from "./CategorySlidercard";

const SellerCategoriesMenu = () => {
  const [categoriesData, setCategoriesData] = useState([]);
  const [categoriesId, setCategoriesId] = useState([]);
  const { selectedCategory } = useCategory();
  const [isLocalLoading, setIsLocalLoading] = useState(true);
  const { setIsLoading } = useLoading();
  const [loadedImages, setLoadedImages] = useState(0);
  const scrollContainerRef = useRef(null); // Ref for the scrollable container
  const sellerid = localStorage.getItem('seller_id');
  
  let scrollAmount;

  if (window.innerWidth <= 768) {
    // Mobile screens
    scrollAmount = 380; 
  } else {
    // Large screens
    scrollAmount = 630; 
  }
  

  // Flatten categories recursively (main categories + subcategories)
  const flattenCategories = (categories) => {
    let flatList = [];
    categories.forEach((category) => {
      flatList.push(category);
      if (category.children && Array.isArray(category.children)) {
        flatList = flatList.concat(flattenCategories(category.children));
      }
    });
    return flatList;
  };

  // Fetch categories for the seller
  const getSellerCategories = async () => {
    const json = JSON.stringify({ seller_id: sellerid });
    try {
      const response = await axios.post(url + "/app/v1/api/seller_category_list", json);
      let res = response.data.message;

      // Flatten categories to include subcategories
      const flattenedCategories = flattenCategories(res);
      setCategoriesData(flattenedCategories);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching seller categories:", error);
      setCategoriesData([]);
      setIsLoading(false);
    }
  };

  // Fetch category IDs from web control API
  const getWebcontrolCategories = async () => {
    const webcontrolUrl = url + "/app/v1/api/list_of_webcontroll";
    try {
      const response = await axios.post(webcontrolUrl);
      const res = response.data;

      if (Array.isArray(res.message)) {
        let categoryIds = [];
        res.message.forEach((item) => {
          categoryIds = categoryIds.concat(item.categories_id.split(","));
        });
        setCategoriesId(categoryIds);
      }
    } catch (error) {
      console.error("Error fetching webcontrol categories:", error);
    }
  };

  // Image load handler to manage loading state
  const handleImageLoad = useCallback(() => {
    setLoadedImages((prev) => {
      const newCount = prev + 1;
      if (newCount === categoriesData.length) {
        setIsLocalLoading(false);
        setIsLoading(false);
      }
      return newCount;
    });
  }, [categoriesData.length, setIsLoading]);

  useEffect(() => {
    getWebcontrolCategories();
  }, []);

  useEffect(() => {
    if (categoriesId.length > 0 || selectedCategory) {
      getSellerCategories();
    }
  }, [categoriesId, selectedCategory]);

  // Left/Right navigation handlers for scrolling
  const handleNext = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  const handlePrev = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    }
  };

  return (
    <div className="mt-6">
      <div className="mt-3 relative">
        <div className="flex overflow-x-auto custom-scrollbar">
          {/* Left Arrow Button */}
          <button 
            onClick={handlePrev} 
            className="absolute left-0 top-1/2 transform -translate-y-1/2 cursor-pointer md:bg-transparent bg-black/50 text-white p-2 rounded-full z-10 hover:text-black hover:bg-white/50 transition"
          >
            <ChevronLeft size={50} />
          </button>

          <div 
            className="flex overflow-x-auto space-x-2 md:space-x-8  custom-scrollbar" 
            ref={scrollContainerRef}
          >
            {categoriesData.length > 0 ? (
              categoriesData.map((category) => (
                <Link
                  key={category.zulu_category_id}
                  to={`/sellerproduct/${category.zulu_category_id}`}
                  className="no-underline flex-shrink-0"
                >
                  <CategorySlidercard
                    className="p-1 "
                    zulu_category_name={category.zulu_category_name}
                    seller_category_name={category.seller_category_name}
                    zulu_category_image={category.zulu_category_image}
                    seller_category_image={category.seller_category_image}
                    zulu_category_id={category.zulu_category_id}
                    subcategories={category.children}
                    onImageLoad={handleImageLoad}
                   
                    style={{
                      width: '100%',
                    }}
                
                  />
                </Link>
              ))
            ) : (
              <p className="text-gray-500 text-lg animate-bounce flex">
                <div className="w-[650px] h-[500px] bg-gray-300 animate-pulse rounded-lg"></div>
                <div className="w-[650px] h-[500px] bg-gray-300 animate-pulse rounded-lg"></div>
                <div className="w-[650px] h-[500px] bg-gray-300 animate-pulse rounded-lg"></div>
              </p>
            )}
          </div>

          {/* Right Arrow Button */}
          <button 
            onClick={handleNext} 
            className="absolute right-0 top-1/2 transform -translate-y-1/2 text-white p-2 cursor-pointer rounded-full z-10 md:bg-transparent bg-black/50 hover:bg-white/50 hover:text-black transition"
          >
            <ChevronRight size={50} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SellerCategoriesMenu;
