import axios from "axios";
import React, { useEffect, useContext, useState } from "react";
import { FaTwitter, FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import { url } from "../../../components/api/Url";
import UserContext from "../../../context/UserContext";

const SellerFooter = () => {
  const sellerid = localStorage.getItem('seller_id');
  const sellerslug = localStorage.getItem('seller_slug');

  // Log the background data
  // console.log('Background Data:', {
  //   BG_type: data?.component?.top_bar_menu.BG_type,
  //   BG_Color1: data?.component?.top_bar_menu.BG_Color1,
  //   BG_Color2: data?.component?.top_bar_menu.BG_Color2,
  // });

  const {
    sellerListdata,
    setSellerListdata,
    loader,
    error,
    setLoader,
    setError,
  } = useContext(UserContext);

  const [data, setData] = useState()

  async function sellerList() {
    // const json = JSON.stringify({ seller_id: "85" });
    if (sellerid == "") {
      return
    }
    try {
      let response = await axios.post(url + "/app/v1/api/seller_list?id=" + sellerid);
      let res = await response.data;
      const data = res[0]?.json_component
      const Jsonres = JSON.parse(data)
      setSellerListdata(res[0]);
      setData(Jsonres)
      setLoader(false);
      setError(false);
    } catch (error) {
      setError(true);
      console.log("ERROR MESSAGE :: ", error.message);
    }
  }

  useEffect(() => {
    sellerList();
  }, []);

  // console.log(sellerListdata[0])
  const [color, setColor] = useState(sellerListdata.color_1);
  const [color2, setColor2] = useState(sellerListdata.color_2);

  return (
    <footer
      id="foot"
      className="text-center mt-4 text-white py-16"
      style={{
        backgroundImage: data?.component?.top_bar_menu.BG_type === 'Gradient'
          ? `linear-gradient(to right, ${data?.component?.top_bar_menu.BG_Color1}, ${data?.component?.top_bar_menu.BG_Color2})`
          : `none`,
        backgroundColor: data?.component?.top_bar_menu.BG_type === 'Colour'
          ? `${data?.component?.top_bar_menu.BG_Color1}`
          : 'white',

          color: data?.component?.top_bar_menu.BG_type === 'Colour'
          ? `${data?.component?.top_bar_menu.text_color1}`
          : 'black',
      }}
    >
      <div className="container">
        <section>
          <div className="flex flex-wrap text-center justify-center">
            <div className="w-full md:w-1/4 mt-3">
              <h6 className="mb-4 font-bold text-lg">Company</h6>
              <p>
                <Link to={`/o/${sellerslug}/about-us`} className="">About us</Link>
              </p>
              <p>
                <Link to={`/o/${sellerslug}/contact-us`}>Contact us</Link>
              </p>
              <p>
                <Link to={`/o/${sellerslug}/business`}>Business</Link>
              </p>
              <p>
                <Link to={`/o/${sellerslug}/channel-partners`}>Channel Partners</Link>
              </p>
            </div>
            <div className="w-full md:w-1/4 mt-3">
              <h6 className="mb-4 font-bold text-lg">Legal </h6>
              <p>
                <Link to={`/o/${sellerslug}/privacy-policy`}>Privacy Policy</Link>
              </p>
              <p>
                <Link to={`/o/${sellerslug}/terms-conditions`}>Terms & Conditions</Link>
              </p>
              <p>
                <Link to={`/o/${sellerslug}/return-policy`}>Return Policy</Link>
              </p>
            </div>
            <div className="w-full md:w-1/4 mt-3">
              <h6 className="mb-4 font-bold text-lg">Contact us</h6>
              <p>hi@zulu.club</p>
              <p>+91 85279 20080</p>
              <p>
                Golf Course Extn Road,
                <br />
                Gurgaon, Harayana, India
              </p>
            </div>
          </div>
        </section>
      </div>
      <hr className="border-none outline-none" />
      <div className="container w-full mt-2">
        <div className="flex mb-3 items-center justify-center">
          <a href="#" className="px-2">
            <FaTwitter />
          </a>
          <a href="#" className="px-2">
            <FaFacebookF />
          </a>
          <a href="#" className="px-2">
            <FaInstagram />
          </a>
          <a href="#" className="px-2">
            <FaLinkedin />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default SellerFooter;
