import React, { useEffect } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';

export default function OrderSuccess() {
    const { id } = useParams();
    const location = useLocation(); 
    const usedAddress = location.state?.usedAddress; 
    const phone = localStorage.getItem('mobileno');

    useEffect(() => {
        console.log('Order ID:', id);
        console.log('Used Address:', usedAddress);
        console.log('Phone Number:', phone);
    }, [id, usedAddress, phone]);

    return (
        <div className="min-h-screen bg-gray-50 dark:bg-gray-900 py-10">
            <section className="bg-white shadow-md rounded-lg py-8 antialiased dark:bg-gray-800 mx-auto max-w-2xl px-6 md:px-12">
                <div className="text-center">
                    <h2 className="text-2xl font-bold text-gray-900 dark:text-white sm:text-3xl mb-4">
                        Thank You for Your Order!
                    </h2>
                    <p className="text-gray-500 dark:text-gray-400 mb-6">
                        Your order  
                        <span className="font-medium text-gray-900 dark:text-white mr-1"> #{id}</span> 
                        will be processed within 24 hours during working days. We will notify you by email once your order has been shipped.
                    </p>
                </div>

                <div className="bg-gray-50 dark:bg-gray-700 rounded-lg border border-gray-100 dark:border-gray-700 p-6 space-y-6 mb-8">
                    <div className="flex justify-between items-center">
                        {/* <span className="text-gray-500 dark:text-gray-400">Order Date</span> */}
                        {/* <span className="font-medium text-gray-900 dark:text-white">14 May 2024</span> */}
                    </div>

                    <div className="flex justify-between items-center">
                        <span className="text-gray-500 dark:text-gray-400">Delivery Address</span>
                        <span className="font-medium text-gray-900 dark:text-white text-right">
                            {usedAddress ? (
                                <div className="font-medium text-gray-900 dark:text-white text-left">
                                    <p>{usedAddress.address}</p>
                                    <p>{usedAddress.city}, {usedAddress.state}</p>
                                    <p>{usedAddress.pincode}, {usedAddress.country}</p>
                                </div>
                            ) : (
                                <span className="text-red-500">No address found</span>
                            )}
                        </span>
                    </div>


                    <div className="flex justify-between items-center">
                        <span className="text-gray-500 dark:text-gray-400">Phone</span>
                        <span className="font-medium text-gray-900 dark:text-white">
                            {phone}
                        </span>
                    </div>
                </div>

                <div className="flex justify-center space-x-4">
                    <a href="#" className="text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 transition-colors duration-200 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        Track your order
                    </a>
                    <Link to="/allproducts" className="py-2.5 px-5 text-sm font-medium 
                    text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-600 transition-colors duration-200 focus:outline-none focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                        Return to shopping
                    </Link>
                </div>
            </section>
        </div>
    );
}
