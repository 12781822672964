// src/components/ProfileView.js
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ClipLoader } from 'react-spinners';
import { url } from '../../../components/api/Url';


const SellerUserViewProfile = ({ username, email, userimage, mobile, onSubmit, cityname, areaname,dateofbirth }) => {
  const pathimage = url + '/uploads/user_image/' + userimage;
  const [image, setImage] = useState(pathimage);
  const [usernamepage, setUsernamepage] = useState(username);
  const [emailaddress, setEmailaddress] = useState(email);
  const [userid, setUserid] = useState(localStorage.getItem('userid'));
  const [mobileno, setMobileno] = useState(mobile);
  const [city, setCity] = useState(cityname);
  const [area, setArea] = useState(areaname);
  const [dob, setDob] = useState(dateofbirth);
  const [newImage, setNewImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [oldpassword, setOldpassword] = useState('');
  const [newpassword, setNewpassword] = useState('');
  const [confirmpassword, setConfirmpassword] = useState('');
  const [isloading, setIsloading] = useState('');
  const [issubmiting, setIssubmitting] = useState(false)


  const [formData, setFormData] = useState({
    usernamepage: usernamepage || '',
    emailaddress: emailaddress || '',
    mobile: mobile || '',
  });


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewImage(reader.result);
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const validateForm = () => {
    const newErrors = {};

    // Name validation
    if (!usernamepage) {
      newErrors.usernamepage = 'Name is required';
    }
    if (!mobileno) {
      newErrors.mobileno = 'Mobile No is required';
    }

    // Email validation
    if (!emailaddress) {
      newErrors.emailaddress = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(emailaddress)) {
      newErrors.emailaddress = 'Email is invalid';
    }

    // Password validation
    /*   if (!password) {
        newErrors.password = 'Password is required';
      } else if (password.length < 8) {
        newErrors.password = 'Password must be at least 6 characters long';
      } */

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIssubmitting(true);
      console.log('Form submitted successfully:');
      setIsloading(true)
      const datafrom = new FormData();
      datafrom.append('mobile', mobileno); //localStorage.getItem('tem_mobileno') localStorage.getItem('tem_mobileno')
      datafrom.append('email', emailaddress);
      datafrom.append('type', 'phone');
      datafrom.append('user_id', userid);
      datafrom.append('image', image);
      datafrom.append('city', city);
      datafrom.append('area', area);
      datafrom.append('dob', dob);
      console.log(datafrom);
      try {
        const response = await fetch(url + '/app/v1/api/update_user', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            // 'Content-Type': 'application/json',
          },
          body: datafrom
        });

        if (!response.ok) {
          setIssubmitting(false)
          toast('Network response was not ok')
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Success:', data);
        setIssubmitting(false);
      } catch (error) {
        toast(error)
        setIssubmitting(false);
        setIsloading(false)
      }
      setIsloading(false)
    }

  };

  return (
    <div className="bg-white p-6 max-w-[1000px] text-left mx-auto">
      {/* Profile Photo Upload */}
      <div className="bg-white p-8 rounded-lg border mx-auto">
        <div className="grid grid-cols-3 gap-6 items-start">
          {/* Left Section - Profile Info */}
          <div className="col-span-1">
            <h2 className="text-gray-900 font-semibold mb-1">Profile Photo </h2>
            <p className="text-gray-400 text-sm">This image will be displayed on your profile</p>
          </div>

          {/* Right Section - Image Upload */}
          <div className="col-span-2">
            <div className="flex space-x-4 items-center">
              {/* Profile Photo Preview */}
              <div className="relative w-28 h-28">
                <img
                  src={image || '/path/to/default/image.png'}  //{`${url}/image` 
                  alt="Profile"
                  className="w-24 h-24 rounded-full object-cover border-2"
                />
              </div>

              {/* Upload Section */}
              <label
                htmlFor="file-input"
                className="flex flex-col items-center justify-center w-full h-32 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:border-blue-500"
              >
                <div className="flex flex-col items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke="currentColor"
                    className="w-10 h-10 text-blue-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4.5v15m0 0l-5-5m5 5l5-5m-5-10v15"
                    />
                  </svg>
                  <p className="text-blue-500 font-semibold">Click to upload</p>
                  <p className="text-gray-400 text-sm">or drag and drop</p>
                  <p className="text-gray-400 text-xs">JPG, PNG, or GIF (Recommended size 1000x1000px)</p>
                </div>
                <input
                  id="file-input"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handleImageChange}
                />
              </label>
            </div>
          </div>
        </div>




      </div>


      <hr className="my-6" />

      {/* Personal Information Section */}
      <div className="bg-white p-8 rounded-lg shadow-sm border mx-auto">
        <h2 className="text-gray-900 text-lg font-semibold mb-6">Personal Information</h2>

        {/* Username Field */}
        <div className="grid grid-cols-2 gap-4 text-sm mb-6">
          <div>
            <label className="block text-gray-700 font-medium mb-2">Username</label>
            <input
              type="text"
              name="usernamepage"
              value={usernamepage}
              onChange={(e) => setUsernamepage(e.target.value)}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 focus:ring-opacity-50"
            />
            {/* Error message (optional) */}
            {/* {errors.userName && <p className="text-red-500 text-sm mt-1">{errors.userName}</p>} */}
          </div>
        </div>

        {/* Email Field */}
        <div className="mb-6 text-sm">
          <label className="block text-gray-700 font-medium mb-2">Email</label>
          <input
            type="email"
            name="emailaddress"
            value={emailaddress}
            onChange={(e) => setEmailaddress(e.target.value)}
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 focus:ring-opacity-50"
          />
          {/* Error message (optional) */}
          {/* {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>} */}
        </div>

        {/* Phone Number Field */}
        <div className="mb-6 text-sm">
          <label className="block text-gray-700 font-medium mb-2">Phone Number</label>
          <input
            type="text"
            name="mobileno"
            value={mobileno}
            onChange={(e) => setMobileno(e.target.value)}
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 focus:ring-opacity-50"
          />
          {/* Error message (optional) */}
          {/* {errors.phoneNumber && <p className="text-red-500 text-sm mt-1">{errors.phoneNumber}</p>} */}
        </div>

         {/* Date of birth Field */}
         <div className="mb-6 text-sm">
          <label className="block text-gray-700 font-medium mb-2">Date of birth</label>
          <input
            type="date"
            name="dob"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 focus:ring-opacity-50"
          />
          {/* Error message (optional) */}
          {/* {errors.phoneNumber && <p className="text-red-500 text-sm mt-1">{errors.phoneNumber}</p>} */}
        </div>
         {/* city Field */}
         <div className="mb-6 text-sm">
          <label className="block text-gray-700 font-medium mb-2">City</label>
          <input
            type="text"
            name="city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 focus:ring-opacity-50"
          />
          {/* Error message (optional) */}
          {/* {errors.phoneNumber && <p className="text-red-500 text-sm mt-1">{errors.phoneNumber}</p>} */}
        </div>

         {/* Area Field */}
         <div className="mb-6 text-sm">
          <label className="block text-gray-700 font-medium mb-2">Area</label>
          <input
            type="text"
            name="area"
            value={area}
            onChange={(e) => setArea(e.target.value)}
            className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 focus:ring-opacity-50"
          />
          {/* Error message (optional) */}
          {/* {errors.phoneNumber && <p className="text-red-500 text-sm mt-1">{errors.phoneNumber}</p>} */}
        </div>

      </div>
      <hr className="my-6" />

      {/* Password */}
      {/*   <div className="bg-white p-8 rounded-lg shadow-sm text-sm border mx-auto">
  <h2 className="text-gray-900 text-lg font-semibold mb-6">Change Password</h2>
  <div className="mb-6">
    <label className="block text-gray-700 font-medium mb-2">Old Password</label>
    <input
      type="password"
      name="oldpassword"
      value={oldpassword}
      onChange={(e) => setOldpassword(e.target.value)}
      className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 focus:ring-opacity-50"
    />
  </div>
  <div className="mb-6">
    <label className="block text-gray-700 font-medium mb-2">New Password</label>
    <input
      type="password"
      name="newpassword"
      value={newpassword}
      onChange={(e) => setNewpassword(e.target.value)}
      className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 focus:ring-opacity-50"
    />
  </div>
  <div className="mb-6">
    <label className="block text-gray-700 font-medium mb-2">Confirm Password</label>
    <input
      type="password"
      name="confirmpassword"
      value={confirmpassword}
      onChange={(e) => setConfirmpassword(e.target.value)}
      className="mt-1 block w-full p-3 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 focus:ring-opacity-50"
    />
  </div>

</div> */}



      {/* Action Buttons */}
      <div className="flex justify-end space-x-4 mt-6">
        <button
          type="button"
          className="py-2 px-6 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none"
        >
          Cancel
        </button>
          <button
          type="submit" onClick={handleSubmit} disabled={issubmiting}
          className="py-2 px-6 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none"
          >
             {issubmiting ? 'saving...' : 'Update'}
          </button>
      </div>
    </div>



  );
};

export default SellerUserViewProfile;
